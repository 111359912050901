import ComponentHelper      from '../../helper/ComponentHelper';
import PropTypes            from '../PropTypes';
import React                from 'react';
import styles               from './styles.module.scss';
import OverlayMenuTextTheme from './OverlayMenuTextTheme';
import classNames           from 'classnames';
import SelectionHelper      from '../../helper/SelectionHelper';

class Footer extends React.Component {
    render () {
        return (
            <li
                className={classNames(
                    styles.overlayMenuText,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [OverlayMenuTextTheme.default]: styles.overlayMenuTextDefault,
                            [OverlayMenuTextTheme.italic]:  styles.overlayMenuTextItalic,
                        },
                    ),
                )}
            >
                {this.props.text}
            </li>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Footer;

Component.propTypes = {
    text:  PropTypes.string,
    theme: PropTypes.oneOf(Object.values(OverlayMenuTextTheme)),
};

Component.defaultProps = {
    text:  null,
    theme: OverlayMenuTextTheme.default,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;