import _                      from 'lodash';
import AlertBoxManager        from '../components/AlertBoxManager';
import CenterContainer        from '../components/CenterContainer';
import ColorButton            from '../components/ColorButton';
import ColorButtonTheme       from '../components/ColorButton/ColorButtonTheme';
import Headline               from '../components/Headline';
import HeadlineTheme          from '../components/Headline/HeadlineTheme';
import I18n                   from 'i18next';
import IconType               from '../components/Icon/IconType';
import LeftRightSplitter      from '../components/LeftRightSplitter';
import MailHelper             from '../helper/MailHelper';
import PageTitleHelper        from '../helper/PageTitle';
import React                  from 'react';
import styles                 from '../styles.module.scss';
import TextButton             from '../components/TextButton';
import TextButtonTheme        from '../components/TextButton/TextButtonTheme';
import TextInput              from '../components/TextInput';
import TextInputType          from '../components/TextInput/TextInputType';
import { AlertBoxActions }    from '../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { UserActions }        from '../store/actions/user';
import { login }              from '../api';

class Screen extends Component {
    loginButtonPressed = () => {
        console.log('LoginScreen: loginButtonPressed');

        this.props.clearAlerts();
        this.props.login();
    };

    lostPasswordButtonPressed = () => {
        MailHelper.openMailWindow(I18n.t('lostPasswordEmailAddress'));
    };

    onFormSubmit = (event) => {
        event.preventDefault();

        this.loginButtonPressed();
    };

    passwordChanged = (event) => {
        this.props.setPassword({
            password: event.target.value,
        });
    };

    render () {
        return (
            <div className={styles.contentView}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('login'))}
                    </title>
                </Helmet>
                <CenterContainer centerContent={true}>
                    <form onSubmit={this.onFormSubmit}>
                        <input
                            type={'submit'}
                            name={'submit'}
                        />
                        <Headline
                            text={I18n.t('login')}
                            theme={HeadlineTheme.bold}
                        />
                        <AlertBoxManager />
                        <TextInput
                            iconType={IconType.user}
                            onChange={this.usernameChanged}
                            onFocus={this.handleUsernameInputFocusSelect}
                            placeholder={I18n.t('loginUsernamePlaceholder')}
                            type={TextInputType.text}
                            value={this.props.username}
                        />
                        <TextInput
                            iconType={IconType.key}
                            onChange={this.passwordChanged}
                            placeholder={I18n.t('loginPasswordPlaceholder')}
                            type={TextInputType.password}
                            value={this.props.password}
                        />
                        <LeftRightSplitter
                            renderLeft={this.renderLostPasswordButton}
                            renderRight={this.renderLoginButton}
                        />
                    </form>
                </CenterContainer>
            </div>
        );
    }

    renderLoginButton = () => {
        return (
            <ColorButton
                onClick={this.loginButtonPressed}
                text={I18n.t('loginAction')}
                theme={ColorButtonTheme.red}
            />
        );
    };

    renderLostPasswordButton = () => {
        return (
            <TextButton
                onClick={this.lostPasswordButtonPressed}
                text={I18n.t('lostPasswordAction')}
                theme={TextButtonTheme.gray}
            />
        );
    };

    usernameChanged = (event) => {
        this.props.setUsername({
            username: event.target.value,
        });
    };

    handleUsernameInputFocusSelect = (event) => {
        const target                = event.target;
        const value                 = target.value;
        const loginUsernameTemplate = I18n.t('loginUsernamePlaceholder');

        if (value === '') {
            target.value = loginUsernameTemplate;

            this.setCursorPosition(target, 0);
        }

        if (value.endsWith(loginUsernameTemplate)) {
            const endPosition = value.replace(loginUsernameTemplate, '').length;

            this.setCursorPosition(target, 0, endPosition);
        }
    };

    setCursorPosition = (target, start, end = start) => {
        setTimeout(function () {
            target.setSelectionRange(start, end);
        }, 0);
    };
}

const mapStateToProps = state => (
    {
        password: state.user.password,
        token:    state.user.token,
        username: state.user.username,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
