//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper        from '../../helper/ComponentHelper';
import I18n                   from 'i18next';
import React                  from 'react';
import PropTypes              from '../PropTypes';
import Icon                   from '../Icon';
import OverlayMenu            from '../OverlayMenu';
import OverlayMenuButton      from '../OverlayMenuButton';
import OverlayMenuText        from '../OverlayMenuText';
import Routes                 from '../../constants/Routes';
import IconButton             from '../IconButton';
import IconButtonTheme        from '../IconButton/IconButtonTheme';
import styles                 from './styles.module.scss';
import IconType               from '../Icon/IconType';
import { bindActionCreators } from 'redux';
import _                      from 'lodash';
import moment                 from 'moment';
import { LayoutActions }      from '../../store/actions/layout';
import { connect }            from 'react-redux';
import { UserActions }        from '../../store/actions/user';
import { withRouter }         from 'react-router-dom';
import * as ApiUrls           from '../../constants/Api';
import OverlayMenuTextTheme   from '../OverlayMenuText/OverlayMenuTextTheme';
import classNames             from 'classnames';
import Api                    from '../../constants/Api';

class Header extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            rightMenuHover: false,
        };
    }

    getLastLoginText = () => {
        const lastLoginDate       = moment(this.props.lastLogin);
        const lastLoginDateString = lastLoginDate.format(I18n.t('lastLoginDateFormat'));
        const lastLoginString     = I18n.t('lastLogin') + ': ' + lastLoginDateString;

        return lastLoginString;
    };

    logoClicked = () => {
        if (this.props.token) {
            this.props.history.push(Routes.getRouteWithParameters(
                Routes.timeTracking,
                {
                    monthYear: '',
                },
            ));
        } else {
            this.props.history.push(Routes.login);
        }
    };

    logoutButtonPressed = () => {
        this.setRightMenuHover(false);

        this.props.logout();
    };

    menuButtonPressed = () => {
        this.props.allSideBarToggleVisibility();
    };

    rightMenuHoverEnd = () => {
        this.setRightMenuHover(false);
    };

    rightMenuHoverStart = () => {
        this.setRightMenuHover(true);
    };

    setRightMenuHover = (rightMenuHover) => {
        this.setState({
            rightMenuHover,
        });
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    shouldShowMenu = () => {
        return this.props.pathname !== Routes.login;
    };

    render () {
        return (
            <div
                className={classNames(
                    styles.headerContainer,
                    {
                        [styles.stagingHeader]: Api.isStaging(),
                    },
                )}
            >
                <div className={styles.headerLeft}>
                    {this.renderLeftMenu()}
                </div>
                <div className={styles.headerCenter}>
                    <h1 onClick={this.logoClicked}>
                        <strong>{I18n.t('customerName')}</strong>
                        <span>{I18n.t('applicationName')}</span>
                        {this.renderStagingFlag()}
                    </h1>
                </div>
                <div
                    className={styles.headerRight}
                    onMouseEnter={this.rightMenuHoverStart}
                    onMouseLeave={this.rightMenuHoverEnd}
                >
                    {this.renderRightMenu()}
                </div>
            </div>
        );
    }

    renderLeftMenu = () => {
        if (this.shouldShowMenu()) {
            return (
                <IconButton
                    iconType={IconType.burger}
                    onClick={this.menuButtonPressed}
                    theme={IconButtonTheme.bigIcon}
                />
            );
        }

        return null;
    };

    renderRightMenu = () => {
        if (this.shouldShowMenu()) {
            return (
                <div className={styles.headerUsernameWrapper}>
                    <Icon iconType={IconType.user} />
                    <span className={styles.headerUsername}>
                        {this.props.username}
                    </span>
                    <OverlayMenu visible={this.state.rightMenuHover}>
                        <OverlayMenuText
                            text={this.getLastLoginText()}
                            theme={OverlayMenuTextTheme.italic}
                        />
                        <OverlayMenuButton
                            onClick={this.logoutButtonPressed}
                            text={I18n.t('logout')}
                        />
                    </OverlayMenu>
                </div>
            );
        }

        return null;
    };

    renderStagingFlag = () => {
        if (ApiUrls.BACKEND_URL !== ApiUrls.PRODUCTION_BACKEND_URL) {
            return (
                <span>
                    {I18n.t('headerStagingFlag')}
                </span>
            );
        }

        return null;
    };
}

const Component = Header;

Component.propTypes = {
    lastLogin: PropTypes.oneOfType([
        PropTypes.string,
    ]),
    pathname:  PropTypes.string,
    token:     PropTypes.string,
    username:  PropTypes.string,
};

Component.defaultProps = {
    lastLogin: null,
    pathname:  null,
    token:     null,
    username:  null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [
    'rightMenuHover',
];

const mapStateToProps = state => (
    {
        lastLogin: state.user.lastLogin,
        pathname:  state.router.location.pathname,
        token:     state.user.token,
        username:  state.user.username,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LayoutActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Component));
