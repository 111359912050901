'use strict';

import _ from 'lodash';

export default class ClientNameHelper {
    static getClientName (project) {
        if (project.client) {
            const client = project.client;

            if (client.contactPerson && client.contactPerson.name) {
                return client.name + ' (' + client.contactPerson.name + ')';
            }

            return client.name;
        }
        
        return '-';
    }
}