import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import classNames      from 'classnames';

class Footer extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.right) {
            style.right = this.props.right;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <div
                className={classNames(
                    styles.overlayMenuWrapper,
                    {
                        [styles.overlayMenuWrapperVisible]: this.props.visible,
                    },
                )}
                style={style}
            >
                <div className={styles.overlayMenu}>
                    <ul>
                        {this.props.children}
                    </ul>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Footer;

Component.propTypes = {
    children: PropTypes.children,
    right:    PropTypes.number,
    visible:  PropTypes.bool,
};

Component.defaultProps = {
    children: null,
    right:    null,
    visible:  false,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;