//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                    from 'lodash';
import DateHelper           from '../../helper/Date';
import I18n                 from 'i18next';
import moment               from 'moment';
import update               from 'immutability-helper';
import { LOCATION_CHANGE }  from 'connected-react-router';
import { UserTimeSumTypes } from '../actions/userTimeSum';
import { UserTypes }        from '../actions/user';

const initialState = {
    userTimeDaySums:                          [],
    userTimeDaySumsCurrentWeek:               [],
    userTimeMonthSumCurrentMonth:             [],
    userTimeMonthSums:                        [],
    userTimeProjectSums:                      [],
    userTimeYearSums:                         [],
    orderedUserTimeDaySumsPerDay:             [],
    orderedUserTimeDaySumsPerDayMonthAndYear: {},
};

const mapAndOrderUserTimeDaySumsForDays = function (days, userTimeDaySums) {
    const timeBookingsPerDay           = _.map(days, function (day) {
        const filteredUserTimeDaySums = _.filter(userTimeDaySums, function (userTimeDaySum) {
            const userTimeDaySumDate = DateHelper.getDateByDayOfYear(userTimeDaySum.dayOfYear, userTimeDaySum.year);

            if (DateHelper.isSameDay(day, userTimeDaySumDate)) {
                return userTimeDaySum;
            }
        });

        let durationInHoursSum = 0;
        let holiday            = null;

        for (const filteredUserTimeDaySum of filteredUserTimeDaySums) {
            const filteredUserTimeDaySumHoliday = filteredUserTimeDaySum.holiday;

            if (!holiday && filteredUserTimeDaySumHoliday) {
                holiday = filteredUserTimeDaySumHoliday;
            }

            durationInHoursSum += filteredUserTimeDaySum.timeInHours;
        }

        const formattedDate = new moment(day).format(I18n.t('listDateFormatWithShortDay'));

        return {
            date:     formattedDate,
            duration: durationInHoursSum,
            holiday:  holiday,
            rawDate:  day,
        };
    });
    const orderedUserTimeDaySumsPerDay = _.orderBy(timeBookingsPerDay, ['rawDate'], ['asc']);

    return orderedUserTimeDaySumsPerDay;
};

const fetchAllUserTimeDaySumsSucceeded = function (action, state) {
    const newUserTimeDaySums                       = action.userTimeDaySums;
    const currentWeekDays                          = DateHelper.getCurrentWeekDays();
    const userTimeDaySumsCurrentWeek               = _.compact(
        _.map(newUserTimeDaySums, function (userTimeDaySum) {
            const weekDayMatch = _.find(currentWeekDays, {
                dayOfYear: userTimeDaySum.dayOfYear,
                year:      userTimeDaySum.year,
            });

            if (weekDayMatch) {
                return userTimeDaySum;
            }
        }),
    );
    const orderedUserTimeDaySumsPerDayMonthAndYear = state.orderedUserTimeDaySumsPerDayMonthAndYear;
    let orderedUserTimeDaySumsPerDay               = state.orderedUserTimeDaySumsPerDay;

    if (orderedUserTimeDaySumsPerDayMonthAndYear) {
        const month                  = orderedUserTimeDaySumsPerDayMonthAndYear.month;
        const year                   = orderedUserTimeDaySumsPerDayMonthAndYear.year;
        const daysInMonth            = DateHelper.getDaysInMonth(month - 1, year, true, true);
        orderedUserTimeDaySumsPerDay = mapAndOrderUserTimeDaySumsForDays(daysInMonth, newUserTimeDaySums);
    }

    return update(state, {
        userTimeDaySums:              {
            $set: newUserTimeDaySums,
        },
        userTimeDaySumsCurrentWeek:   {
            $set: userTimeDaySumsCurrentWeek,
        },
        orderedUserTimeDaySumsPerDay: {
            $set: orderedUserTimeDaySumsPerDay,
        },
    });
};

const fetchAllUserTimeMonthSumsSucceeded = function (action, state) {
    const userTimeMonthSums            = action.userTimeMonthSums;
    const currentMonth                 = DateHelper.getCurrentMonth();
    const currentYear                  = DateHelper.getCurrentYear();
    const userTimeMonthSumCurrentMonth = _.find(
        userTimeMonthSums,
        {
            month: currentMonth,
            year:  currentYear,
        },
    );

    return update(state, {
        userTimeMonthSums:            {
            $set: userTimeMonthSums,
        },
        userTimeMonthSumCurrentMonth: {
            $set: userTimeMonthSumCurrentMonth,
        },
    });
};

const fetchAllUserTimeProjectSumsSucceeded = function (action, state) {
    return update(state, {
        userTimeProjectSums: {
            $set: action.userTimeProjectSums,
        },
    });
};

const fetchAllUserTimeYearSumsSucceeded = function (action, state) {
    return update(state, {
        userTimeYearSums: {
            $set: action.userTimeYearSums,
        },
    });
};

const logout = function (action, state) {
    return update(state, {
        $set: initialState,
    });
};

const locationChange = function (action, state) {
    const pathname = action.payload.location.pathname;

    if (pathname.startsWith('/dashboard')) {
        const monthYearString = pathname.replace('/dashboard/', '');
        const monthYearArray  = monthYearString.split('-');
        let month             = DateHelper.getCurrentMonth();
        let year              = DateHelper.getCurrentYear();

        if (monthYearArray.length >= 2) {
            month = monthYearArray[0];
            year  = monthYearArray[1];
        }

        const daysInMonth                  = DateHelper.getDaysInMonth(month - 1, year, true, true);
        const userTimeDaySums              = state.userTimeDaySums;
        const orderedUserTimeDaySumsPerDay = mapAndOrderUserTimeDaySumsForDays(daysInMonth, userTimeDaySums);

        return update(state, {
            orderedUserTimeDaySumsPerDay:             {
                $set: orderedUserTimeDaySumsPerDay,
            },
            orderedUserTimeDaySumsPerDayMonthAndYear: {
                month: {
                    $set: month,
                },
                year:  {
                    $set: year,
                },
            },
        });
    }

    return state;
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS_SUCCEEDED:     return fetchAllUserTimeDaySumsSucceeded(action, state);
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS_SUCCEEDED:   return fetchAllUserTimeMonthSumsSucceeded(action, state);
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS_SUCCEEDED: return fetchAllUserTimeProjectSumsSucceeded(action, state);
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS_SUCCEEDED:    return fetchAllUserTimeYearSumsSucceeded(action, state);
        case UserTypes.LOGOUT:                                            return logout(action, state);
        case LOCATION_CHANGE:                                             return locationChange(action, state);
        default:                                                          return state;
        // @formatter:on
    }
}
