import QueryString from 'query-string';

class MailHelper {

    static openMailWindow (recipientAddress, subject, body, carbonAddress, blindCarbonAddress) {
        if (recipientAddress) {
            const url    = 'mailto:' + recipientAddress + '?';
            const params = QueryString.stringify({
                subject: subject,
                body:    body,
                cc:      carbonAddress,
                bcc:     blindCarbonAddress,
            });

            window.location.href = url + params;
        } else {
            console.warn('No recipient address set - cannot open mail window');
        }
    }
}

export default MailHelper;