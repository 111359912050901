//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import PropTypes                      from '../PropTypes';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router-dom';
import styles                         from './styles.module.scss';
import classNames                     from 'classnames';
import ComponentHelper                from '../../helper/ComponentHelper';

class Link extends React.Component {
    currentRouteIsActive = () => {
        const currentRouteIsActive = this.props.pathname.startsWith(this.props.to);

        return currentRouteIsActive;
    };

    render () {
        return (
            <ReactRouterDomLink
                className={classNames(
                    styles.sideBarLink,
                    {
                        [styles.sideBarLinkActive]: this.currentRouteIsActive(),
                    },
                )}
                to={this.props.to}
                params={this.props.params}
            >
                {this.props.text}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Link;

Component.propTypes = {
    params:   PropTypes.object,
    pathname: PropTypes.string,
    text:     PropTypes.string,
    to:       PropTypes.string,
};

Component.defaultProps = {
    params:   {},
    pathname: '',
    text:     '',
    to:       null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default withRouter(connect(
    mapStateToProps,
    null,
)(Component));
