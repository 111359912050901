//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import ComponentHelper from '../../helper/ComponentHelper';
import I18n            from 'i18next';
import IconButton      from '../IconButton';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import React           from 'react';
import Routes          from '../../constants/Routes';
import styles          from './styles.module.scss';
import { withRouter }  from 'react-router-dom';
import moment          from 'moment';

class TimeTrackingCalendarWeekActionList extends React.Component {
    viewButtonPressed = () => {
        const momentDate = moment(this.props.date);
        const dateFrom   = momentDate.startOf('week').format('DD-MM-YYYY');
        const dateTo     = momentDate.endOf('week').format('DD-MM-YYYY');

        this.props.history.push(Routes.getRouteWithParameters(
            Routes.timeTracking,
            {
                monthYear: '',
            },
            {
                dateFrom: dateFrom,
                dateTo:   dateTo,
            },
        ));
    };

    render () {
        if (this.props.date) {
            return (
                <ul className={styles.timeTrackingCalendarWeekActionList}>
                    {this.renderEditButton()}
                </ul>
            );
        }

        return null;
    }

    renderEditButton = () => {
        return (
            <li>
                <IconButton
                    iconType={IconType.clock}
                    onClick={this.viewButtonPressed}
                    theme={IconButtonTheme.grayRounded}
                    title={I18n.t('timeTrackingCalendarWeekDetail')}
                />
            </li>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TimeTrackingCalendarWeekActionList;

Component.propTypes = {
    date: PropTypes.any,
};

Component.defaultProps = {
    date: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default withRouter(Component);
