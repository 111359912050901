//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import ValueLabel      from '../ValueLabel';

class TableWorkingPackageAbbreviationFormatter extends React.Component {
    render () {
        const value        = this.props.value;
        const abbreviation = _.get(value, 'abbreviation');
        const title        = _.get(value, 'title');

        return (
            <ValueLabel
                value={abbreviation}
                customTitle={title}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableWorkingPackageAbbreviationFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
