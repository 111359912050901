//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import ClientNameHelper from '../../helper/ClientNameHelper';
import ComponentHelper  from '../../helper/ComponentHelper';
import DefaultColumns   from '../Table/DefaultColumns';
import PropTypes        from '../PropTypes';
import React            from 'react';
import SortDirection    from '../../constants/SortDirection';
import Table            from '../Table';
import { connect }      from 'react-redux';

class ProjectTable extends React.Component {
    getColumnConfiguration = () => {
        const defaultColumns      = DefaultColumns.get();
        const columnConfiguration = _.compact([
            defaultColumns.projectNumber,
            defaultColumns.projectTitle,
            defaultColumns.clientName,
            (
                // https://lulububu.atlassian.net/browse/VOFEITITRA-189
                this.props.isProjectManager ? defaultColumns.timeOverview : null
            ),
            defaultColumns.status,
            defaultColumns.projectActions,
        ]);

        return columnConfiguration;
    };

    getProjects = () => {
        let projects = [];

        if (this.props.projects) {
            for (const project of this.props.projects) {
                const isProjectManager = _.get(project, 'projectManager.username') === this.props.username;

                projects.push({
                    actions:               project,
                    clientName:            ClientNameHelper.getClientName(project),
                    projectNumber:         project.number,
                    projectNumberSortable: project.numberSortable,
                    projectState:          project.state,
                    projectTitle:          project.title,
                    timeOverview:          (
                                               !isProjectManager ?
                                                   -1 :
                                                   (
                                                       project.actualExpenditure / project.totalExpenditure
                                                   )
                                           ),
                });
            }
        }

        return projects;
    };

    render () {
        const projects = this.getProjects();

        return (
            <Table
                columnConfiguration={this.getColumnConfiguration()}
                rawData={projects}
                sortColumn={'projectNumber'}
                sortDirection={SortDirection.desc}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectTable;

Component.propTypes = {
    projects:            PropTypes.array,
    projectTimeBookings: PropTypes.array,
    username:            PropTypes.string,
};

Component.defaultProps = {
    projects:            [],
    projectTimeBookings: [],
    username:            null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isProjectManager: state.user.isProjectManager,
        projects:         state.project.projects,
        username:         state.user.username,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
