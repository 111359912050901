import React      from 'react';
import styles     from './styles.module.scss';
import classNames from 'classnames';
import PropTypes  from '../PropTypes';

class TableRow extends React.Component {
    componentDidMount () {

    }

    render () {
        return (
            <div
                className={classNames(
                    styles.tableRow,
                    {
                        [styles.tableRowBold]: this.props.boldContent,
                    },
                )}
            >
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        // Has to be true otherwise the table cannot get resized
        return true;
    }
}

const Component = TableRow;

Component.propTypes = {
    boldContent: PropTypes.bool,
};

Component.defaultProps = {
    boldContent: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;