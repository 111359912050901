import ComponentHelper from '../../helper/ComponentHelper';
import Icon            from '../Icon';
import React           from 'react';
import styles          from './styles.module.scss';
import IconType        from '../Icon/IconType';

class ScrollToTopButton extends React.Component {
    buttonClicked = () => {
        // Scroll the window up
        window.scrollTo(0, 0);

        const firstTable = document.querySelector('.react-grid-Canvas');

        if (firstTable) {
            // Scroll the first (and only) visible table up
            firstTable.scrollTo({
                top:      0,
                left:     0,
                behavior: 'smooth',
            });
        }
    };

    render () {
        return (
            <div
                className={styles.scrollToTopButton}
                onClick={this.buttonClicked}
            >
                <Icon iconType={IconType.arrowUp} />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ScrollToTopButton;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;