//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { ProjectTypes } from '../actions/project';
import update           from 'immutability-helper';
import { UserTypes }    from '../actions/user';
import _                from 'lodash';

const initialState = {
    projects: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ProjectTypes.FETCH_ALL_PROJECTS_SUCCEEDED:
            return update(state, {
                projects: {
                    $set: action.projects,
                },
            });

        case ProjectTypes.FETCH_PROJECT_SUCCEEDED:
            const projectIndex = _.findKey(state.projects, { id: action.project.id });

            if (!_.isUndefined(projectIndex)) {
                return update(state, {
                    projects: {
                        [projectIndex]: {
                            $set: action.project,
                        },
                    },
                });
            }

            return state;

        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        default:
            return state;
    }
}
