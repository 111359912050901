import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import PropTypes                      from '../PropTypes';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router-dom';
import ComponentHelper                from '../../helper/ComponentHelper';

class Link extends React.Component {
    render () {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                to={this.props.to}
                params={this.props.params}
                target={this.props.target}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Link;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    params:    PropTypes.object,
    pathname:  PropTypes.string,
    target:    PropTypes.string,
    to:        PropTypes.string,
};

Component.defaultProps = {
    children:  [],
    className: '',
    params:    {},
    pathname:  '',
    target:    null,
    to:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default withRouter(connect(
    mapStateToProps,
    null,
)(Component));