import React                        from 'react';
import { connect }                  from 'react-redux';
import styles                       from './styles.module.scss';
import ReactDataGrid                from 'react-data-grid';
import { bindActionCreators }       from 'redux';
import PropTypes                    from '../PropTypes';
import classNames                   from 'classnames';
import SortDirections               from '../../constants/SortDirections';
import { Data }                     from 'react-data-grid-addons';
import TableRow                     from '../TableRow';
import _                            from 'lodash';
import { LayoutActions }            from '../../store/actions/layout';
import WindowEventHelper            from '../../helper/WindowEvent';
import ComponentHelper              from '../../helper/ComponentHelper';
import UserTimeTrackingTableRowType from '../UserTimeTrackingTable/UserTimeTrackingTableRowType';

class Component extends React.Component {
    dataGridReference = null;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.dataGridReference) {
            if (this.props.tableFilterVisible !== nextProps.tableFilterVisible) {
                this.dataGridReference.onToggleFilter();
            }

            if (
                // @formatter:off
                this.props.leftSideBarSize      !== nextProps.leftSideBarSize     ||
                this.props.leftSideBarVisible   !== nextProps.leftSideBarVisible  ||
                this.props.rightSideBarSize     !== nextProps.rightSideBarSize    ||
                this.props.rightSideBarVisible  !== nextProps.rightSideBarVisible
                // @formatter:on
            ) {
                WindowEventHelper.fakeResize();
            }
        }

        if (nextProps.rawData !== this.props.rawData) {
            this.setState(
                {
                    rawData: nextProps.rawData,
                },
                this.filterAndSortData,
            );
        }
    }

    componentDidMount () {
        // This makes react-data-grid draw a horizontal scrollbar when the content is too wide
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-172
        WindowEventHelper.fakeResize();
    }

    constructor (props) {
        super(props);

        this.state = {
            data:          [...props.rawData],
            filters:       {},
            rawData:       [...props.rawData],
            sortColumn:    props.sortColumn,
            sortDirection: props.sortDirection,
        };
    }

    filterAndSortData = () => {
        const filteredData = Data.Selectors.getRows({
            rows:    this.state.rawData,
            filters: this.state.filters,
        });
        const sortedData   = this.sortRows(
            filteredData,
            this.state.sortColumn,
            this.state.sortDirection,
        );

        console.log('Table: filerAndSortData', this.state, filteredData, sortedData);

        this.setData(sortedData);
    };

    getRowCount = () => {
        return this.state.data.length;
    };

    getRowData = (rowNumber) => {
        const rowData = this.state.data[rowNumber];

        return rowData;
    };

    getSortComparer = (sortColumn, sortDirection) => {
        console.log('getSortComparer', sortColumn, sortDirection);

        switch (sortColumn) {
            case 'date':
                return (left, right) => {
                    const leftDate  = left[sortColumn].split('.').reverse().join('');
                    const rightDate = right[sortColumn].split('.').reverse().join('');

                    if (sortDirection === SortDirections.ASC) {
                        return rightDate < leftDate ? 1 : rightDate > leftDate ? -1 : 0;
                    } else if (sortDirection === SortDirections.DESC) {
                        return leftDate < rightDate ? 1 : leftDate > rightDate ? -1 : 0;
                    }
                };

            case 'projectNumberSortable':
                return (left, right) => {
                    if (sortDirection === SortDirections.ASC) {
                        console.log('getSortComparer', parseFloat(left[sortColumn]), parseFloat(right[sortColumn]));

                        return parseFloat(left[sortColumn]) > parseFloat(right[sortColumn]) ? 1 : -1;
                    } else if (sortDirection === SortDirections.DESC) {
                        return parseFloat(left[sortColumn]) < parseFloat(right[sortColumn]) ? 1 : -1;
                    }
                };
        }

        return (left, right) => {
            if (sortDirection === SortDirections.ASC) {
                return left[sortColumn] > right[sortColumn] ? 1 : -1;
            } else if (sortDirection === SortDirections.DESC) {
                return left[sortColumn] < right[sortColumn] ? 1 : -1;
            }
        };
    };

    getTableClass = () => {
        return classNames(
            styles.table,
            {
                ['has-actions-column']: this.hasActionsColumn(),
            },
        );
    };

    getValidFilterValues = (columnId) => {
        const filterValues   = [];
        const addFilterValue = (filterValue) => {
            if (filterValues.indexOf(filterValue) === -1) {
                filterValues.push(filterValue);
            }
        };

        for (const row of this.state.data) {
            const data = row[columnId];

            if (_.isArray(data)) {
                for (const subData of data) {
                    addFilterValue(subData);
                }
            } else {
                addFilterValue(data);
            }
        }

        filterValues.sort();

        return filterValues;
    };

    handleFilterChange = (filter) => {
        const newFilters = { ...this.state.filters };

        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }

        return newFilters;
    };

    hasActionsColumn = () => {
        for (const columnConfiguration of this.props.columnConfiguration) {
            if (columnConfiguration.key === 'actions') {
                return true;
            }
        }

        return false;
    };

    onAddFilter = (filter) => {
        this.setFilters(this.handleFilterChange(filter));
    };

    onClearFilters = () => {
        this.setFilters({});
    };

    onGridSort = (sortColumn, sortDirection) => {
        this.setState(
            {
                sortColumn,
                sortDirection,
            },
            this.filterAndSortData,
        );
    };

    render () {
        const rowCount = this.getRowCount();

        console.log('Table: render: rowCount', rowCount);

        return (
            <div className={this.getTableClass()}>
                <ReactDataGrid
                    columns={this.props.columnConfiguration}
                    enableCellAutoFocus={false}
                    enableCellSelect={false}
                    headerRowHeight={25}
                    getValidFilterValues={this.getValidFilterValues}
                    minHeight={500}
                    onAddFilter={this.onAddFilter}
                    onClearFilters={this.onClearFilters}
                    onGridSort={this.onGridSort}
                    ref={this.setGridReference}
                    rowGetter={this.getRowData}
                    rowHeight={50}
                    rowRenderer={this.renderTableRow}
                    rowsCount={rowCount}
                    rowSelection={{
                        showCheckbox:      false,
                        enableShiftSelect: false,
                    }}
                    sortColumn={this.state.sortColumn}
                    sortDirection={this.state.sortDirection}
                    // This is overwritten by the "width" field in the column configuration
                    // so this only sets the minimum width of the title field since
                    // the title has no minimum width
                    minColumnWidth={200}
                />
            </div>
        );
    }

    // https://lulububu.atlassian.net/browse/VOFEITITRA-187
    renderTableRow = ({ renderBaseRow, ...canvasProps }) => {
        const row  = canvasProps.row;
        const rows = [];

        if (!row.type || row.type === UserTimeTrackingTableRowType.default) {
            rows.push(
                <TableRow key={'defaultRow' + canvasProps.idx}>
                    {renderBaseRow({ ...canvasProps })}
                </TableRow>,
            );
        }

        if (row.type === UserTimeTrackingTableRowType.sum) {
            rows.push(
                <TableRow
                    boldContent={true}
                    key={'sumRow' + canvasProps.idx}
                >
                    {renderBaseRow({ ...canvasProps })}
                </TableRow>,
            );
        }

        return rows;
    };

    setData = (data) => {
        console.log('Table: setData', data);

        this.setState({
            data,
        });
    };

    setFilters = (filters) => {
        this.setState(
            {
                filters,
            },
            this.filterAndSortData,
        );
    };

    setGridReference = (reference) => {
        this.dataGridReference = reference;

        if (reference && this.props.tableFilterVisible) {
            reference.onToggleFilter();
        }
    };

    sortRows = (initialRows, sortColumn, sortDirection) => {
        if (sortColumn === 'projectNumber') {
            sortColumn = 'projectNumberSortable';
        }

        const sortRowsComparer = this.getSortComparer(sortColumn, sortDirection);
        const sortedRows       = (
            sortDirection === SortDirections.NONE ?
                initialRows :
                [...initialRows].sort(sortRowsComparer)
        );

        return sortedRows;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    columnConfiguration: PropTypes.array,
    leftSideBarSize:     PropTypes.number,
    leftSideBarVisible:  PropTypes.bool,
    rawData:             PropTypes.array,
    rightSideBarSize:    PropTypes.number,
    rightSideBarVisible: PropTypes.bool,
    sortColumn:          PropTypes.string,
    sortDirection:       PropTypes.string,
    tableFilterVisible:  PropTypes.bool,
};

Component.defaultProps = {
    columnConfiguration: [],
    leftSideBarSize:     200,
    leftSideBarVisible:  true,
    rawData:             [],
    rightSideBarSize:    0,
    rightSideBarVisible: false,
    sortColumn:          null,
    sortDirection:       SortDirections.NONE,
    tableFilterVisible:  false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'data',
    'filters',
    'rawData',
    'sortColumn',
    'sortDirection',
];

const mapStateToProps = state => (
    {
        leftSideBarSize:     state.layout.leftSideBarSize,
        leftSideBarVisible:  state.layout.leftSideBarVisible,
        rightSideBarSize:    state.layout.rightSideBarSize,
        rightSideBarVisible: state.layout.rightSideBarVisible,
        tableFilterVisible:  state.layout.tableFilterVisible,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LayoutActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);