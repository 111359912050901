//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    add:        'add',
    arrowDown:  'arrowDown',
    arrowLeft:  'arrowLeft',
    arrowRight: 'arrowRight',
    arrowUp:    'arrowUp',
    burger:     'burger',
    check:      'check',
    copy:       'copy',
    clock:      'clock',
    cross:      'cross',
    folder:     'folder',
    key:        'key',
    listPlus:   'listPlus',
    package:    'package',
    pencil:     'pencil',
    search:     'search',
    user:       'user',
};
