import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../PropTypes';
import ComponentHelper from '../../helper/ComponentHelper';

class SplitPanePanel extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.height > -1) {
            style.height = this.props.height;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <div
                className={styles.splitPanePanel}
                style={style}
            >
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SplitPanePanel;

Component.propTypes = {
    children: PropTypes.children,
    height:   PropTypes.number,
};

Component.defaultProps = {
    children: null,
    height:   -1,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;