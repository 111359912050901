//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { UserTypes } from '../actions/user';
import update        from 'immutability-helper';

const initialState = {
    isProjectManager: null,
    lastLogin:        null,
    password:         '',
    token:            null,
    username:         '',
    id:               null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UserTypes.LOGIN_SUCCEEDED:
            return update(state, {
                isProjectManager: {
                    $set: action.isProjectManager,
                },
                lastLogin:        {
                    $set: action.lastLogin.date,
                },
                password:         {
                    $set: '',
                },
                token:            {
                    $set: action.token,
                },
                username:         {
                    $set: action.username,
                },
                id:               {
                    $set: action.id,
                },
            });

        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        case UserTypes.SET_PASSWORD:
            return update(state, {
                password: {
                    $set: action.password,
                },
            });

        case UserTypes.SET_USERNAME:
            return update(state, {
                username: {
                    $set: action.username,
                },
            });

        default:
            return state;
    }
}
