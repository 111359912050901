//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper           from '../../helper/ComponentHelper';
import PropTypes                 from '../PropTypes';
import React                     from 'react';
import styles                    from './styles.module.scss';
import Table                     from '../Table';
import classNames                from 'classnames';
import SelectionHelper           from '../../helper/SelectionHelper';
import TableWithHeaderTitleTheme from './TableWithHeaderTitleTheme';

class TableWithHeaderTitle extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.tableWithHeaderTitleContainer,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [TableWithHeaderTitleTheme.green]:  styles.tableWithHeaderTitleThemeGreen,
                            [TableWithHeaderTitleTheme.yellow]: styles.tableWithHeaderTitleThemeYellow,
                        },
                    ),
                )}
            >
                <div className={styles.tableWithHeaderTitleHeader}>
                    {this.props.title}
                </div>
                <Table
                    columnConfiguration={this.props.columnConfiguration}
                    rawData={this.props.rawData}
                />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableWithHeaderTitle;

Component.propTypes = {
    theme:               PropTypes.oneOf(Object.values(TableWithHeaderTitleTheme)),
    title:               PropTypes.string,
    rawData:             PropTypes.array,
    columnConfiguration: PropTypes.array,
};

Component.defaultProps = {
    theme:               TableWithHeaderTitleTheme.yellow,
    title:               '',
    rawData:             [],
    columnConfiguration: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
