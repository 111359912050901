//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import { UserTypes }    from '../actions/user';
import { HolidayTypes } from '../actions/holidays';

const initialState = {
    holidays: [],
};

const fetchAllHolidaysSucceeded = (action, state) => {
    return update(state, {
        holidays: {
            $set: action.holidays,
        },
    });
}

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case HolidayTypes.FETCH_ALL_HOLIDAYS_SUCCEEDED: return fetchAllHolidaysSucceeded(action, state);
        case UserTypes.LOGOUT:                          return logout(action, state);
        default:                                        return state;
        // @formatter:on
    }
}
