//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AlertBoxManager  from '../components/AlertBoxManager';
import BreadCrumbBar    from '../components/BreadCrumbBar';
import classNames       from 'classnames';
import ColorButton      from '../components/ColorButton';
import ColorButtonTheme from '../components/ColorButton/ColorButtonTheme';
import Dashboard        from '../components/Dashboard';
import Headline         from '../components/Headline';
import HeadlineOverlay  from '../components/HeadlineOverlay';
import HeadlineTheme    from '../components/Headline/HeadlineTheme';
import I18n             from 'i18next';
import IconType         from '../components/Icon/IconType';
import PageTitleHelper  from '../helper/PageTitle';
import React            from 'react';
import Routes           from '../constants/Routes';
import styles           from '../styles.module.scss';
import { Component }    from 'react';
import { Helmet }       from 'react-helmet';

class Screen extends Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.contentView,
                    styles.contentViewFullHeight,
                )}
            >
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('myOverview'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    overlay={this.renderHeadlineOverlay()}
                    text={I18n.t('myOverview')}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <AlertBoxManager />
                <Dashboard />
            </div>
        );
    }

    renderHeadlineOverlay = () => {
        return (
            <HeadlineOverlay>
                <ColorButton
                    iconType={IconType.clock}
                    to={Routes.timeTrackingCreate}
                    text={I18n.t('timeTrackingCreateNewButton')}
                    theme={ColorButtonTheme.redSmall}
                />
            </HeadlineOverlay>
        );
    };
}

export default Screen;
