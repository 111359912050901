// @see https://stackoverflow.com/questions/1818474/how-to-trigger-the-window-resize-event-in-javascript/18693617
class WindowEventHelper {
    static fakeResize () {
        // Trigger a resize event to make react-data-grid recalculate
        // its boundaries
        requestAnimationFrame(function () {
            const event = window.document.createEvent('UIEvents');

            event.initUIEvent(
                'resize',
                true,
                false,
                window,
                0
            );

            window.dispatchEvent(event);
        });
    }
}

export default WindowEventHelper;