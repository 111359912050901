//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                       from 'lodash';
import ComponentHelper         from '../../helper/ComponentHelper';
import DashboardNumberBox      from '../DashboardNumberBox';
import DashboardNumberBoxTheme from '../DashboardNumberBox/DashboardNumberBoxTheme';
import DateHelper              from '../../helper/Date';
import I18n                    from 'i18next';
import LatestTrackedTimeTable  from '../LatestTrackedTimeTable';
import MonthlyTrackedTimeTable from '../MonthlyTrackedTimeTable';
import PropTypes               from '../PropTypes';
import React                   from 'react';
import styles                  from './styles.module.scss';
import TopProjectTable         from '../TopProjectTable';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import { UserTimeSumActions }  from '../../store/actions/userTimeSum';
import { withRouter }          from 'react-router';

class Dashboard extends React.Component {
    getCurrentWeekTrackedHours = () => {
        return _.sumBy(
            this.props.userTimeDaySumsCurrentWeek,
            function (userTimeSum) {
                return userTimeSum.timeInHours;
            },
        );
    };

    getCurrentMonthTrackedHours = () => {
        const userTimeMonthSumCurrentMonth = this.props.userTimeMonthSumCurrentMonth;

        if (userTimeMonthSumCurrentMonth) {
            return userTimeMonthSumCurrentMonth.timeInHours;
        }

        return 0;
    };

    updateMonthAndYear = (monthYearObject) => {
        const newMonth = monthYearObject.month;
        const newYear  = monthYearObject.year;

        this.props.openDashboard({
            month: newMonth,
            year:  newYear,
        });
    };

    getMonthAndYear = () => {
        const { monthYear } = this.props.match.params;
        const currentMonth  = DateHelper.getCurrentMonth();
        const currentYear   = DateHelper.getCurrentYear();

        if (monthYear && _.includes(monthYear, '-')) {
            const monthYearArray = monthYear.split('-');

            if (!isNaN(monthYearArray[0]) && !isNaN(monthYearArray[1])) {
                return monthYearArray;
            }
        }

        return [currentMonth, currentYear];
    };

    render () {
        const monthAndYear = this.getMonthAndYear();
        const month        = monthAndYear[0];
        const year         = monthAndYear[1];

        return (
            <div
                className={styles.dashboard}
            >
                <div className={styles.dashboardLeftSide}>
                    <MonthlyTrackedTimeTable
                        month={month}
                        year={year}
                        updateMonthAndYear={this.updateMonthAndYear}
                    />
                </div>
                <div className={styles.dashboardRightSide}>
                    <div className={styles.dashboardNumberBoxContainer}>
                        <DashboardNumberBox
                            className={styles.dashboardNumberBox}
                            theme={DashboardNumberBoxTheme.lightBlue}
                            title={I18n.t('myWeek')}
                            number={this.getCurrentWeekTrackedHours()}
                            unit={I18n.t('trackedHours')}
                        />
                        <DashboardNumberBox
                            theme={DashboardNumberBoxTheme.pink}
                            title={I18n.t('myMonth')}
                            number={this.getCurrentMonthTrackedHours()}
                            unit={I18n.t('trackedHours')}
                        />
                    </div>
                    <div className={styles.dashboardLatestTrackedTimeContainer}>
                        <LatestTrackedTimeTable />
                    </div>
                    <div className={styles.dashboardTopProjectsContainer}>
                        <TopProjectTable />
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    componentDidMount = () => {
        const props = this.props;

        if (props.token) {
            props.fetchAllUserTimeDaySums();
            props.fetchAllUserTimeMonthSums();
            props.fetchAllUserTimeProjectSums();
            props.fetchAllUserTimeYearSums();
        }
    };
}

const Component = Dashboard;

Component.propTypes = {
    match:                        PropTypes.any,
    token:                        PropTypes.string,
    userTimeDaySums:              PropTypes.array,
    userTimeDaySumsCurrentWeek:   PropTypes.array,
    userTimeMonthSumCurrentMonth: PropTypes.any,
    userTimeMonthSums:            PropTypes.array,
    userTimeProjectSums:          PropTypes.array,
    userTimeYearSums:             PropTypes.array,
};

Component.defaultProps = {
    match:                        null,
    token:                        null,
    userTimeDaySums:              [],
    userTimeDaySumsCurrentWeek:   [],
    userTimeMonthSumCurrentMonth: null,
    userTimeMonthSums:            [],
    userTimeProjectSums:          [],
    userTimeYearSums:             [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        token:                        state.user.token,
        userTimeDaySums:              state.userTimeSum.userTimeDaySums,
        userTimeDaySumsCurrentWeek:   state.userTimeSum.userTimeDaySumsCurrentWeek,
        userTimeMonthSumCurrentMonth: state.userTimeSum.userTimeMonthSumCurrentMonth,
        userTimeMonthSums:            state.userTimeSum.userTimeMonthSums,
        userTimeProjectSums:          state.userTimeSum.userTimeProjectSums,
        userTimeYearSums:             state.userTimeSum.userTimeYearSums,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    UserTimeSumActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Component));
