//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import AbbreviationLabel from '../AbbreviationLabel';
import PropTypes         from '../PropTypes';

class TableAbbreviationFormatter extends React.Component {
    render() {
        const { value, limit } = this.props;
        const limitedValue     = value.slice(0, limit);

        return (
            <AbbreviationLabel
                value={limitedValue}
            />
        );
    }
}

const Component = TableAbbreviationFormatter;

Component.propTypes = {
    value: PropTypes.any,
    limit: PropTypes.number,
};

Component.defaultProps = {
    value: [],
    limit: 3,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
