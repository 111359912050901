import { makeActionCreator } from '../../helper/Store';

export const ProjectTypes = {
    FETCH_ALL_PROJECTS:           'Project/FETCH_ALL_PROJECTS',
    FETCH_ALL_PROJECTS_FAILED:    'Project/FETCH_ALL_PROJECTS_FAILED',
    FETCH_ALL_PROJECTS_SUCCEEDED: 'Project/FETCH_ALL_PROJECTS_SUCCEEDED',
    FETCH_PROJECT:                'Project/FETCH_PROJECT',
    FETCH_PROJECT_FAILED:         'Project/FETCH_PROJECT_FAILED',
    FETCH_PROJECT_SUCCEEDED:      'Project/FETCH_PROJECT_SUCCEEDED',
};

const fetchAllProjects = makeActionCreator(
    ProjectTypes.FETCH_ALL_PROJECTS,
);

const fetchAllProjectsFailed = makeActionCreator(
    ProjectTypes.FETCH_ALL_PROJECTS_FAILED,
);

const fetchAllProjectsSucceeded = makeActionCreator(
    ProjectTypes.FETCH_ALL_PROJECTS_SUCCEEDED, {
        projects: null,
    },
);

const fetchProject = makeActionCreator(
    ProjectTypes.FETCH_PROJECT, {
        projectId: null,
    },
);

const fetchProjectFailed = makeActionCreator(
    ProjectTypes.FETCH_PROJECT_FAILED,
);

const fetchProjectSucceeded = makeActionCreator(
    ProjectTypes.FETCH_PROJECT_SUCCEEDED, {
        project: null,
    },
);

export const ProjectActions = {
    fetchAllProjects,
    fetchAllProjectsFailed,
    fetchAllProjectsSucceeded,
    fetchProject,
    fetchProjectFailed,
    fetchProjectSucceeded,
};