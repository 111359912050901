//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api               from '../../api';
import Routes                 from '../../constants/Routes';
import { call }               from 'redux-saga/effects';
import { HolidayActions }     from '../actions/holidays';
import { push }               from 'connected-react-router';
import { put }                from 'redux-saga/effects';
import { UserTimeSumActions } from '../actions/userTimeSum';

const fetchAllUserTimeDaySums = function* () {
    const response = yield call(Api.fetchAllUserTimeDaySums);

    if (response.ok) {
        const userTimeDaySums = response.data;

        yield put(UserTimeSumActions.fetchAllUserTimeDaySumsSucceeded({
            userTimeDaySums,
        }));
    } else {
        yield put(UserTimeSumActions.fetchAllUserTimeDaySumsFailed());
    }
};

const fetchAllUserTimeMonthSums = function* () {
    const response = yield call(Api.fetchAllUserTimeMonthSums);

    if (response.ok) {
        const userTimeMonthSums = response.data;

        yield put(UserTimeSumActions.fetchAllUserTimeMonthSumsSucceeded({
            userTimeMonthSums,
        }));
    } else {
        yield put(UserTimeSumActions.fetchAllUserTimeMonthSumsFailed());
    }
};

const fetchAllUserTimeProjectSums = function* () {
    const response = yield call(Api.fetchAllUserTimeProjectSums);

    if (response.ok) {
        const userTimeProjectSums = response.data;

        yield put(UserTimeSumActions.fetchAllUserTimeProjectSumsSucceeded({
            userTimeProjectSums,
        }));
    } else {
        yield put(UserTimeSumActions.fetchAllUserTimeProjectSumsFailed());
    }
};

const fetchAllUserTimeYearSums = function* () {
    const response = yield call(Api.fetchAllUserTimeYearSums);

    if (response.ok) {
        const userTimeYearSums = response.data;

        yield put(UserTimeSumActions.fetchAllUserTimeYearSumsSucceeded({
            userTimeYearSums,
        }));
    } else {
        yield put(UserTimeSumActions.fetchAllUserTimeYearSumsFailed());
    }
};

const openDashboard = function* (action) {
    yield put(HolidayActions.fetchAllHolidays());
    yield put(push(Routes.getRouteWithParameters(
        Routes.dashboard,
        {
            monthYear: action.month + '-' + action.year,
        },
    )));
};

export default {
    fetchAllUserTimeDaySums,
    fetchAllUserTimeMonthSums,
    fetchAllUserTimeProjectSums,
    fetchAllUserTimeYearSums,
    openDashboard,
};
