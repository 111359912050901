import I18n                 from 'i18next';
import { initReactI18next } from 'react-i18next';
import german               from './german';
import Languages            from '../constants/Languages';
import Environment          from '../helper/Environment';
import LanguageHelper       from '../helper/Language';

const resources = {
    [Languages.german]: {
        translation: german,
    },
};

I18n.use(initReactI18next)
    .init({
        resources,
        lng:           LanguageHelper.getDefaultLanguage(),
        fallbackLng:   Languages.german,
        debug:         Environment.isDevelopment(),
        interpolation: {
            escapeValue: false,
        },
    })
;

export default I18n;