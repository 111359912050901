import _                      from 'lodash';
import ComponentHelper        from '../../helper/ComponentHelper';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import styles                 from './styles.module.scss';
import OverlayMenuButtonTheme from './OverlayMenuButtonTheme';
import classNames             from 'classnames';
import SelectionHelper        from '../../helper/SelectionHelper';

class Footer extends React.Component {
    render () {
        return (
            <li
                className={classNames(
                    styles.overlayMenuButton,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [OverlayMenuButtonTheme.default]: styles.overlayMenuButtonDefault,
                            [OverlayMenuButtonTheme.red]:     styles.overlayMenuButtonRed,
                        },
                    ),
                )}
                onClick={this.props.onClick}
            >
                {this.props.text}
            </li>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Footer;

Component.propTypes = {
    onClick: PropTypes.func,
    text:    PropTypes.string,
    theme:   PropTypes.oneOf(Object.values(OverlayMenuButtonTheme)),
};

Component.defaultProps = {
    onClick: _.noop,
    text:    null,
    theme:   OverlayMenuButtonTheme.default,
};

Component.renderAffectingProps = [
    'text',
];

Component.renderAffectingStates = [];

export default Component;