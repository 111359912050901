import { TextTemplateTypes } from '../actions/textTemplate';
import update                from 'immutability-helper';
import { UserTypes }         from '../actions/user';

const initialState = {
    textTemplates: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES_SUCCEEDED:
            return update(state, {
                textTemplates: {
                    $set: action.textTemplates,
                },
            });

        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        default:
            return state;
    }
}