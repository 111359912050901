import ScrollToTopButton           from '../ScrollToTopButton';
import ComponentHelper             from '../../helper/ComponentHelper';
import FooterLink                  from '../FooterLink';
import I18n                        from 'i18next';
import React                       from 'react';
import styles                      from './styles.module.scss';
import VersionNumber               from '../VersionNumber';
import Routes                      from '../../constants/Routes';
import { BACKEND_URL_ADMIN_LOGIN } from '../../constants/Api';

class Footer extends React.Component {
    render () {
        return (
            <div className={styles.footerContainer}>
                <div className={styles.footerLeft}>
                    {I18n.t('applicationCopyright')}
                    <br />
                    <VersionNumber />
                </div>
                <div className={styles.footerRight}>
                    <ul>
                        <li>
                            <FooterLink
                                to={BACKEND_URL_ADMIN_LOGIN}
                                text={I18n.t('admin')}
                                external={true}
                            />
                        </li>
                        <li>
                            <FooterLink
                                to={Routes.dataProtection}
                                text={I18n.t('dataProtection')}
                            />
                        </li>
                        <li>
                            <FooterLink
                                to={Routes.imprint}
                                text={I18n.t('imprint')}
                            />
                        </li>
                    </ul>
                    <ScrollToTopButton />
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Footer;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;