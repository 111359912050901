import I18n                          from 'i18next';
import PageTitleHelper               from '../helper/PageTitle';
import BreadCrumbBar                 from '../components/BreadCrumbBar';
import React                         from 'react';
import { Component }                 from 'react';
import { Helmet }                    from 'react-helmet';
import { bindActionCreators }        from 'redux';
import { UserActions }               from '../store/actions/user';
import { connect }                   from 'react-redux';
import { ProjectTimeBookingActions } from '../store/actions/projectTimeBooking';
import _                             from 'lodash';
import styles                        from '../styles.module.scss';
import HeadlineTheme                 from '../components/Headline/HeadlineTheme';
import HeadlineOverlay               from '../components/HeadlineOverlay';
import Headline                      from '../components/Headline';
import TableFilterButton             from '../components/TableFilterButton';
import ProjectTable                  from '../components/ProjectTable';
import classNames                    from 'classnames';
import AlertBoxManager               from '../components/AlertBoxManager';

class Screen extends Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.contentView,
                    styles.contentViewFullHeight,
                )}
            >
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('projects'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    overlay={this.renderHeadlineOverlay()}
                    text={I18n.t('myProjects')}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <AlertBoxManager />
                <ProjectTable />
            </div>
        );
    }

    renderHeadlineOverlay = () => {
        return (
            <HeadlineOverlay>
                <TableFilterButton />
            </HeadlineOverlay>
        );
    };
}

const mapStateToProps = state => (
    {
        loading:             state.loading,
        projects:            state.project.projects,
        projectTimeBookings: state.projectTimeBooking.projectTimeBookings,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    ProjectTimeBookingActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
