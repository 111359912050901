//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import PropTypes                    from '../PropTypes';
import ProjectTimeBookingActionList from '../ProjectTimeBookingActionList';

class TableTimeTrackingActionsFormatter extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <ProjectTimeBookingActionList projectTimeBooking={this.props.value} />
            );
        }

        return '';
    }
}

const Component = TableTimeTrackingActionsFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
