//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api           from '../../api';
import { call }           from 'redux-saga/effects';
import { HolidayActions } from '../actions/holidays';
import { put }            from 'redux-saga/effects';

const fetchAllHolidays = function* () {
    const response = yield call(Api.fetchAllHolidays);

    if (response.ok) {
        const holidays = response.data;

        yield put(HolidayActions.fetchAllHolidaysSucceeded({
            holidays,
        }));
    } else {
        yield put(HolidayActions.fetchAllHolidaysFailed());
    }
};

export default {
    fetchAllHolidays,
};
