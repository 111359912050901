import ComponentHelper        from '../../helper/ComponentHelper';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import I18n                   from 'i18next';
import styles                 from './styles.module.scss';
import ColorButton            from '../ColorButton';
import ColorButtonTheme       from '../ColorButton/ColorButtonTheme';
import IconType               from '../Icon/IconType';
import { bindActionCreators } from 'redux';
import { LayoutActions }      from '../../store/actions/layout';
import { connect }            from 'react-redux';

class TableFilterButton extends React.Component {
    getButtonText = () => {
        if (!this.props.tableFilterVisible) {
            return I18n.t('tableFilterShow');
        }

        return I18n.t('tableFilterHide');
    };

    toggleFilterButtonPressed = () => {
        this.props.tableFilterToggleVisibility();
    };

    render () {
        const buttonText = this.getButtonText();

        return (
            <ColorButton
                className={styles.tableFilterButton}
                iconType={IconType.search}
                onClick={this.toggleFilterButtonPressed}
                text={buttonText}
                theme={ColorButtonTheme.graySmall}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableFilterButton;

Component.propTypes = {
    tableFilterVisible: PropTypes.bool,
};

Component.defaultProps = {
    tableFilterVisible: false,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        tableFilterVisible: state.layout.tableFilterVisible,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(LayoutActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);