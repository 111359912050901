import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import _               from 'lodash';
import classNames      from 'classnames';
import TextButtonTheme from './TextButtonTheme';
import SelectionHelper from '../../helper/SelectionHelper';
import IconType        from '../Icon/IconType';
import Icon            from '../Icon';
import ComponentHelper from '../../helper/ComponentHelper';

class TextButton extends React.Component {
    render () {
        return (
            <span
                className={classNames(
                    styles.textButton,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [TextButtonTheme.gray]: styles.textButtonGray,
                            [TextButtonTheme.red]:  styles.textButtonRed,
                        },
                    ),
                )}
                onClick={this.props.onClick}
            >
                {this.renderIcon()}
                {this.props.text}
            </span>
        );
    }

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <Icon
                    iconType={this.props.iconType}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TextButton;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    onClick:  PropTypes.func,
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(TextButtonTheme)),
};

Component.defaultProps = {
    iconType: null,
    onClick:  _.noop,
    text:     '',
    theme:    null,
};

Component.renderAffectingProps = [
    'text',
    'theme',
];

Component.renderAffectingStates = [];

export default Component;