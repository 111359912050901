//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '../../helper/ComponentHelper';
import IconAdd         from '../../assets/icons/add.svg';
import IconArrowDown   from '../../assets/icons/arrowDown.svg';
import IconArrowLeft   from '../../assets/icons/arrowLeft.svg';
import IconArrowRight  from '../../assets/icons/arrowRight.svg';
import IconArrowUp     from '../../assets/icons/arrowUp.svg';
import IconBurger      from '../../assets/icons/burger.svg';
import IconCheck       from '../../assets/icons/check.svg';
import IconClock       from '../../assets/icons/clock.svg';
import IconCopy        from '../../assets/icons/copy.svg';
import IconCross       from '../../assets/icons/cross.svg';
import IconFolder      from '../../assets/icons/folder.svg';
import IconKey         from '../../assets/icons/key.svg';
import IconLisPlus     from '../../assets/icons/listPlus.svg';
import IconPackage     from '../../assets/icons/package.svg';
import IconPencil      from '../../assets/icons/pencil.svg';
import IconSearch      from '../../assets/icons/search.svg';
import IconType        from './IconType';
import IconUser        from '../../assets/icons/user.svg';
import PropTypes       from '../PropTypes';
import React           from 'react';
import SelectionHelper from '../../helper/SelectionHelper';
import styles          from './styles.module.scss';

class Icon extends React.Component {
    render () {
        return (
            <span className={styles.icon}>
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.add]:        <IconAdd />,
                [IconType.arrowDown]:  <IconArrowDown />,
                [IconType.arrowUp]:    <IconArrowUp />,
                [IconType.arrowLeft]:  <IconArrowLeft />,
                [IconType.arrowRight]: <IconArrowRight />,
                [IconType.burger]:     <IconBurger />,
                [IconType.check]:      <IconCheck />,
                [IconType.clock]:      <IconClock />,
                [IconType.copy]:       <IconCopy />,
                [IconType.cross]:      <IconCross />,
                [IconType.folder]:     <IconFolder />,
                [IconType.key]:        <IconKey />,
                [IconType.listPlus]:   <IconLisPlus />,
                [IconType.package]:    <IconPackage />,
                [IconType.pencil]:     <IconPencil />,
                [IconType.search]:     <IconSearch />,
                [IconType.user]:       <IconUser />,
            },
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Icon;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
