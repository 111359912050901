import _               from 'lodash';
import colors          from '../../styles/colors.scss';
import ComponentHelper from '../../helper/ComponentHelper';
import fonts           from '../../styles/fonts.scss';
import Highlighter     from 'react-highlight-words';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import React           from 'react';
import Select          from 'react-select';
import SelectTheme     from './SelectTheme';
import styles          from './styles.module.scss';
import zIndexes        from '../../styles/zIndexes.scss';

class SelectInternal extends React.Component {
    getCustomComponents = () => {
        const components = {};

        if (this.props.hideArrow) {
            components.DropdownIndicator = () => {
                return null;
            };
        }

        return components;
    };

    getCustomControlStyle = (provided, state) => {
        const customStyle = {
            ...provided,
            background:   'none',
            borderColor:  (
                              state.isSelected || state.isFocused ?
                                  colors.colorRed :
                                  colors.colorGrayLight3
                          ) + ' !important',
            borderRadius: 4,
            boxShadow:    'none !important',
            cursor:       'pointer',
            height:       '100%',
            outline:      0,
        };

        if (this.props.theme === SelectTheme.noBorderLeft) {
            customStyle.borderTopLeftRadius    = 0;
            customStyle.borderBottomLeftRadius = 0;
            customStyle.borderLeftWidth        = 0;
        }

        return customStyle;
    };

    getCustomStyles = () => {
        const customStyles = {
            container:          (provided, state) => {
                return {
                    ...provided,
                    flex: 1,
                };
            },
            control:            this.getCustomControlStyle,
            dropdownIndicator:  (provided, state) => {
                return {
                    ...provided,
                    color: colors.colorGrayLight3,
                };
            },
            indicatorSeparator: (provided, state) => {
                return {
                    ...provided,
                    display: 'none',
                };
            },
            menu:               (provided, state) => {
                return {
                    ...provided,

                    boxShadow:    'none',
                    padding:      0,
                    marginBottom: 0,
                    marginTop:    10,
                    zIndex:       zIndexes.zIndexControlDropdown,
                };
            },
            menuList:           (provided, state) => {
                return {
                    ...provided,
                    padding:      0,
                    borderWidth:  1,
                    borderColor:  colors.colorGrayLight3,
                    borderStyle:  'solid',
                    borderRadius: 4,
                    overflow:     'scroll',
                };
            },
            noOptionsMessage:   (provided, state) => {
                return {
                    ...provided,
                    color:      colors.colorGrayLight2,
                    fontSize:   14,
                    fontFamily: fonts.fontSourceSansPro,
                };
            },
            option:             (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: (
                                         state.isSelected || state.isFocused ?
                                             colors.colorGrayLight6 :
                                             colors.colorWhite
                                     ) + ' !important',
                    color:           colors.colorGrayLight2,
                    fontSize:        14,
                    fontFamily:      fonts.fontSourceSansPro,
                };
            },
            placeholder:        (provided, state) => {
                return {
                    ...provided,
                    color:      colors.colorGrayLight2,
                    fontSize:   14,
                    fontFamily: fonts.fontSourceSansPro,
                };
            },
            singleValue:        (provided, state) => {
                return {
                    ...provided,
                    color:      colors.colorGrayLight2,
                    fontSize:   14,
                    fontFamily: fonts.fontSourceSansPro,
                };
            },
        };

        return customStyles;
    };

    getNoOptionsMessage = () => {
        return I18n.t('selectNoSearchResults');
    };

    render () {
        const customComponents = this.getCustomComponents();
        const customStyles     = this.getCustomStyles();

        return (
            <Select
                components={customComponents}
                formatOptionLabel={this.renderSelectFormatOptionLabel}
                noOptionsMessage={this.getNoOptionsMessage}
                onChange={this.props.onChange}
                options={this.props.options}
                placeholder={this.props.placeholder}
                ref={this.props.innerRef}
                styles={customStyles}
                value={this.props.value}
            />
        );
    };

    renderSelectFormatOptionLabel = ({ label }, { inputValue }) => {
        return (
            <Highlighter
                highlightClassName={styles.selectHighlightedText}
                searchWords={[inputValue]}
                textToHighlight={label}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SelectInternal;

Component.propTypes = {
    hideArrow:   PropTypes.bool,
    innerRef:    PropTypes.func,
    onChange:    PropTypes.func,
    options:     PropTypes.array,
    placeholder: PropTypes.string,
    theme:       PropTypes.oneOf(Object.values(SelectTheme)),
    value:       PropTypes.any,
};

Component.defaultProps = {
    hideArrow:   false,
    innerRef:    _.noop,
    onChange:    _.noop,
    options:     [],
    placeholder: null,
    theme:       SelectTheme.default,
    value:       null,
};

Component.renderAffectingProps = [
    'hideArrow',
    'iconType',
    'placeholder',
    'options',
    'value',
];

Component.renderAffectingStates = [];

export default SelectInternal;