import ComponentHelper from '../../helper/ComponentHelper';
import I18n            from 'i18next';
import React           from 'react';
import PropTypes       from '../PropTypes';
import styles          from './styles.module.scss';
import IconBox         from '../IconBox';
import IconType        from '../Icon/IconType';
import _               from 'lodash';
import TextInputType   from './TextInputType';

class TextInput extends React.Component {
    inputReference = null;

    iconBoxClicked = () => {
        if (this.inputReference) {
            this.inputReference.focus();
        }
    };

    render () {
        return (
            <div className={styles.textInputWrapper}>
                {this.renderIconBox()}
                {this.renderTextInput()}
            </div>
        );
    }

    renderIconBox = () => {
        if (this.props.iconType) {
            return (
                <IconBox
                    iconType={this.props.iconType}
                    onClick={this.iconBoxClicked}
                />
            );
        }

        return null;
    };

    renderTextInput = () => {
        if (this.props.type === TextInputType.textarea) {
            return (
                <textarea
                    onChange={this.props.onChange}
                    onFocus={this.props.onFocus}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                />
            );
        }

        return (
            <input
                onChange={this.props.onChange}
                onFocus={this.props.onFocus}
                placeholder={this.props.placeholder}
                ref={this.setInputReference}
                type={this.props.type}
                value={this.props.value}
            />
        );
    };

    setInputReference = (inputReference) => {
        this.inputReference = inputReference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TextInput;

Component.propTypes = {
    iconType:    PropTypes.oneOf(Object.values(IconType)),
    onChange:    PropTypes.func,
    onFocus:     PropTypes.func,
    placeholder: PropTypes.string,
    type:        PropTypes.oneOf(Object.values(TextInputType)),
    value:       PropTypes.string,
};

Component.defaultProps = {
    iconType:    null,
    onChange:    _.noop,
    onFocus:     _.noop,
    placeholder: null,
    type:        TextInputType.text,
    value:       '',
};

Component.renderAffectingProps = [
    'iconType',
    'placeholder',
    'value',
];

Component.renderAffectingStates = [];

export default Component;