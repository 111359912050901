'use strict';

import _ from 'lodash';

export default class WorkingPackageTitleHelper {
    static getWorkingPackageTitle (workingPackage) {
        if (workingPackage) {
            let workingPackageTitlePrefix = _.get(workingPackage, 'sortOrder', '');

            if (workingPackageTitlePrefix) {
                workingPackageTitlePrefix += ' ';
            }

            const workingPackageTitle = [
                workingPackageTitlePrefix,
                _.get(workingPackage, 'title', '-'),
            ].join('');

            return workingPackageTitle;
        }

        return '-';
    }
}