import Cast            from '../../helper/Cast';
import classNames      from 'classnames';
import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class StatusLabel extends React.Component {
    getLabel = () => {
        const cleanValue = (
            // @formatter:off
            Cast.string((this.props.process * 100).toFixed(2)) +
            ' %'
            // @formatter:on
        );

        return cleanValue;
    };

    getStyleClass = () => {
        const value = this.props.process;

        // @formatter:off
        if (value >= 1.00) return styles.progressBar100;
        if (value >= 0.75) return styles.progressBarOver75;
        if (value >= 0.50) return styles.progressBarOver50;
        if (value >= 0.25) return styles.progressBarOver25;
        // @formatter:on

        return styles.progressBar0;
    };

    getWidth = () => {
        const width = Cast.int(this.props.process * 100) + '%';

        return width;
    };

    render () {
        const label      = this.getLabel();
        const styleClass = this.getStyleClass();
        const width      = this.getWidth();

        return (
            <span
                className={classNames(
                    // This is used to target the progress bar in tables to change the background
                    // color from white to gray
                    'progressBar',
                    styles.progressBar,
                    styleClass,
                )}
                title={label}
            >
                <span
                    className={styles.progressBarProgress}
                    style={{
                        width,
                    }}
                >
                    {label}
                </span>
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = StatusLabel;

Component.propTypes = {
    process: PropTypes.any,
};

Component.defaultProps = {
    process: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;