import { LayoutTypes } from '../actions/layout';
import update          from 'immutability-helper';
import { UserTypes }   from '../actions/user';

const initialLeftSideBarSize  = 260;
const initialRightSideBarSize = 200;

const initialState = {
    groupByDate:         true,
    leftSideBarVisible:  true,
    leftSideBarSize:     initialLeftSideBarSize,
    rightSideBarVisible: true,
    rightSideBarSize:    initialRightSideBarSize,
    tableFilterVisible:  false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LayoutTypes.ALL_SIDEBAR_TOGGLE_VISIBILITY:
            return update(state, {
                $toggle: [
                    'leftSideBarVisible',
                    'rightSideBarVisible',
                ],
            });

        case LayoutTypes.GROUP_BY_DATE_TOGGLE:
            return update(state, {
                $toggle: [
                    'groupByDate',
                ],
            });

        case LayoutTypes.LEFT_SIDEBAR_TOGGLE_VISIBILITY:
            return update(state, {
                $toggle: [
                    'leftSideBarVisible',
                ],
            });

        case LayoutTypes.RIGHT_SIDEBAR_TOGGLE_VISIBILITY:
            return update(state, {
                $toggle: [
                    'rightSideBarVisible',
                ],
            });

        case LayoutTypes.TABLE_FILTER_TOGGLE_VISIBILITY:
            return update(state, {
                $toggle: [
                    'tableFilterVisible',
                ],
            });

        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        default:
            return state;
    }
}