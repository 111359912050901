//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import Table                        from '../Table';
import DefaultColumns               from '../Table/DefaultColumns';
import { connect }                  from 'react-redux';
import moment                       from 'moment';
import I18n                         from 'i18next';
import _                            from 'lodash';
import SortDirection                from '../../constants/SortDirection';
import PropTypes                    from '../PropTypes';
import WorkingPackageTitleHelper    from '../../helper/WorkingPackageTitleHelper';
import ComponentHelper              from '../../helper/ComponentHelper';
import UserTimeTrackingTableRowType from './UserTimeTrackingTableRowType';

class UserTimeTrackingTable extends React.Component {
    getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get({
            withLongDate: true,
        });

        return [
            defaultColumns.projectNumber,
            defaultColumns.projectTitle,
            defaultColumns.workingPackageTitle,
            defaultColumns.text,
            defaultColumns.date,
            defaultColumns.duration,
            defaultColumns.status,
            defaultColumns.timeTrackingActions,
        ];
    };

    getFormattedDate = (date) => {
        const dateAsMomentDate = new moment(date);
        const formattedDate    = dateAsMomentDate.format(I18n.t('listDateFormatWithShortDay'));

        return formattedDate;
    };

    getProjectTimeBookings = () => {
        let projectTimeBookings = [];
        const monthYearFilter   = this.props.monthYearFilter;
        const dateFilter        = this.props.dateFilter;

        if (this.props.projectTimeBookings) {
            const dateSumBuffer = [];
            let currentDate     = null;

            for (const projectTimeBooking of this.props.projectTimeBookings) {
                const workingPackageTitle = WorkingPackageTitleHelper.getWorkingPackageTitle(projectTimeBooking.workingPackage);
                const date                = new Date(projectTimeBooking.date);
                const momentDate          = moment(date);
                const formattedDate       = this.getFormattedDate(projectTimeBooking.date);

                if (
                    (
                        monthYearFilter &&
                        (
                            monthYearFilter.month != date.getMonth() + 1 ||
                            monthYearFilter.year != date.getFullYear()
                        )
                    ) ||
                    (
                        dateFilter &&
                        (
                            !momentDate.isAfter(dateFilter.from) ||
                            !momentDate.isBefore(dateFilter.to)
                        )
                    )
                ) {
                    continue;
                }

                if (this.props.groupByDate) {
                    if (!dateSumBuffer[formattedDate]) {
                        dateSumBuffer[formattedDate] = 0;
                    }

                    dateSumBuffer[formattedDate] += projectTimeBooking.timeInHours;

                    if (currentDate === null) {
                        currentDate = formattedDate;
                    } else if (currentDate !== formattedDate) {
                        projectTimeBookings.push({
                            date:     currentDate,
                            duration: dateSumBuffer[currentDate],
                            type:     UserTimeTrackingTableRowType.sum,
                        });

                        currentDate = formattedDate;
                    }
                }

                projectTimeBookings.push({
                    actions:             projectTimeBooking,
                    date:                formattedDate,
                    description:         projectTimeBooking.text,
                    duration:            projectTimeBooking.timeInHours,
                    projectNumber:       _.get(projectTimeBooking, 'project.number', '-'),
                    projectState:        _.get(projectTimeBooking, 'project.state', '-'),
                    projectTitle:        _.get(projectTimeBooking, 'project.title', '-'),
                    type:                UserTimeTrackingTableRowType.default,
                    workingPackageTitle: workingPackageTitle,
                });
            }

            if (this.props.groupByDate) {
                projectTimeBookings.push({
                    date:     currentDate,
                    duration: dateSumBuffer[currentDate],
                    type:     UserTimeTrackingTableRowType.sum,
                });
            }
        }

        return projectTimeBookings;
    };

    render () {
        const projectTimeBookings = this.getProjectTimeBookings();
        const tableKey            = 'table' + (
            this.props.groupByDate ? 'Grouped' : 'Default'
        );

        return (
            <Table
                columnConfiguration={this.getColumnConfiguration()}
                key={tableKey}
                rawData={projectTimeBookings}
                sortColumn={'date'}
                sortDirection={SortDirection.desc}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserTimeTrackingTable;

Component.propTypes = {
    groupByDate:         PropTypes.bool,
    projectTimeBookings: PropTypes.array,
    monthYearFilter:     PropTypes.any,
    dateFilter:          PropTypes.any,
};

Component.defaultProps = {
    groupByDate:         true,
    projectTimeBookings: [],
    monthYearFilter:     false,
    dateFilter:          false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        groupByDate:         state.layout.groupByDate,
        projectTimeBookings: state.projectTimeBooking.projectTimeBookings,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
