import colors from '../../styles/colors.scss';

const SplitPaneStyle = {
    resizerHorizontal:       {
        backgroundColor: colors.colorGrayLight6,
        height:          2,
        cursor:          'ns-resize',
    },
    resizerHorizontalHidden: {
        height: 0,
        cursor: 'default',
    },
    resizerVertical:         {
        backgroundColor: colors.colorGrayLight6,
        width:           2,
        cursor:          'ew-resize',
    },
    resizerVerticalHidden:   {
        width:  0,
        cursor: 'default',
    },
};

export default SplitPaneStyle;