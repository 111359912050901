import React           from 'react';
import PropTypes       from '../PropTypes';
import LongTextLabel   from '../LongTextLabel';
import ComponentHelper from '../../helper/ComponentHelper';

class TableLongTextFormatter extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <LongTextLabel
                    value={this.props.value}
                />
            );
        }

        return '';
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableLongTextFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;