//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const UserTypes = {
    LOGIN:           'User/LOGIN',
    LOGIN_FAILED:    'User/LOGIN_FAILED',
    LOGIN_SUCCEEDED: 'User/LOGIN_SUCCEEDED',
    LOGOUT:          'User/LOGOUT',
    SET_PASSWORD:    'User/SET_PASSWORD',
    SET_USERNAME:    'User/SET_USERNAME',
};

const login = makeActionCreator(
    UserTypes.LOGIN,
);

const loginFailed = makeActionCreator(
    UserTypes.LOGIN_FAILED,
);

const loginSucceeded = makeActionCreator(
    UserTypes.LOGIN_SUCCEEDED, {
        lastLogin:        null,
        isProjectManager: null,
        token:            null,
        username:         null,
        id:               null,
    },
);

const logout = makeActionCreator(
    UserTypes.LOGOUT,
);

const setPassword = makeActionCreator(
    UserTypes.SET_PASSWORD,
    {
        password: null,
    },
);

const setUsername = makeActionCreator(
    UserTypes.SET_USERNAME,
    {
        username: null,
    },
);

export const UserActions = {
    login,
    loginFailed,
    loginSucceeded,
    logout,
    setPassword,
    setUsername,
};
