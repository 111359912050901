import { makeActionCreator } from '../../helper/Store';

export const LayoutTypes = {
    ALL_SIDEBAR_TOGGLE_VISIBILITY:   'Layout/ALL_SIDEBAR_TOGGLE_VISIBILITY',
    GROUP_BY_DATE_TOGGLE:            'Layout/GROUP_BY_DATE_TOGGLE',
    LEFT_SIDEBAR_SET_SIZE:           'Layout/LEFT_SIDEBAR_SET_SIZE',
    LEFT_SIDEBAR_TOGGLE_VISIBILITY:  'Layout/LEFT_SIDEBAR_TOGGLE_VISIBILITY',
    RIGHT_SIDEBAR_SET_SIZE:          'Layout/RIGHT_SIDEBAR_SET_SIZE',
    RIGHT_SIDEBAR_TOGGLE_VISIBILITY: 'Layout/RIGHT_SIDEBAR_TOGGLE_VISIBILITY',
    TABLE_FILTER_TOGGLE_VISIBILITY:  'Layout/TABLE_FILTER_TOGGLE_VISIBILITY',
};

const allSideBarToggleVisibility = makeActionCreator(
    LayoutTypes.ALL_SIDEBAR_TOGGLE_VISIBILITY,
);

const groupByDateToggle = makeActionCreator(
    LayoutTypes.GROUP_BY_DATE_TOGGLE,
);

const leftSideBarSetSize = makeActionCreator(
    LayoutTypes.LEFT_SIDEBAR_SET_SIZE,
    {
        size: null,
    },
);

const leftSideBarToggleVisibility = makeActionCreator(
    LayoutTypes.LEFT_SIDEBAR_TOGGLE_VISIBILITY,
);

const rightSideBarSetSize = makeActionCreator(
    LayoutTypes.LEFT_SIDEBAR_SET_SIZE,
    {
        size: null,
    },
);

const rightSideBarToggleVisibility = makeActionCreator(
    LayoutTypes.RIGHT_SIDEBAR_TOGGLE_VISIBILITY,
);

const tableFilterToggleVisibility = makeActionCreator(
    LayoutTypes.TABLE_FILTER_TOGGLE_VISIBILITY,
);

export const LayoutActions = {
    allSideBarToggleVisibility,
    groupByDateToggle,
    leftSideBarSetSize,
    leftSideBarToggleVisibility,
    rightSideBarSetSize,
    rightSideBarToggleVisibility,
    tableFilterToggleVisibility,
};