import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class HeadlineOverlay extends React.Component {
    render () {
        return (
            <div className={styles.headlineOverlay}>
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HeadlineOverlay;

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;