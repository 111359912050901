//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { create }   from 'apisauce';
import * as ApiUrls from '../constants/Api';
import moment       from 'moment';

const createInstance = (host, apiPath) => {
    const api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
            'accept':       'application/json',
        },
    });

    api.host = host;

    return api;
};

const Api = createInstance(ApiUrls.default.getBackendUrl(), '/');

const deleteProjectTimeBooking = (
    id,
) => {
    return Api.delete(
        ApiUrls.API_PROJECT_TIME_BOOKINGS_URL + '/' + id,
    );
};

const fetchAllHolidays = () => {
    return Api.get(ApiUrls.API_HOLIDAYS_URL);
};

const fetchAllProjects = () => {
    return Api.get(ApiUrls.API_PROJECTS_URL);
};

const fetchAllProjectTimeBookings = () => {
    return Api.get(ApiUrls.API_PROJECT_TIME_BOOKINGS_URL);
};

const fetchAllProjectTimeBookingsYearMonth = (year, month) => {
    const beginOfTheMonth = new Date(year, month - 1, 1);
    const dateAfter       = moment(beginOfTheMonth).startOf('week').toDate();
    const dateBefore      = moment(beginOfTheMonth).endOf('month').endOf('week').toDate();

    return Api.get(
        ApiUrls.API_PROJECT_TIME_BOOKINGS_URL,
        {
            'date[before]': dateBefore,
            'date[after]':  dateAfter,
        },
    );
};

const fetchAllTextTemplates = () => {
    return Api.get(ApiUrls.API_TEXT_TEMPLATES_URL);
};

const fetchAllUserTimeDaySums = () => {
    return Api.get(ApiUrls.API_USER_TIME_DAY_SUM_URL);
};

const fetchAllUserTimeMonthSums = () => {
    return Api.get(ApiUrls.API_USER_TIME_MONTH_SUM_URL);
};

const fetchAllUserTimeProjectSums = () => {
    return Api.get(ApiUrls.API_USER_TIME_PROJECT_SUM_URL);
};

const fetchAllUserTimeYearSums = () => {
    return Api.get(ApiUrls.API_USER_TIME_YEAR_SUM_URL);
};

const fetchProject = (id) => {
    return Api.get(ApiUrls.API_PROJECTS_URL + '/' + id);
};

const login = (
    username,
    password,
) => {
    return Api.post(
        ApiUrls.API_TOKENS,
        {
            email: username,
            password,
        },
    );
};

const setToken = (token) => {
    const authorization = 'Bearer ' + token;

    console.log('API: setToken', token, authorization);

    Api.setHeader('Authorization', authorization);
};

const submitProjectTimeBooking = (
    date,
    project,
    workingPackage,
    text,
    time,
) => {
    return Api.post(
        ApiUrls.API_PROJECT_TIME_BOOKINGS_URL,
        {
            date,
            project,
            workingPackage,
            text,
            timeInHours: time,
        },
    );
};

const updateProjectTimeBooking = (
    id,
    date,
    project,
    workingPackage,
    text,
    time,
) => {
    return Api.put(
        ApiUrls.API_PROJECT_TIME_BOOKINGS_URL + '/' + id,
        {
            date,
            project,
            workingPackage,
            text,
            timeInHours: time,
        },
    );
};

export {
    Api,
    deleteProjectTimeBooking,
    fetchAllHolidays,
    fetchAllProjects,
    fetchAllProjectTimeBookings,
    fetchAllProjectTimeBookingsYearMonth,
    fetchAllTextTemplates,
    fetchAllUserTimeDaySums,
    fetchAllUserTimeMonthSums,
    fetchAllUserTimeProjectSums,
    fetchAllUserTimeYearSums,
    fetchProject,
    login,
    setToken,
    submitProjectTimeBooking,
    updateProjectTimeBooking,
};
