import { TextTemplateActions } from '../actions/textTemplate';
import { put }                 from 'redux-saga/effects';
import * as Api                from '../../api';
import { call }                from 'redux-saga/effects';

const fetchAllTextTemplates = function* () {
    const response = yield call(Api.fetchAllTextTemplates);

    if (response.ok) {
        const textTemplates = response.data;

        yield put(TextTemplateActions.fetchAllTextTemplatesSucceeded({
            textTemplates,
        }));
    } else {
        yield put(TextTemplateActions.fetchAllTextTemplatesFailed());
    }
};

export default {
    fetchAllTextTemplates,
};
