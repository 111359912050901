import _                             from 'lodash';
import React                         from 'react';
import I18n                          from 'i18next';
import PropTypes                     from '../PropTypes';
import IconButton                    from '../IconButton';
import IconButtonTheme               from '../IconButton/IconButtonTheme';
import IconType                      from '../Icon/IconType';
import { bindActionCreators }        from 'redux';
import { ProjectTimeBookingActions } from '../../store/actions/projectTimeBooking';
import { connect }                   from 'react-redux';
import { withRouter }                from 'react-router-dom';
import styles                        from './styles.module.scss';
import Routes                        from '../../constants/Routes';
import ComponentHelper               from '../../helper/ComponentHelper';
import ProjectStatus                 from '../../constants/ProjectStatus';

class TimeTrackingActionList extends React.Component {
    editButtonPressed = () => {
        this.props.setProjectTimeBooking({
            projectTimeBooking: this.props.projectTimeBooking,
        });

        this.props.history.push(Routes.timeTrackingEdit);
    };

    render () {
        if (this.props.projectTimeBooking) {
            return (
                <ul className={styles.timeTrackingActionList}>
                    {this.renderEditButton()}
                </ul>
            );
        }
    }

    renderEditButton = () => {
        const projectState    = _.get(this.props.projectTimeBooking, 'project.state');
        const isProjectActive = projectState === _.toUpper(ProjectStatus.active);

        if (isProjectActive) {
            return (
                <li>
                    <IconButton
                        iconType={IconType.pencil}
                        onClick={this.editButtonPressed}
                        theme={IconButtonTheme.grayRounded}
                        title={I18n.t('timeTrackingEdit')}
                    />
                </li>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TimeTrackingActionList;

Component.propTypes = {
    projectTimeBooking: PropTypes.object,
};

Component.defaultProps = {
    projectTimeBooking: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(ProjectTimeBookingActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Component));
