//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { all }             from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { takeLatest }      from 'redux-saga/effects';

// Types
import { HolidayTypes }            from '../actions/holidays';
import { LoadingTypes }            from '../actions/loading';
import { ProjectTimeBookingTypes } from '../actions/projectTimeBooking';
import { ProjectTypes }            from '../actions/project';
import { TextTemplateTypes }       from '../actions/textTemplate';
import { UserTimeSumTypes }        from '../actions/userTimeSum';
import { UserTypes }               from '../actions/user';

// Sagas
import HolidaySagas            from './holidays';
import LoadingSagas            from './loading';
import ProjectSagas            from './project';
import ProjectTimeBookingsSaga from './projectTimeBookings';
import TextTemplateSagas       from './textTemplate';
import UserSagas               from './user';
import UserTimeSumSagas        from './userTimeSum';

const root = function* () {
    yield all([
        // @formatter:off
        takeLatest(ProjectTypes.FETCH_ALL_PROJECTS,                                    ProjectSagas.fetchAllProjects),
        takeLatest(ProjectTypes.FETCH_PROJECT,                                         ProjectSagas.fetchProject),

        takeLatest(ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING,                ProjectTimeBookingsSaga.deleteProjectTimeBooking),
        takeLatest(ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_FAILED,         ProjectTimeBookingsSaga.deleteProjectTimeBookingFailed),
        takeLatest(ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_SUCCEEDED,      ProjectTimeBookingsSaga.deleteProjectTimeBookingSucceeded),
        takeLatest(ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS,            ProjectTimeBookingsSaga.fetchAllProjectTimeBookings),
        takeLatest(ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH, ProjectTimeBookingsSaga.fetchAllProjectTimeBookingsYearMonth),
        takeLatest(ProjectTimeBookingTypes.SET_PROJECT,                                ProjectTimeBookingsSaga.setProject),
        takeLatest(ProjectTimeBookingTypes.SET_PROJECT_TIME_BOOKING,                   ProjectTimeBookingsSaga.setProjectTimeBooking),
        takeLatest(ProjectTimeBookingTypes.SET_TEXT_TEMPLATE,                          ProjectTimeBookingsSaga.setTextTemplate),
        takeLatest(ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING,                ProjectTimeBookingsSaga.submitProjectTimeBooking),
        takeLatest(ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED,      ProjectTimeBookingsSaga.submitProjectTimeBookingSucceeded),
        takeLatest(ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING,                ProjectTimeBookingsSaga.updateProjectTimeBooking),
        takeLatest(ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING_SUCCEEDED,      ProjectTimeBookingsSaga.updateProjectTimeBookingSucceeded),
        takeLatest(ProjectTimeBookingTypes.OPEN_PROJECT_TIME_BOOKINGS,                 ProjectTimeBookingsSaga.openProjectTimeBookings),
        takeLatest(LOCATION_CHANGE,                                                    ProjectTimeBookingsSaga.locationChanged),

        takeLatest(TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES,                         TextTemplateSagas.fetchAllTextTemplates),

        takeLatest(HolidayTypes.FETCH_ALL_HOLIDAYS,                                    HolidaySagas.fetchAllHolidays),

        takeLatest(UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS,                      UserTimeSumSagas.fetchAllUserTimeDaySums),
        takeLatest(UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS,                    UserTimeSumSagas.fetchAllUserTimeMonthSums),
        takeLatest(UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS,                  UserTimeSumSagas.fetchAllUserTimeProjectSums),
        takeLatest(UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS,                     UserTimeSumSagas.fetchAllUserTimeYearSums),
        takeLatest(UserTimeSumTypes.OPEN_DASHBOARD,                                    UserTimeSumSagas.openDashboard),

        takeLatest(UserTypes.LOGIN,                                                    UserSagas.login),
        takeLatest(UserTypes.LOGIN_FAILED,                                             UserSagas.loginFailed),
        takeLatest(UserTypes.LOGIN_SUCCEEDED,                                          UserSagas.loginSucceeded),
        takeLatest(UserTypes.LOGOUT,                                                   UserSagas.logout),

        takeLatest(LoadingTypes.OVERLAY_CLICKED,                                       LoadingSagas.overlayClicked),

        takeLatest(REHYDRATE,                                                          UserSagas.restoreToken),
        // @formatter:on
    ]);
};

export default {
    root,
};
