//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                           from 'lodash';
import DateHelper                  from '../../helper/Date';
import I18n                        from 'i18next';
import moment                      from 'moment';
import Routes                      from '../../constants/Routes';
import update                      from 'immutability-helper';
import { LOCATION_CHANGE }         from 'connected-react-router';
import { ProjectTimeBookingTypes } from '../actions/projectTimeBooking';
import { UserTypes }               from '../actions/user';

const initialState = {
    addAnotherTimeBookingAfterSave:          false,
    projectTimeBookingCreateEditContext:     {
        date:         new Date(),
        duration:     {
            hours:   0,
            minutes: 0,
        },
        id:           null,
        project:      null,
        text:         '',
        textTemplate: null,
    },
    projectTimeBookings:                     [],
    projectsWithTotalTimeOrderedByTotalTime: [],
};

const locationChange = function (action, state) {
    const pathname = action.payload.location.pathname;

    if (
        pathname !== Routes.timeTrackingCreate &&
        pathname !== Routes.timeTrackingEdit
    ) {
        return update(state, {
            projectTimeBookingCreateEditContext: {
                $set: initialState.projectTimeBookingCreateEditContext,
            },
        });
    }

    return state;
};

const decreaseDate = function (action, state) {
    const previousDay = DateHelper.getPreviousWorkingDay(
        state.projectTimeBookingCreateEditContext.date,
        action.holidays,
    );

    return update(state, {
        projectTimeBookingCreateEditContext: {
            date: {
                $set: previousDay,
            },
        },
    });
};

const decreaseDurationHours = function (action, state) {
    let previousHours = state.projectTimeBookingCreateEditContext.duration.hours - 1;

    if (previousHours < 0) {
        previousHours = 24;
    }

    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                hours: {
                    $set: previousHours,
                },
            },
        },
    });
};

const decreaseDurationMinutes = function (action, state) {
    let previousMinutes = state.projectTimeBookingCreateEditContext.duration.minutes - 15;

    if (previousMinutes < 0) {
        previousMinutes = 45;
    }

    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                minutes: {
                    $set: previousMinutes,
                },
            },
        },
    });
};

const increaseDate = function (action, state) {
    const nextDay = DateHelper.getNextWorkingDay(state.projectTimeBookingCreateEditContext.date, action.holidays);

    return update(state, {
        projectTimeBookingCreateEditContext: {
            date: {
                $set: nextDay,
            },
        },
    });
};

const increaseDurationHours = function (action, state) {
    let nextHours = state.projectTimeBookingCreateEditContext.duration.hours + 1;

    if (nextHours > 24) {
        nextHours = 0;
    }

    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                hours: {
                    $set: nextHours,
                },
            },
        },
    });
};

const increaseDurationMinutes = function (action, state) {
    let nextMinutes = state.projectTimeBookingCreateEditContext.duration.minutes + 15;

    if (nextMinutes > 45) {
        nextMinutes = 0;
    }

    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                minutes: {
                    $set: nextMinutes,
                },
            },
        },
    });
};

const mapProjectTimeBookingsOrderedByTotalTime = function (projectTimeBookings) {
    let projectsWithTotalTimeOrderedByTotalTime = [];

    for (const projectTimeBooking of projectTimeBookings) {
        const project                         = projectTimeBooking.project;
        let totalTimeInHours                  = projectTimeBooking.timeInHours;
        const existingIndex                   = _.findIndex(
            projectsWithTotalTimeOrderedByTotalTime,
            {
                project: project,
            },
        );
        const projectTimeBookingsProjectTotal = projectsWithTotalTimeOrderedByTotalTime[existingIndex];

        if (projectTimeBookingsProjectTotal) {
            totalTimeInHours += projectTimeBookingsProjectTotal.totalTimeInHours;

            projectsWithTotalTimeOrderedByTotalTime.splice(existingIndex, 1, {
                project:          project,
                totalTimeInHours: totalTimeInHours,
            });
        } else {
            projectsWithTotalTimeOrderedByTotalTime.push({
                project:          project,
                totalTimeInHours: totalTimeInHours,
            });
        }
    }

    projectsWithTotalTimeOrderedByTotalTime = _.orderBy(
        projectsWithTotalTimeOrderedByTotalTime,
        ['totalTimeInHours'],
        ['asc'],
    );

    return projectsWithTotalTimeOrderedByTotalTime;
};

const fetchAllProjectTimeBookingsSucceeded = function (action, state) {
    const projectTimeBookings                     = action.projectTimeBookings;
    const projectsWithTotalTimeOrderedByTotalTime = mapProjectTimeBookingsOrderedByTotalTime(projectTimeBookings);

    return update(state, {
        projectTimeBookings:                     {
            $set: projectTimeBookings,
        },
        projectsWithTotalTimeOrderedByTotalTime: {
            $set: projectsWithTotalTimeOrderedByTotalTime,
        },
    });
};

const fetchAllProjectTimeBookingsYearMonthSucceeded = function (action, state) {
    const projectTimeBookings                     = action.projectTimeBookings;
    const projectsWithTotalTimeOrderedByTotalTime = mapProjectTimeBookingsOrderedByTotalTime(projectTimeBookings);

    return update(state, {
        projectTimeBookings:                     {
            $set: projectTimeBookings,
        },
        projectsWithTotalTimeOrderedByTotalTime: {
            $set: projectsWithTotalTimeOrderedByTotalTime,
        },
    });
};

const setAddAnotherTimeBookingAfterSave = function (action, state) {
    return update(state, {
        addAnotherTimeBookingAfterSave: {
            $set: action.addAnotherTimeBookingAfterSave,
        },
    });
};

const setDate = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            date: {
                $set: action.date,
            },
        },
    });
};

const setDurationHours = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                hours: {
                    $set: action.hours,
                },
            },
        },
    });
};

const setDurationMinutes = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            duration: {
                minutes: {
                    $set: action.minutes,
                },
            },
        },
    });
};

const setProjectTimeBooking = function (action, state) {
    const hours   = Math.floor(action.projectTimeBooking.timeInHours);
    const minutes = 60 * (
        action.projectTimeBooking.timeInHours - hours
    );

    return update(state, {
        projectTimeBookingCreateEditContext: {
            date:           {
                $set: action.projectTimeBooking.date,
            },
            duration:       {
                hours:   {
                    $set: hours,
                },
                minutes: {
                    $set: minutes,
                },
            },
            id:             {
                $set: action.projectTimeBooking.id,
            },
            project:        {
                $set: action.projectTimeBooking.project.id,
            },
            text:           {
                $set: action.projectTimeBooking.text,
            },
            workingPackage: {
                $set: _.get(action, 'projectTimeBooking.workingPackage.id'),
            },
        },
    });
};

const setProject = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            project: {
                $set: action.project,
            },
        },
    });
};

const setText = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            text: {
                $set: action.text,
            },
        },
    });
};

const setTextTemplate = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            textTemplate: {
                $set: action.textTemplate,
            },
        },
    });
};

const setWorkingPackage = function (action, state) {
    return update(state, {
        projectTimeBookingCreateEditContext: {
            workingPackage: {
                $set: action.workingPackage,
            },
        },
    });
};

const toggleAddAnotherTimeBookingAfterSave = function (action, state) {
    return update(state, {
        $toggle: [
            'addAnotherTimeBookingAfterSave',
        ],
    });
};

const submitProjectTimeBookingSucceeded = function (action, state) {
    if (state.addAnotherTimeBookingAfterSave) {
        return update(state, {
            projectTimeBookingCreateEditContext: {
                duration:     {
                    $set: initialState.projectTimeBookingCreateEditContext.duration,
                },
                text:         {
                    $set: initialState.projectTimeBookingCreateEditContext.text,
                },
                textTemplate: {
                    $set: initialState.projectTimeBookingCreateEditContext.textTemplate,
                },
            },
        });
    }

    return update(state, {
        projectTimeBookingCreateEditContext: {
            $set: initialState.projectTimeBookingCreateEditContext,
        },
    });
};

const logout = function (action, state) {
    return update(state, {
        $set: initialState,
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case LOCATION_CHANGE:                                                               return locationChange(action, state);
        case ProjectTimeBookingTypes.DECREASE_DATE:                                         return decreaseDate(action, state);
        case ProjectTimeBookingTypes.DECREASE_DURATION_HOURS:                               return decreaseDurationHours(action, state);
        case ProjectTimeBookingTypes.DECREASE_DURATION_MINUTES:                             return decreaseDurationMinutes(action, state);
        case ProjectTimeBookingTypes.INCREASE_DATE:                                         return increaseDate(action, state);
        case ProjectTimeBookingTypes.INCREASE_DURATION_HOURS:                               return increaseDurationHours(action, state);
        case ProjectTimeBookingTypes.INCREASE_DURATION_MINUTES:                             return increaseDurationMinutes(action, state);
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_SUCCEEDED:             return fetchAllProjectTimeBookingsSucceeded(action, state);
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_SUCCEEDED:  return fetchAllProjectTimeBookingsYearMonthSucceeded(action, state);
        case ProjectTimeBookingTypes.SET_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE:               return setAddAnotherTimeBookingAfterSave(action, state);
        case ProjectTimeBookingTypes.SET_DATE:                                              return setDate(action, state);
        case ProjectTimeBookingTypes.SET_DURATION_HOURS:                                    return setDurationHours(action, state);
        case ProjectTimeBookingTypes.SET_DURATION_MINUTES:                                  return setDurationMinutes(action, state);
        case ProjectTimeBookingTypes.SET_PROJECT_TIME_BOOKING:                              return setProjectTimeBooking(action, state);
        case ProjectTimeBookingTypes.SET_PROJECT:                                           return setProject(action, state);
        case ProjectTimeBookingTypes.SET_TEXT:                                              return setText(action, state);
        case ProjectTimeBookingTypes.SET_TEXT_TEMPLATE:                                     return setTextTemplate(action, state);
        case ProjectTimeBookingTypes.SET_WORKING_PACKAGE:                                   return setWorkingPackage(action, state);
        case ProjectTimeBookingTypes.TOGGLE_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE:            return toggleAddAnotherTimeBookingAfterSave(action, state);
        case ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED:                 return submitProjectTimeBookingSucceeded(action, state);
        case UserTypes.LOGOUT:                                                              return logout(action, state);
        default:                                                                            return state;
        // @formatter:on
    }
}
