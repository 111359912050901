//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                      from 'immutability-helper';
import { ProjectTimeBookingTypes } from '../actions/projectTimeBooking';
import { ProjectTypes }            from '../actions/project';
import { TextTemplateTypes }       from '../actions/textTemplate';
import { UserTimeSumTypes }        from '../actions/userTimeSum';
import { UserTypes }               from '../actions/user';
import { LOCATION_CHANGE }         from 'connected-react-router';
import { LoadingTypes }            from '../actions/loading';

const initialState = {
    clickCount: 0,
    isLoading:  false,
    level:      0,
};

const increaseClickCount = (state, action) => {
    return update(state, {
        clickCount: {
            $set: state.clickCount + 1,
        },
    });
};

const resetClickCount = (state, action) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
    });
};

const resetOverlay = (state, action) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading: {
            $set: initialState.isLoading,
        },
        level: {
            $set: initialState.level,
        }
    });
};

const decrease = (state, action) => {
    const clickCount = initialState.clickCount;
    const level      = Math.max(state.level - 1, 0);
    const isLoading  = !(
        level === 0
    );

    return update(state, {
        clickCount: {
            $set: clickCount,
        },
        isLoading:  {
            $set: isLoading,
        },
        level:      {
            $set: level,
        },
    });
};

const increase = (state, action) => {
    const levelIncrease = 1;

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: true,
        },
        level:      {
            $set: state.level + levelIncrease,
        },
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        // @formatter:off
        case LoadingTypes.RESET_OVERLAY:                                                   return resetOverlay(state, action);
        case LoadingTypes.OVERLAY_CLICKED:                                                 return increaseClickCount(state, action);

        case LOCATION_CHANGE:                                                              return resetClickCount(state, action);

        case ProjectTypes.FETCH_ALL_PROJECTS_FAILED:
        case ProjectTypes.FETCH_ALL_PROJECTS_SUCCEEDED:
        case ProjectTypes.FETCH_PROJECT_FAILED:
        case ProjectTypes.FETCH_PROJECT_SUCCEEDED:
        case ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_FAILED:
        case ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_SUCCEEDED:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_FAILED:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_SUCCEEDED:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_FAILED:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_SUCCEEDED:
        case ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_FAILED:
        case ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED:
        case ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING_FAILED:
        case ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING_SUCCEEDED:
        case TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES_FAILED:
        case TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES_SUCCEEDED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS_FAILED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS_SUCCEEDED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS_FAILED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS_SUCCEEDED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS_FAILED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS_SUCCEEDED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS_FAILED:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS_SUCCEEDED:
        case UserTypes.LOGIN_FAILED:
        case UserTypes.LOGIN_SUCCEEDED:                                                    return decrease(state, action);

        // Do not forget to extend getLoadingTextForType()
        // when you add a new action here
        case ProjectTypes.FETCH_ALL_PROJECTS:
        case ProjectTypes.FETCH_PROJECT:
        case ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS:
        case ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH:
        case ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING:
        case ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING:
        case TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS:
        case UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS:
        case UserTypes.LOGIN:                                                              return increase(state, action);
        // @formatter:on
    }

    return state;
};
