//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { AlertBoxActions }           from '../actions/alertBox';
import { ProjectTimeBookingActions } from '../actions/projectTimeBooking';
import { put }                       from 'redux-saga/effects';
import * as Api                      from '../../api';
import { select }                    from 'redux-saga/effects';
import { call }                      from 'redux-saga/effects';
import { push }                      from 'connected-react-router';
import Routes                        from '../../constants/Routes';
import _                             from 'lodash';
import I18n                          from 'i18next';
import { ProjectActions }            from '../actions/project';
import DateHelper                    from '../../helper/Date';
import * as path                     from 'path';

const deleteProjectTimeBooking = function* (action) {
    const response        = yield call(
        Api.deleteProjectTimeBooking,
        action.id,
    );
    const responsePayload = {
        id: action.id,
    };

    if (response.ok) {
        yield put(ProjectTimeBookingActions.deleteProjectTimeBookingSucceeded(responsePayload));
    } else {
        yield put(ProjectTimeBookingActions.deleteProjectTimeBookingFailed(responsePayload));
    }
};

const deleteProjectTimeBookingFailed = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('timeTrackingDeleteError1'),
    }));
};

const deleteProjectTimeBookingSucceeded = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('timeTrackingDeleteSuccess1'),
    }));

    yield put(push(Routes.getRouteWithParameters(
        Routes.timeTracking,
        {
            monthYear: '',
        },
    )));
};

const fetchAllProjectTimeBookings = function* (action) {
    const response = yield call(Api.fetchAllProjectTimeBookings);

    if (response.ok) {
        const projectTimeBookings = response.data;

        yield put(ProjectTimeBookingActions.fetchAllProjectTimeBookingsSucceeded({
            projectTimeBookings,
        }));
    } else {
        yield put(ProjectTimeBookingActions.fetchAllProjectTimeBookingsFailed());
    }
};

const fetchAllProjectTimeBookingsYearMonth = function* (action) {
    const response = yield call(Api.fetchAllProjectTimeBookingsYearMonth, action.year, action.month);

    if (response.ok) {
        const projectTimeBookings = response.data;

        yield put(ProjectTimeBookingActions.fetchAllProjectTimeBookingsYearMonthSucceeded({
            projectTimeBookings,
        }));
    } else {
        yield put(ProjectTimeBookingActions.fetchAllProjectTimeBookingsYearMonthFailed());
    }
};

const locationChanged = function* (action) {
    const pathname      = action.payload.location.pathname;
    let monthYearString = null;

    if (pathname.startsWith('/zeiterfassung')) {
        monthYearString = pathname.replace('/zeiterfassung/', '');
    }

    if (pathname.startsWith('/dashboard')) {
        monthYearString = pathname.replace('/dashboard/', '');
    }

    if (monthYearString !== null) {
        const monthYearArray = monthYearString.split('-');
        let month            = DateHelper.getCurrentMonth();
        let year             = DateHelper.getCurrentYear();

        if (monthYearArray.length >= 2) {
            month = monthYearArray[0];
            year  = monthYearArray[1];
        }

        yield put(ProjectTimeBookingActions.fetchAllProjectTimeBookingsYearMonth({
            year:  year,
            month: month,
        }));
    }
};

const setProject = function* (action) {
    const projectId = action.project;

    yield put(ProjectActions.fetchProject({
        projectId,
    }));
};

const setProjectTimeBooking = function* (action) {
    const projectId = action.projectTimeBooking.project.id;

    yield put(ProjectActions.fetchProject({
        projectId,
    }));
};

const setTextTemplate = function* (action) {
    const textTemplates = yield select(state => state.textTemplate.textTemplates);
    const textTemplate  = _.find(textTemplates, { id: action.textTemplate });

    yield put(ProjectTimeBookingActions.setProjectTimeBookingText({
        text: textTemplate.text,
    }));
};

const submitProjectTimeBooking = function* (action) {
    const project  = action.project;
    const response = yield call(
        Api.submitProjectTimeBooking,
        action.date,
        project,
        action.workingPackage,
        action.text,
        action.time,
    );

    if (response.ok) {
        const projectTimeBooking = response.data;

        yield put(ProjectTimeBookingActions.submitProjectTimeBookingSucceeded({
            projectTimeBooking,
        }));
        yield put(ProjectActions.fetchProject({
            projectId: project.id,
        }));
    } else {
        yield put(ProjectTimeBookingActions.submitProjectTimeBookingFailed());
    }
};

const submitProjectTimeBookingSucceeded = function* (action) {
    yield put(ProjectActions.fetchAllProjects());

    const projectTimeBooking = yield select(state => state.projectTimeBooking);
    const isEditMode         = projectTimeBooking.projectTimeBookingCreateEditContext.id;

    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('timeTrackingSuccess1'),
    }));

    if (isEditMode) {
        yield put(push(Routes.getRouteWithParameters(
            Routes.timeTracking,
            {
                monthYear: '',
            },
        )));
    } else {
        if (projectTimeBooking.addAnotherTimeBookingAfterSave) {
            // Nothing to do
        } else {
            yield put(push(Routes.getRouteWithParameters(
                Routes.timeTracking,
                {
                    monthYear: '',
                },
            )));
        }
    }
};

const updateProjectTimeBooking = function* (action) {
    const response = yield call(
        Api.updateProjectTimeBooking,
        action.id,
        action.date,
        action.project,
        action.workingPackage,
        action.text,
        action.time,
    );

    if (response.ok) {
        const projectTimeBooking = response.data;

        yield put(ProjectTimeBookingActions.updateProjectTimeBookingSucceeded({
            projectTimeBooking,
        }));
    } else {
        yield put(ProjectTimeBookingActions.updateProjectTimeBookingFailed());
    }
};

const updateProjectTimeBookingSucceeded = function* (action) {
    yield put(push(Routes.getRouteWithParameters(
        Routes.timeTracking,
        {
            monthYear: '',
        },
    )));
};

const openProjectTimeBookings = function* (action) {
    yield put(push(Routes.getRouteWithParameters(
        Routes.timeTracking,
        {
            monthYear: action.month + '-' + action.year,
        },
    )));
};

export default {
    deleteProjectTimeBooking,
    deleteProjectTimeBookingFailed,
    deleteProjectTimeBookingSucceeded,
    fetchAllProjectTimeBookings,
    fetchAllProjectTimeBookingsYearMonth,
    locationChanged,
    setProject,
    setProjectTimeBooking,
    setTextTemplate,
    submitProjectTimeBooking,
    submitProjectTimeBookingSucceeded,
    updateProjectTimeBooking,
    updateProjectTimeBookingSucceeded,
    openProjectTimeBookings,
};
