//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

'use strict';

import moment       from 'moment';
import _            from 'lodash';
import HolidayTypes from '../constants/HolidayTypes';

export default class DateHelper {
    static getCurrentWeekDays () {
        const currentWeekDays = [];
        const currentDate     = new Date();
        const weekDay         = this.setToBeginOfWeek(currentDate);

        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const dayOfTheYear = this.getDayOfTheYear(weekDay);

            currentWeekDays.push({
                dayOfYear: dayOfTheYear,
                year:      currentDate.getFullYear(),
            });

            weekDay.setDate(weekDay.getDate() + 1);
        }

        return currentWeekDays;
    }

    static getDaysInMonth (month, year, onlyWorkingDays = false, fillToFullWeek = false) {
        const days = [];
        const date = new Date(year, month, 1);

        if (fillToFullWeek) {
            const momentDate = moment(date);
            const maxWeekDay = _.min([momentDate.isoWeekday(), onlyWorkingDays ? 6 : 7]);

            for (let daysInFirstCalendarWeekPreviousMonth = 1; daysInFirstCalendarWeekPreviousMonth < maxWeekDay; daysInFirstCalendarWeekPreviousMonth++) {
                days.push(momentDate.subtract(1, 'days').toDate());
            }
        }

        for (let dayInMonth = 1; dayInMonth <= 31; dayInMonth++) {
            if (date.getMonth() !== month) {
                break;
            }

            const day = date.getDay();

            if (
                !onlyWorkingDays ||
                (
                    onlyWorkingDays && day >= 1 && day <= 6
                )
            ) {
                days.push(new Date(date));
            }

            date.setDate(date.getDate() + 1);
        }

        if (fillToFullWeek) {
            const momentDate     = moment(date);
            const currentWeekDay = momentDate.isoWeekday();

            if (currentWeekDay > 1) {
                const lastDayOfWeek = onlyWorkingDays ? 6 : 7;

                if (currentWeekDay < lastDayOfWeek) {
                    for (let daysInLastCalendarWeek = currentWeekDay; daysInLastCalendarWeek <= lastDayOfWeek; daysInLastCalendarWeek++) {
                        days.push(momentDate.toDate());
                        momentDate.add(1, 'day');
                    }
                }
            }
        }

        return days;
    }

    static isWorkingDay (date, holidays = []) {
        const day          = date.getDay();
        const holidayMatch = _.find(
            holidays,
            (holiday) => {
                if (moment(holiday.date).isSame(date, 'day')) {
                    const fullDayHolidayType = HolidayTypes.FULL_DAY;
                    const holidayType        = _.get(holiday, 'holidayType', fullDayHolidayType);

                    if (holidayType === fullDayHolidayType) {
                        return holiday;
                    }
                }
            },
        );

        return (
            !holidayMatch &&
            day !== 0
        );
    }

    static getNextWorkingDay (date, holidays = []) {
        const momentDate = moment(date);
        const nextDate   = momentDate.add(1, 'day').toDate();

        if (this.isWorkingDay(nextDate, holidays)) {
            return nextDate;
        }

        return this.getNextWorkingDay(nextDate, holidays);
    }

    static getPreviousWorkingDay (date, holidays = []) {
        const momentDate = moment(date);
        const nextDate   = momentDate.subtract(1, 'day').toDate();

        if (this.isWorkingDay(nextDate, holidays)) {
            return nextDate;
        }

        return this.getPreviousWorkingDay(nextDate, holidays);
    }

    static isSameDay (dateLeftSide, dateRightSide) {
        return dateLeftSide.getFullYear() === dateRightSide.getFullYear() &&
            dateLeftSide.getMonth() === dateRightSide.getMonth() &&
            dateLeftSide.getDate() === dateRightSide.getDate();
    }

    static getCurrentMonthName () {
        const currentDate = new Date();

        return this.getMonthName(currentDate.getMonth());
    }

    static getMonthName (utcMonth) {
        const monthNames = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december',
        ];

        return monthNames[utcMonth];
    }

    static getCurrentMonth () {
        const currentDate = new Date();

        return this.getMonth(currentDate);
    }

    static getMonth (date) {
        return date.getMonth() + 1;
    }

    static getCurrentYear () {
        const currentDate = new Date();

        return currentDate.getFullYear();
    }

    static isHoliday (date, holidays) {
        const holidayMatch = _.find(holidays, function (holiday) {
            const holidayDate = new Date(holiday.date);

            if (
                holidayDate.getDay() === date.getDay() &&
                holidayDate.getMonth() === date.getMonth() &&
                holidayDate.getFullYear() === date.getFullYear()
            ) {
                return holiday;
            }
        });

        return !!holidayMatch;
    }

    static setToBeginOfWeek (date) {
        let day = date.getDay() || 7;

        if (day !== 1) {
            date.setHours(
                -24 * (
                    day - 1
                ),
            );
        }

        return date;
    }

    static getWeekOfTheYear (date) {
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

        date.setUTCDate(
            date.getUTCDate() + 4
            - (
                date.getUTCDay() || 7
            ),
        );

        let yearStart = new Date(
            Date.UTC(
                date.getUTCFullYear(),
                0,
                1,
            ),
        );
        let weekNo    = Math.ceil(
            (
                (
                    (
                        date - yearStart
                    ) / 86400000
                ) + 1
            ) / 7,
        );

        return [date.getUTCFullYear(), weekNo];
    }

    static getDayOfTheYear (date) {
        const dayOfYear = moment(date).dayOfYear();

        return dayOfYear;
    };

    /**
     * @param dayOfYear First day of the year is 1
     * @param year
     * @returns {Date}
     */
    static getDateByDayOfYear (dayOfYear, year) {
        const date = new Date(year, 0);

        return new Date(date.setDate(dayOfYear));
    }

    static isLeapYear (date) {
        let year = date.getFullYear();

        if (
            (
                year & 3
            ) != 0
        ) {
            return false;
        }

        return (
            (
                year % 100
            ) != 0
            ||
            (
                year % 400
            ) == 0
        );
    };
}
