import ComponentHelper from '../../helper/ComponentHelper';
import React           from 'react';
import PropTypes       from '../PropTypes';
import styles          from './styles.module.scss';
import HeadlineTheme   from './HeadlineTheme';
import classNames      from 'classnames';
import SelectionHelper from '../../helper/SelectionHelper';

class Headline extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.headlineWrapper}>
                <div
                    className={classNames(
                        styles.headline,
                        SelectionHelper.get(
                            this.props.theme,
                            {
                                [HeadlineTheme.bold]:                styles.headlineBold,
                                [HeadlineTheme.small]:               styles.headlineSmall,
                                [HeadlineTheme.standard]:            styles.headlineStandard,
                                [HeadlineTheme.standardSmallMargin]: styles.headlineStandardSmallMargin,
                            },
                        ),
                    )}
                >
                    {this.props.text}
                </div>
                {this.renderOverlay()}
            </div>
        );
    }

    renderOverlay = () => {
        if (this.props.overlay) {
            return (
                <div className={styles.headlineOverlay}>
                    {this.props.overlay}
                </div>
            );
        }

        return null;
    };
}

const Component = Headline;

Component.propTypes = {
    overlay: PropTypes.node,
    text:    PropTypes.string,
    theme:   PropTypes.oneOf(Object.values(HeadlineTheme)),
};

Component.defaultProps = {
    overlay: null,
    text:    '',
    theme:   null,
};

Component.renderAffectingProps = [
    'text',
    'theme',
];

Component.renderAffectingStates = [];

export default Component;