import { ProjectActions } from '../actions/project';
import { put }            from 'redux-saga/effects';
import * as Api           from '../../api';
import { call }           from 'redux-saga/effects';

const fetchAllProjects = function* () {
    const response = yield call(Api.fetchAllProjects);

    if (response.ok) {
        const projects = response.data;

        yield put(ProjectActions.fetchAllProjectsSucceeded({
            projects,
        }));
    } else {
        yield put(ProjectActions.fetchAllProjectsFailed());
    }
};

const fetchProject = function* (action) {
    const response = yield call(Api.fetchProject, action.projectId);

    if (response.ok) {
        const project = response.data;

        yield put(ProjectActions.fetchProjectSucceeded({
            project,
        }));
    } else {
        yield put(ProjectActions.fetchProjectFailed());
    }
};

export default {
    fetchAllProjects,
    fetchProject,
};
