//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                                     from 'i18next';
import TableAbbreviationFormatter               from '../TableAbbreviationFormatter';
import TableBoldTextFormatter                   from '../TableBoldTextFormatter';
import TableDateFilter                          from '../TableDateFilter';
import TableDurationFormatter                   from '../TableDurationFormatter';
import TableLongTextFormatter                   from '../TableLongTextFormatter';
import TableLongTextWithCopyFormatter           from '../TableLongTextWithCopyFormatter';
import TableNumericFilter                       from '../TableNumericFilter';
import TableOverviewActionsFormatter            from '../TableOverviewActionsFormatter';
import TableProcessFormatter                    from '../TableProcessFormatter';
import TableProjectActionsFormatter             from '../TableProjectActionsFormatter';
import TableStatusFormatter                     from '../TableStatusFormatter';
import TableTextFilter                          from '../TableTextFilter';
import TableTextFormatter                       from '../TableTextFormatter';
import TableTimeTrackingActionsFormatter        from '../TableTimeTrackingActionsFormatter';
import TableWorkingPackageAbbreviationFormatter from '../TableWorkingPackageAbbreviationFormatter';

const getClientNameColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableTextFormatter,
        key:            'clientName',
        name:           I18n.t('clientName'),
        sortable:       true,
        width:          180,
    };
};

const getDateColumn = (withLongDate, withBoldDate) => {
    return {
        filterable:     true,
        filterRenderer: TableDateFilter,
        formatter:      TableTextFormatter,
        key:            'date',
        name:           I18n.t('date'),
        sortable:       true,
        width:          100 + (
            withLongDate ?
                32 :
                0
        ),
    };
};

const getDescriptionColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableLongTextFormatter,
        key:            'description',
        name:           I18n.t('description'),
        sortable:       true,
    };
};

const getDescriptionWithCopyColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableLongTextWithCopyFormatter,
        key:            'description',
        name:           I18n.t('description'),
        sortable:       true,
    };
};

const getDurationColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableDurationFormatter,
        key:            'duration',
        name:           I18n.t('duration'),
        sortable:       true,
        width:          100,
    };
};

const getEmployeeAbbreviationColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableAbbreviationFormatter,
        key:            'employeeAbbreviation',
        name:           I18n.t('employee'),
        sortable:       true,
        width:          100,
    };
};

const getProjectActionsColumn = () => {
    return {
        filterable: false,
        formatter:  TableProjectActionsFormatter,
        key:        'actions',
        name:       I18n.t('actions'),
        sortable:   false,
        width:      80,
    };
};

const getProjectNumberColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableBoldTextFormatter,
        key:            'projectNumber',
        name:           I18n.t('projectNumber'),
        sortable:       true,
        width:          100,
    };
};

const getProjectTitleColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableBoldTextFormatter,
        key:            'projectTitle',
        name:           I18n.t('projectTitle'),
        sortable:       true,
    };
};

const getStatusColumn = () => {
    return {
        filterable: false,
        formatter:  TableStatusFormatter,
        key:        'projectState',
        name:       I18n.t('status'),
        sortable:   true,
        width:      100,
    };
};

const getTimeOverviewColumn = () => {
    return {
        filterable: false,
        formatter:  TableProcessFormatter,
        key:        'timeOverview',
        name:       I18n.t('timeOverview'),
        sortable:   true,
        width:      180,
    };
};

const getTimeTrackingActionsColumn = () => {
    return {
        filterable: false,
        formatter:  TableTimeTrackingActionsFormatter,
        key:        'actions',
        name:       I18n.t('actions'),
        sortable:   false,
        width:      80,
    };
};

const getOverviewActionsColumn = () => {
    return {
        filterable: false,
        formatter:  TableOverviewActionsFormatter,
        key:        'actions',
        name:       I18n.t('actions'),
        sortable:   false,
        width:      80,
    };
};

const getWorkingPackageAbbreviationColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableWorkingPackageAbbreviationFormatter,
        key:            'workingPackageAbbreviation',
        name:           I18n.t('workingPackageAbbreviation'),
        sortable:       true,
        width:          115,
    };
};

const getWorkingPackageTitleColumn = () => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableBoldTextFormatter,
        key:            'workingPackageTitle',
        name:           I18n.t('workingPackage'),
        sortable:       true,
    };
};

export default {
    get: (settings) => {
        if (!settings) {
            settings = {};
        }

        return {
            clientName:                 getClientNameColumn(),
            date:                       getDateColumn(settings.withLongDate),
            dateBold:                   getDateColumn(),
            duration:                   getDurationColumn(),
            employeeAbbreviation:       getEmployeeAbbreviationColumn(),
            projectActions:             getProjectActionsColumn(),
            projectNumber:              getProjectNumberColumn(),
            projectTitle:               getProjectTitleColumn(),
            workingPackageAbbreviation: getWorkingPackageAbbreviationColumn(),
            status:                     getStatusColumn(),
            text:                       getDescriptionColumn(),
            textWithCopy:               getDescriptionWithCopyColumn(),
            timeOverview:               getTimeOverviewColumn(),
            timeTrackingActions:        getTimeTrackingActionsColumn(),
            overviewActions:            getOverviewActionsColumn(),
            workingPackageTitle:        getWorkingPackageTitleColumn(),
        };
    },
};
