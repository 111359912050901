//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes       from '../PropTypes';
import Link            from '../Link';
import React           from 'react';
import styles          from './styles.module.scss';
import I18n            from 'i18next';
import Routes          from '../../constants/Routes';
import { connect }     from 'react-redux';
import ComponentHelper from '../../helper/ComponentHelper';

class BreadCrumbBar extends React.Component {
    getBreadCrumbRouteStack = () => {
        const breadCrumbRouteStack = [];

        switch (this.props.pathname) {
            case Routes.timeTrackingCreate:
            case Routes.timeTrackingEdit:
                breadCrumbRouteStack.push(Routes.timeTracking);

                break;
        }

        breadCrumbRouteStack.push(this.props.pathname);

        return breadCrumbRouteStack;
    };

    getLabelForRoute = (route) => {
        switch (route) {
            // @formatter:off
            case Routes.dashboard:          return I18n.t('myOverview');
            case Routes.dataProtection:     return I18n.t('dataProtection');
            case Routes.imprint:            return I18n.t('imprint');
            case Routes.projects:           return I18n.t('projects');
            case Routes.timeTracking:       return I18n.t('timeTrackingBreadCrumb');
            case Routes.timeTrackingCreate: return I18n.t('timeTrackingCreateNewBreadCrumb');
            case Routes.timeTrackingEdit:   return I18n.t('timeTrackingCreateEditBreadCrumb');
            // @formatter:on
        }

        console.error('Missing label for route:', route);

        return null;
    };

    render () {
        const breadCrumbRouteStack = this.getBreadCrumbRouteStack();

        return (
            <div className={styles.breadCrumbBar}>
                <ul>
                    {breadCrumbRouteStack.map(this.renderBreadCrumbRoute)}
                </ul>
            </div>
        );
    }

    renderBreadCrumbRoute = (breadCrumbRoute) => {
        const label = this.getLabelForRoute(breadCrumbRoute);

        return (
            <li key={breadCrumbRoute}>
                <Link to={breadCrumbRoute}>
                    {label}
                </Link>
            </li>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreadCrumbBar;

Component.propTypes = {
    pathname: PropTypes.string,
};

Component.defaultProps = {
    pathname: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        pathname: state.router.location.pathname,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
