//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import alertBoxReducer           from './alertBox';
import holidayReducer            from './holidays';
import layoutReducer             from './layout';
import loadingReducer            from './loading';
import projectReducer            from './project';
import projectTimeBookingReducer from './projectTimeBooking';
import textTemplateReducer       from './textTemplate';
import userReducer               from './user';
import userTimeSumReducer        from './userTimeSum';
import { combineReducers }       from 'redux';
import { connectRouter }         from 'connected-react-router';

export default (history) => combineReducers({
    alertBox:           alertBoxReducer,
    layout:             layoutReducer,
    loading:            loadingReducer,
    holiday:            holidayReducer,
    project:            projectReducer,
    projectTimeBooking: projectTimeBookingReducer,
    textTemplate:       textTemplateReducer,
    user:               userReducer,
    userTimeSum:        userTimeSumReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router:             connectRouter(history),
})
