//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import styles          from './styles.module.scss';
import ComponentHelper from '../../helper/ComponentHelper';

class HolidayLabel extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <span className={styles.holidayLabel}>
                    {I18n.t('holiday')}
                </span>
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HolidayLabel;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
