//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Cast                    from '../../helper/Cast';
import classNames              from 'classnames';
import ComponentHelper         from '../../helper/ComponentHelper';
import DashboardNumberBoxTheme from './DashboardNumberBoxTheme';
import I18n                    from 'i18next';
import PropTypes               from '../PropTypes';
import React                   from 'react';
import SelectionHelper         from '../../helper/SelectionHelper';
import styles                  from './styles.module.scss';

class DashboardNumberBox extends React.Component {
    getNumber = () => {
        const value = this.props.number;

        if (value || value === 0) {
            const fixedNumber       = value.toFixed(2);
            const numberString      = Cast.string(fixedNumber);
            const numberStringComma = numberString.replace('.', ',');

            return numberStringComma;
        }

        return I18n.t('loading');
    };

    render () {
        return (
            <div
                className={classNames(
                    styles.dashboardNumberBox,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [DashboardNumberBoxTheme.lightBlue]: styles.dashboardNumberBoxLightBlue,
                            [DashboardNumberBoxTheme.pink]:      styles.dashboardNumberBoxPink,
                        },
                    ),
                    this.props.className,
                )}
            >
                <div className={styles.titleContainer}>
                    <span>
                        {this.props.title}
                    </span>
                    <hr />
                </div>
                <div className={styles.numberContainer}>
                    {this.getNumber()}
                </div>
                <div className={styles.unitContainer}>
                    <span>
                        {this.props.unit}
                    </span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DashboardNumberBox;

Component.propTypes = {
    className: PropTypes.string,
    theme:     PropTypes.oneOf(Object.values(DashboardNumberBoxTheme)),
    title:     PropTypes.string,
    number:    PropTypes.number,
    unit:      PropTypes.string,
};

Component.defaultProps = {
    className: null,
    theme:     DashboardNumberBoxTheme.lightBlue,
    title:     '',
    number:    '',
    unit:      '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
