//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const getRouteWithParameters = function (route, parameterValues, queryParameters) {
    for (const [parameterKey, parameterValue] of Object.entries(parameterValues)) {
        const routeParameterKey = ':' + parameterKey;

        if (route.endsWith(parameterKey) || route.endsWith(parameterKey + '?')) {
            route = route.replace(new RegExp(routeParameterKey + '(\\?)?$'), parameterValue);
        }

        route = route.replace(routeParameterKey + '?/', parameterValue + '/');
        route = route.replace(routeParameterKey + '/', parameterValue + '/');
    }

    if (queryParameters) {
        let queryParameterString;

        for (const [queryParameterKey, queryParameterValue] of Object.entries(queryParameters)) {
            if (!queryParameterString) {
                queryParameterString = '?';
            } else {
                queryParameterString += '&';
            }

            queryParameterString += queryParameterKey + '=' + queryParameterValue;
        }

        if (queryParameterString) {
            route = route + queryParameterString;
        }
    }

    return route;
};

export default {
    getRouteWithParameters,
    dashboard:          '/dashboard/:monthYear?',
    dataProtection:     '/datenschutz',
    imprint:            '/impressum',
    index:              '/',
    login:              '/login',
    projects:           '/projekte',
    timeTracking:       '/zeiterfassung/:monthYear?',
    timeTrackingCreate: '/zeiterfassung/neu',
    timeTrackingEdit:   '/zeiterfassung/bearbeiten',
};
