import React           from 'react';
import DurationLabel   from '../DurationLabel';
import PropTypes       from '../PropTypes';
import ComponentHelper from '../../helper/ComponentHelper';

class TableDurationFormatter extends React.Component {
    render () {
        return (
            <DurationLabel
                value={this.props.value}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableDurationFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;