//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const HolidayTypes = {
    FETCH_ALL_HOLIDAYS:           'Holiday/FETCH_ALL_HOLIDAYS',
    FETCH_ALL_HOLIDAYS_FAILED:    'Holiday/FETCH_ALL_HOLIDAYS_FAILED',
    FETCH_ALL_HOLIDAYS_SUCCEEDED: 'Holiday/FETCH_ALL_HOLIDAYS_SUCCEEDED',
};

const fetchAllHolidays = makeActionCreator(
    HolidayTypes.FETCH_ALL_HOLIDAYS,
);

const fetchAllHolidaysFailed = makeActionCreator(
    HolidayTypes.FETCH_ALL_HOLIDAYS_FAILED,
);

const fetchAllHolidaysSucceeded = makeActionCreator(
    HolidayTypes.FETCH_ALL_HOLIDAYS_SUCCEEDED, {
        holidays: null,
    },
);

export const HolidayActions = {
    fetchAllHolidays,
    fetchAllHolidaysFailed,
    fetchAllHolidaysSucceeded,
};
