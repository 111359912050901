import ComponentHelper from '../../helper/ComponentHelper';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import Link            from '../Link';

class FooterLink extends React.Component {
    render () {
        if (this.props.external) {
            return (
                <a
                    className={styles.footerLink}
                    href={this.props.to}
                    target={'_blank'}
                >
                    {this.props.text}
                </a>
            );
        }

        return (
            <Link
                className={styles.footerLink}
                to={this.props.to}
            >
                {this.props.text}
            </Link>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FooterLink;

Component.propTypes = {
    external: PropTypes.bool,
    text:     PropTypes.string,
    to:       PropTypes.string,
};

Component.defaultProps = {
    external: false,
    text:     null,
    to:       null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;