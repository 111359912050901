//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper   from '../../helper/ComponentHelper';
import I18n              from 'i18next';
import React             from 'react';
import PropTypes         from '../PropTypes';
import Cast              from '../../helper/Cast';
import styles            from './styles.module.scss';
import InputArrowControl from '../InputArrowControl';
import DateInput         from '../DateInput';
import _                 from 'lodash';
import Select            from '../Select';
import moment            from 'moment';

class DateTimeInput extends React.Component {
    getDate = () => {
        if (typeof this.props.date === 'string') {
            return new Date(this.props.date);
        }

        return this.props.date;
    };

    getMinuteSelection = () => {
        const minutes = [];

        for (let minute = 0; minute <= 45; minute += 15) {
            minutes.push({
                label: Cast.string(minute),
                value: minute,
            });
        }

        return minutes;
    };

    getSelectedValue = (options) => {
        const value         = this.props.minutes;
        const selectedValue = options.filter(option => option.value === value);

        return selectedValue;
    };

    handleInputFocusSelect = (event) => {
        event.target.select();
    };

    hoursChanged = (event) => {
        this.props.onDurationHoursChange(Cast.int(event.target.value));
    };

    minutesChanged = (option) => {
        this.props.onDurationMinutesChange(option.value);
    };

    render () {
        const date          = this.getDate();
        const minuteOptions = this.getMinuteSelection();

        return (
            <div className={styles.dateTimeInputWrapper}>
                <div className={styles.dateTimeInputWrapperLeft}>
                </div>
                <div className={styles.dateTimeInputWrapperCenter}>
                    <label htmlFor={'time-tracking-date'}>
                        {I18n.t('date')}
                    </label>
                    <InputArrowControl
                        extraMarginRight={10}
                        arrowDownClicked={this.props.onDateArrowUpButtonPressed}
                        arrowUpClicked={this.props.onDateArrowDownButtonPressed}
                    >
                        <DateInput
                            date={date}
                            id={'time-tracking-date'}
                            onChange={this.props.onDateChange}
                            filterDate={this.props.filterDate}
                        />
                    </InputArrowControl>
                </div>
                <div className={styles.dateTimeInputWrapperRight}>
                    <div>
                        <label>
                            {I18n.t('hours')}
                        </label>
                        <InputArrowControl
                            extraMarginRight={1}
                            arrowDownClicked={this.props.onDurationHoursArrowUpButtonPressed}
                            arrowUpClicked={this.props.onDurationHoursArrowDownButtonPressed}
                        >
                            <div className={styles.dateTimeInputWrapperRightInputWrapper}>
                                <input
                                    onChange={this.hoursChanged}
                                    onFocus={this.handleInputFocusSelect}
                                    placeholder={I18n.t('hoursPlaceholder')}
                                    type={'number'}
                                    value={this.props.hours}
                                    min={0}
                                    max={24}
                                />
                            </div>
                        </InputArrowControl>
                    </div>
                    <div>
                        <label>
                            {I18n.t('minutes')}
                        </label>
                        <InputArrowControl
                            extraMarginRight={0}
                            arrowDownClicked={this.props.onDurationMinutesArrowUpButtonPressed}
                            arrowUpClicked={this.props.onDurationMinutesArrowDownButtonPressed}
                        >
                            <div className={styles.dateTimeInputWrapperRightSelectWrapper}>
                                <Select
                                    hideArrow={true}
                                    options={minuteOptions}
                                    placeholder={I18n.t('minutesPlaceholder')}
                                    onChange={this.minutesChanged}
                                    value={this.getSelectedValue(minuteOptions)}
                                />
                            </div>
                        </InputArrowControl>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DateTimeInput;

Component.propTypes = {
    date:                                    PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    filterDate:                              PropTypes.func,
    hours:                                   PropTypes.number,
    minutes:                                 PropTypes.number,
    onDateArrowDownButtonPressed:            PropTypes.func,
    onDateArrowUpButtonPressed:              PropTypes.func,
    onDateChange:                            PropTypes.func,
    onDurationHoursChange:                   PropTypes.func,
    onDurationHoursArrowDownButtonPressed:   PropTypes.func,
    onDurationHoursArrowUpButtonPressed:     PropTypes.func,
    onDurationMinutesChange:                 PropTypes.func,
    onDurationMinutesArrowDownButtonPressed: PropTypes.func,
    onDurationMinutesArrowUpButtonPressed:   PropTypes.func,
};

Component.defaultProps = {
    date:                                    null,
    filterDate:                              _.noop,
    hours:                                   null,
    minutes:                                 null,
    onDateArrowDownButtonPressed:            _.noop,
    onDateArrowUpButtonPressed:              _.noop,
    onDateChange:                            _.noop,
    onDurationHoursArrowDownButtonPressed:   _.noop,
    onDurationHoursArrowUpButtonPressed:     _.noop,
    onDurationHoursChange:                   _.noop,
    onDurationMinutesArrowDownButtonPressed: _.noop,
    onDurationMinutesArrowUpButtonPressed:   _.noop,
    onDurationMinutesChange:                 _.noop,
};

Component.renderAffectingProps = [
    'date',
    'hours',
    'minutes',
];

Component.renderAffectingStates = [];

export default DateTimeInput;
