//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { UserActions }         from '../actions/user';
import { put }                 from 'redux-saga/effects';
import * as Api                from '../../api';
import { select }              from 'redux-saga/effects';
import { call }                from 'redux-saga/effects';
import { push }                from 'connected-react-router';
import Routes                  from '../../constants/Routes';
import { ProjectActions }      from '../actions/project';
import { TextTemplateActions } from '../actions/textTemplate';
import { AlertBoxActions }     from '../actions/alertBox';
import I18n                    from 'i18next';
import { HolidayActions }      from '../actions/holidays';
import { UserTimeSumActions }  from '../actions/userTimeSum';
import { LoadingActions }      from '../actions/loading';

const routesWithoutSession = [
    Routes.dataProtection,
    Routes.imprint,
    Routes.login,
];

const login = function* () {
    yield put(AlertBoxActions.clearAlerts());

    const user     = yield select(state => state.user);
    const response = yield call(
        Api.login,
        user.username,
        user.password,
    );

    if (response.ok) {
        const lastLogin        = response.data.lastLogin;
        const roles            = response.data.roles;
        const token            = response.data.token;
        const username         = response.data.username;
        const id               = response.data.userId;
        const isProjectManager = roles.indexOf('ROLE_PROJECT_MANAGER') > -1;

        Api.setToken(token);

        yield put(UserActions.loginSucceeded({
            isProjectManager,
            lastLogin,
            token,
            username,
            id,
        }));
    } else {
        yield put(UserActions.loginFailed());
    }
};

const loginFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('loginError'),
    }));
};

const loginSucceeded = function* () {
    yield put(ProjectActions.fetchAllProjects());
    yield put(TextTemplateActions.fetchAllTextTemplates());
    yield put(HolidayActions.fetchAllHolidays());

    yield put(push(Routes.getRouteWithParameters(
        Routes.timeTracking,
        {
            monthYear: '',
        },
    )));
};

const logout = function* () {
    Api.setToken(null);

    yield put(push(Routes.login));
    yield put(LoadingActions.resetOverlay());
};

const restoreToken = function* () {
    const pathname        = yield select(state => state.router.location.pathname);
    const browserPathname = window.location.pathname;
    const user            = yield select(state => state.user);

    yield put(LoadingActions.resetOverlay());

    if (user.token) {
        Api.setToken(user.token);

        if (pathname === Routes.login || browserPathname === Routes.login) {
            yield put(push(Routes.getRouteWithParameters(
                Routes.timeTracking,
                {
                    monthYear: '',
                },
            )));
        }

        yield put(ProjectActions.fetchAllProjects());
        yield put(TextTemplateActions.fetchAllTextTemplates());
        yield put(HolidayActions.fetchAllHolidays());
        yield put(UserTimeSumActions.fetchAllUserTimeDaySums());
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 ||
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        yield put(push(Routes.login));
    }
};

export default {
    login,
    loginFailed,
    loginSucceeded,
    logout,
    restoreToken,
};
