//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const UserTimeSumTypes = {
    FETCH_ALL_USER_TIME_DAY_SUMS:               'UserTimeSum/FETCH_ALL_USER_TIME_DAY_SUMS',
    FETCH_ALL_USER_TIME_DAY_SUMS_FAILED:        'UserTimeSum/FETCH_ALL_USER_TIME_DAY_SUMS_FAILED',
    FETCH_ALL_USER_TIME_DAY_SUMS_SUCCEEDED:     'UserTimeSum/FETCH_ALL_USER_TIME_DAY_SUMS_SUCCEEDED',
    FETCH_ALL_USER_TIME_MONTH_SUMS:             'UserTimeSum/FETCH_ALL_USER_TIME_MONTH_SUMS',
    FETCH_ALL_USER_TIME_MONTH_SUMS_FAILED:      'UserTimeSum/FETCH_ALL_USER_TIME_MONTH_SUMS_FAILED',
    FETCH_ALL_USER_TIME_MONTH_SUMS_SUCCEEDED:   'UserTimeSum/FETCH_ALL_USER_TIME_MONTH_SUMS_SUCCEEDED',
    FETCH_ALL_USER_TIME_PROJECT_SUMS:           'UserTimeSum/FETCH_ALL_USER_TIME_PROJECT_SUMS',
    FETCH_ALL_USER_TIME_PROJECT_SUMS_FAILED:    'UserTimeSum/FETCH_ALL_USER_TIME_PROJECT_SUMS_FAILED',
    FETCH_ALL_USER_TIME_PROJECT_SUMS_SUCCEEDED: 'UserTimeSum/FETCH_ALL_USER_TIME_PROJECT_SUMS_SUCCEEDED',
    FETCH_ALL_USER_TIME_YEAR_SUMS:              'UserTimeSum/FETCH_ALL_USER_TIME_YEAR_SUMS',
    FETCH_ALL_USER_TIME_YEAR_SUMS_FAILED:       'UserTimeSum/FETCH_ALL_USER_TIME_YEAR_SUMS_FAILED',
    FETCH_ALL_USER_TIME_YEAR_SUMS_SUCCEEDED:    'UserTimeSum/FETCH_ALL_USER_TIME_YEAR_SUMS_SUCCEEDED',
    OPEN_DASHBOARD:                             'UserTimeSum/OPEN_DASHBOARD',
};

const fetchAllUserTimeDaySums = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS,
);

const fetchAllUserTimeDaySumsFailed = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS_FAILED,
);

const fetchAllUserTimeDaySumsSucceeded = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_DAY_SUMS_SUCCEEDED, {
        userTimeDaySums: null,
    },
);

const fetchAllUserTimeMonthSums = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS,
);

const fetchAllUserTimeMonthSumsFailed = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS_FAILED,
);

const fetchAllUserTimeMonthSumsSucceeded = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_MONTH_SUMS_SUCCEEDED, {
        userTimeMonthSums: null,
    },
);

const fetchAllUserTimeYearSums = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS,
);

const fetchAllUserTimeYearSumsFailed = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS_FAILED,
);

const fetchAllUserTimeYearSumsSucceeded = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_YEAR_SUMS_SUCCEEDED, {
        userTimeYearSums: null,
    },
);

const fetchAllUserTimeProjectSums = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS,
);

const fetchAllUserTimeProjectSumsFailed = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS_FAILED,
);

const fetchAllUserTimeProjectSumsSucceeded = makeActionCreator(
    UserTimeSumTypes.FETCH_ALL_USER_TIME_PROJECT_SUMS_SUCCEEDED, {
        userTimeProjectSums: null,
    },
);

const openDashboard = makeActionCreator(
    UserTimeSumTypes.OPEN_DASHBOARD, {
        month: null,
        year:  null,
    },
);

export const UserTimeSumActions = {
    fetchAllUserTimeDaySums,
    fetchAllUserTimeDaySumsFailed,
    fetchAllUserTimeDaySumsSucceeded,
    fetchAllUserTimeMonthSums,
    fetchAllUserTimeMonthSumsFailed,
    fetchAllUserTimeMonthSumsSucceeded,
    fetchAllUserTimeProjectSums,
    fetchAllUserTimeProjectSumsFailed,
    fetchAllUserTimeProjectSumsSucceeded,
    fetchAllUserTimeYearSums,
    fetchAllUserTimeYearSumsFailed,
    fetchAllUserTimeYearSumsSucceeded,
    openDashboard,
};
