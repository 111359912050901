import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import BreadCrumbBar   from '../components/BreadCrumbBar';
import Paragraph       from '../components/Paragraph';
import Headline        from '../components/Headline';
import HeadlineTheme   from '../components/Headline/HeadlineTheme';

//
// This is basically a copy of
// @see https://www.vogtundfeist.de/impressum.html
//
class Screen extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('imprint'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    text={'Kontakt'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Paragraph>
                    Planungsbüro
                    <br />
                    vogt und feist
                    <br />
                    Zwergerstraße 15
                    <br />
                    88214 Ravensburg
                </Paragraph>
                <Paragraph>
                    Telefon:
                    {' '}
                    <a
                        href={'call:00497513666030'}
                        target={'_blank'}
                    >
                        0751-36660-30
                    </a>
                    <br />
                    Telefax: 0751-36660-59
                    <br />
                    <a
                        href={'mailto:mail@vogtundfeist.de'}
                        target={'_blank'}
                    >
                        mail@vogtundfeist.de
                    </a>
                    <br />
                    <a
                        href={'https://www.vogtundfeist.de'}
                        target={'_blank'}
                    >
                        www.vogtundfeist.de
                    </a>
                </Paragraph>
                <Headline
                    text={'Impressum'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Paragraph>
                    Angaben gemäß § 5 TMG:
                </Paragraph>
                <Paragraph>
                    Planungsbüro vogt und feist
                    <br />
                    Zwergerstraße 15
                    <br />
                    88214 Ravensburg
                </Paragraph>
                <Headline
                    text={'Vertreten durch:'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Detlef Feist und Achim Vogt
                </Paragraph>
                <Headline
                    text={'Kontakt:'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Telefon:
                    {' '}
                    <a
                        href={'call:00497513666030'}
                        target={'_blank'}
                    >
                        0751-36660-30
                    </a>
                    <br />
                    Telefax: 0751-36660 59
                    <br />
                    E-Mail:
                    {' '}
                    <a
                        href={'mailto:mail@vogtundfeist.de'}
                        target={'_blank'}
                    >
                        mail@vogtundfeist.de
                    </a>
                </Paragraph>
                <Headline
                    text={'Umsatzsteuer-ID:'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a
                    Umsatzsteuergesetz: DE146391354
                </Paragraph>
                <Headline
                    text={'Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Detlef Feist und Achim Vogt
                </Paragraph>
            </div>
        );
    }
}

export default Screen;
