//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import _               from 'lodash';
import PropTypes       from '../PropTypes';
import classNames      from 'classnames';
import styles          from './styles.module.scss';
import ComponentHelper from '../../helper/ComponentHelper';

class ValueLabel extends React.Component {
    getValue = () => {
        const value        = this.props.value;
        const valueIsArray = _.isArray(value);

        if (
            value &&
            (
                !valueIsArray ||
                value.length > 0
            )
        ) {
            return (
                valueIsArray ?
                    value.join(', ') :
                    value
            );
        }

        return I18n.t('emptyPlaceholderShort');
    };

    render () {
        const value       = this.getValue();
        const customTitle = this.props.customTitle;
        const title       = customTitle ? customTitle : value;

        return (
            <span
                className={classNames(
                    styles.valueLabel,
                    this.props.customClassNames,
                    {
                        [styles.valueLabelBold]: this.props.showBold,
                    },
                )}
                title={title}
            >
                {value}
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ValueLabel;

Component.propTypes = {
    customClassNames: PropTypes.array,
    customTitle:      PropTypes.string,
    showBold:         PropTypes.bool,
    value:            PropTypes.any,
};

Component.defaultProps = {
    customClassNames: [],
    customTitle:      null,
    showBold:         false,
    value:            [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
