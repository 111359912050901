//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper      from '../../helper/ComponentHelper';
import I18n                 from 'i18next';
import React                from 'react';
import PropTypes            from '../PropTypes';
import styles               from './styles.module.scss';
import _                    from 'lodash';
import DatePicker           from 'react-datepicker';
import { registerLocale }   from 'react-datepicker';
import { setDefaultLocale } from 'react-datepicker';
import de                   from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

registerLocale('de', de);
setDefaultLocale('de');

class DateInput extends React.Component {
    onChangeRaw = (event) => {
        // This will prevent typing in the date input
        event.preventDefault();
    };

    render () {
        return (
            <div className={styles.dateInputWrapper}>
                <DatePicker
                    dateFormat={I18n.t('dateInputDateFormat')}
                    id={this.props.id}
                    onChange={this.props.onChange}
                    onChangeRaw={this.onChangeRaw}
                    selected={this.props.date}
                    showPopperArrow={false}
                    showWeekNumbers={true}
                    filterDate={this.props.filterDate}
                />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DateInput;

Component.propTypes = {
    date:       PropTypes.object,
    id:         PropTypes.string,
    onChange:   PropTypes.func,
    filterDate: PropTypes.func,
};

Component.defaultProps = {
    date:       null,
    id:         null,
    onChange:   _.noop,
    filterDate: _.noop,
};

Component.renderAffectingProps = [
    'date',
];

Component.renderAffectingStates = [];

export default DateInput;
