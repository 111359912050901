//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                             from 'react';
import _                                 from 'lodash';
import AlertBoxManager                   from '../components/AlertBoxManager';
import BreadCrumbBar                     from '../components/BreadCrumbBar';
import Cast                              from '../helper/Cast';
import CenterContainer                   from '../components/CenterContainer';
import CheckBox                          from '../components/CheckBox';
import ColorButton                       from '../components/ColorButton';
import ColorButtonTheme                  from '../components/ColorButton/ColorButtonTheme';
import DateHelper                        from '../helper/Date';
import DateTimeInput                     from '../components/DateTimeInput';
import Headline                          from '../components/Headline';
import HeadlineOverlay                   from '../components/HeadlineOverlay';
import HeadlineTheme                     from '../components/Headline/HeadlineTheme';
import I18n                              from 'i18next';
import IconType                          from '../components/Icon/IconType';
import LeftRightSplitter                 from '../components/LeftRightSplitter';
import LeftRightSplitterContentAlignment from '../components/LeftRightSplitter/LeftRightSplitterContentAlignment';
import OverlayMenu                       from '../components/OverlayMenu';
import OverlayMenuButton                 from '../components/OverlayMenuButton';
import OverlayMenuButtonTheme            from '../components/OverlayMenuButton/OverlayMenuButtonTheme';
import OverlayMenuText                   from '../components/OverlayMenuText';
import PageTitleHelper                   from '../helper/PageTitle';
import ProjectOverviewTable              from '../components/ProjectOverviewTable';
import Routes                            from '../constants/Routes';
import SelectInput                       from '../components/SelectInput';
import styles                            from '../styles.module.scss';
import TableFilterButton                 from '../components/TableFilterButton';
import TextButton                        from '../components/TextButton';
import TextButtonTheme                   from '../components/TextButton/TextButtonTheme';
import TextInput                         from '../components/TextInput';
import TextInputType                     from '../components/TextInput/TextInputType';
import { AlertBoxActions }               from '../store/actions/alertBox';
import { bindActionCreators }            from 'redux';
import { Component }                     from 'react';
import { connect }                       from 'react-redux';
import { Helmet }                        from 'react-helmet';
import { ProjectTimeBookingActions }     from '../store/actions/projectTimeBooking';
import { UserActions }                   from '../store/actions/user';

class Screen extends Component {
    addAnotherCheckBoxChanged = (data) => {
        this.props.toggleProjectTimeBookingAddAnotherTimeBookingAfterSave();
    };

    closeAreYouSureMenu = () => {
        this.setAreYouSureOverlayVisibility(false);
    };

    constructor (props) {
        super(props);

        this.state = {
            deleteOverlayVisible: false,
        };
    }

    filterDate = date => {
        return DateHelper.isWorkingDay(date, this.props.holidays);
    };

    dateChanged = (dateString) => {
        const date = new Date(dateString);

        this.props.setProjectTimeBookingDate({
            date,
        });
    };

    deleteButtonPressed = () => {
        this.openAreYouSureMenu();
    };

    deleteCancelButtonPressed = () => {
        this.closeAreYouSureMenu();
    };

    deleteSureButtonPressed = () => {
        this.closeAreYouSureMenu();

        this.props.deleteProjectTimeBooking({
            id: this.props.projectTimeBookingCreateEditContext.id,
        });
    };

    durationHoursChanged = (hours) => {
        this.props.setProjectTimeBookingDurationHours({
            hours,
        });
    };

    durationMinutesChanged = (minutes) => {
        this.props.setProjectTimeBookingDurationMinutes({
            minutes,
        });
    };

    getPageTitleKey = () => {
        if (this.isEditContext()) {
            return 'timeTrackingEdit';
        }

        return 'timeTrackingCreateNew';
    };

    getProjects = () => {
        const projects = [];

        if (this.props.projects) {
            for (const project of this.props.projects) {
                projects.push({
                    label: (
                               project.number ?
                                   project.number + ' | ' :
                                   ''
                           ) + project.title,
                    value: project.id,
                    sort:  Cast.float(project.numberSortable),
                });
            }
        }

        const sortedProjectsByNumberDescending = _.sortBy(projects, ['sort']).reverse();

        return sortedProjectsByNumberDescending;
    };

    getWorkingPackages = () => {
        const workingPackages = [];
        const projectId       = _.get(this.props, 'projectTimeBookingCreateEditContext.project');

        if (this.props.projects && projectId) {
            const project = _.find(this.props.projects, { id: projectId });

            if (project && project.enabledWorkingPackages) {
                for (const workingPackage of project.enabledWorkingPackages) {
                    const titlePrefix = (
                        workingPackage.sortOrder ?
                            workingPackage.sortOrder + ' ' :
                            ''
                    );

                    workingPackages.push({
                        label: titlePrefix + workingPackage.title,
                        value: workingPackage.id,
                    });
                }
            }
        }

        return workingPackages;
    };

    getSaveButtonText = () => {
        if (this.isEditContext()) {
            return I18n.t('saveChanges');
        }

        return I18n.t('save');
    };

    getTextTemplates = () => {
        const textTemplates = [];

        if (this.props.textTemplates) {
            for (const textTemplate of this.props.textTemplates) {
                textTemplates.push({
                    label: textTemplate.title,
                    value: textTemplate.id,
                });
            }
        }

        return textTemplates;
    };

    isEditContext = () => {
        const isEditContext = this.props.pathname === Routes.timeTrackingEdit;

        return isEditContext;
    };

    onDateArrowDownButtonPressed = () => {
        const holidays = this.props.holidays;

        this.props.increaseProjectTimeBookingDate({ holidays });
    };

    onDateArrowUpButtonPressed = () => {
        const holidays = this.props.holidays;

        this.props.decreaseProjectTimeBookingDate({ holidays });
    };

    onFormSubmit = (event) => {
        event.preventDefault();

        this.saveButtonPressed();
    };

    onDurationHoursArrowDownButtonPressed = () => {
        this.props.increaseProjectTimeBookingDurationHours();
    };

    onDurationHoursArrowUpButtonPressed = () => {
        this.props.decreaseProjectTimeBookingDurationHours();
    };

    onDurationMinutesArrowDownButtonPressed = () => {
        this.props.increaseProjectTimeBookingDurationMinutes();
    };

    onDurationMinutesArrowUpButtonPressed = () => {
        this.props.decreaseProjectTimeBookingDurationMinutes();
    };

    openAreYouSureMenu = () => {
        this.setAreYouSureOverlayVisibility(true);
    };

    projectChanged = (option) => {
        this.props.setProjectTimeBookingProject({
            project: option.value,
        });
    };

    render () {
        const pageTitleKey = this.getPageTitleKey();
        const pageTitle    = I18n.t(pageTitleKey);

        return (
            <div className={styles.contentView}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(pageTitle)}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <LeftRightSplitter
                    flexLeft={null}
                    renderLeft={() => {
                        return this.renderForm(pageTitle);
                    }}
                    renderRight={this.renderTimeBookingHistory}
                />
            </div>
        );
    }

    renderAddAnotherCheckBox = () => {
        if (!this.isEditContext()) {
            return (
                <CheckBox
                    checked={this.props.projectTimeBookingAddAnotherTimeBookingAfterSave}
                    text={I18n.t('timeTrackingCreateNewAddAnother')}
                    valueChanged={this.addAnotherCheckBoxChanged}
                    noMarginBottom={true}
                />
            );
        }

        return null;
    };

    renderHeadlineOverlay = () => {
        if (this.isEditContext()) {
            return (
                <HeadlineOverlay
                    key={'headlineOverlay' + (
                        this.state.deleteOverlayVisible ? 'Open' : 'Close'
                    )}
                >
                    <TextButton
                        iconType={IconType.cross}
                        onClick={this.deleteButtonPressed}
                        text={I18n.t('timeTrackingDelete')}
                        theme={TextButtonTheme.red}
                    />
                    <OverlayMenu visible={this.state.deleteOverlayVisible}>
                        <OverlayMenuText
                            text={I18n.t('timeTrackingDeleteConfirmText')}
                        />
                        <OverlayMenuButton
                            onClick={this.deleteSureButtonPressed}
                            text={I18n.t('timeTrackingDeleteConfirmButton')}
                            theme={OverlayMenuButtonTheme.red}
                        />
                        <OverlayMenuButton
                            onClick={this.deleteCancelButtonPressed}
                            text={I18n.t('timeTrackingDeleteCancelButton')}
                            theme={OverlayMenuButtonTheme.default}
                        />
                    </OverlayMenu>
                </HeadlineOverlay>
            );
        }

        return null;
    };

    renderForm = (pageTitle) => {
        const projects        = this.getProjects();
        const workingPackages = _.sortBy(this.getWorkingPackages(), 'label');
        const textTemplates   = this.getTextTemplates();

        return (
            <div>
                <Headline
                    key={'headline' + (
                        this.state.deleteOverlayVisible ? 'Open' : 'Close'
                    )}
                    overlay={this.renderHeadlineOverlay()}
                    text={pageTitle}
                    theme={HeadlineTheme.standard}
                />
                <CenterContainer>
                    <form onSubmit={this.onFormSubmit}>
                        <input
                            type={'submit'}
                            name={'submit'}
                        />
                        <AlertBoxManager />
                        <SelectInput
                            iconType={IconType.folder}
                            onChange={this.projectChanged}
                            options={projects}
                            placeholder={I18n.t('selectProject')}
                            value={_.get(this.props, 'projectTimeBookingCreateEditContext.project')}
                        />
                        <SelectInput
                            iconType={IconType.package}
                            onChange={this.workingPackageChanged}
                            options={workingPackages}
                            placeholder={I18n.t('selectWorkingPackage')}
                            value={_.get(this.props, 'projectTimeBookingCreateEditContext.workingPackage')}
                        />
                        <SelectInput
                            iconType={IconType.listPlus}
                            onChange={this.textTemplateChanged}
                            options={textTemplates}
                            placeholder={I18n.t('selectTextTemplate')}
                            value={_.get(this.props, 'projectTimeBookingCreateEditContext.textTemplate')}
                        />
                        <TextInput
                            iconType={IconType.pencil}
                            onChange={this.textChanged}
                            placeholder={I18n.t('descriptionPlaceholder')}
                            type={TextInputType.textarea}
                            value={_.get(this.props, 'projectTimeBookingCreateEditContext.text', '')}
                        />
                        <DateTimeInput
                            date={_.get(this.props, 'projectTimeBookingCreateEditContext.date')}
                            hours={_.get(this.props, 'projectTimeBookingCreateEditContext.duration.hours')}
                            minutes={_.get(this.props, 'projectTimeBookingCreateEditContext.duration.minutes')}
                            filterDate={this.filterDate}
                            onDateChange={this.dateChanged}
                            onDateArrowDownButtonPressed={this.onDateArrowDownButtonPressed}
                            onDateArrowUpButtonPressed={this.onDateArrowUpButtonPressed}
                            onDurationHoursChange={this.durationHoursChanged}
                            onDurationHoursArrowDownButtonPressed={this.onDurationHoursArrowDownButtonPressed}
                            onDurationHoursArrowUpButtonPressed={this.onDurationHoursArrowUpButtonPressed}
                            onDurationMinutesChange={this.durationMinutesChanged}
                            onDurationMinutesArrowDownButtonPressed={this.onDurationMinutesArrowDownButtonPressed}
                            onDurationMinutesArrowUpButtonPressed={this.onDurationMinutesArrowUpButtonPressed}
                        />
                        <LeftRightSplitter
                            contentAlignment={LeftRightSplitterContentAlignment.center}
                            flexLeft={null}
                            renderLeft={this.renderAddAnotherCheckBox}
                            renderRight={this.renderSaveButton}
                        />
                    </form>
                </CenterContainer>
            </div>
        );
    };

    renderSaveButton = () => {
        return (
            <ColorButton
                onClick={this.saveButtonPressed}
                text={this.getSaveButtonText()}
                theme={ColorButtonTheme.redSmall}
            />
        );
    };

    renderTimeBookingHistory = () => {
        const projectId = _.get(this.props, 'projectTimeBookingCreateEditContext.project');

        if (this.props.projects && projectId) {
            const project = _.find(this.props.projects, { id: projectId });

            if (project) {
                return (
                    <div className={styles.flexView}>
                        <Headline
                            overlay={this.renderTimeBookingHistoryHeadlineOverlay()}
                            text={I18n.t('projectOverview')}
                            theme={HeadlineTheme.bold}
                        />
                        <ProjectOverviewTable
                            key={'projectOverviewTable' + projectId}
                            projectTimeBookings={project.projectTimeBookings}
                            project={project}
                        />
                    </div>
                );
            }
        }

        return null;
    };

    renderTimeBookingHistoryHeadlineOverlay = () => {
        return (
            <HeadlineOverlay>
                <TableFilterButton />
            </HeadlineOverlay>
        );
    };

    saveButtonPressed = () => {
        console.log('TimeTrackingScreen: saveButtonPressed');

        if (this.validateForm()) {
            // We need to do this as the date is parsed wrong in the backend probably caused by timezone differences
            // https://lulububu.atlassian.net/browse/VOFEITITRA-292
            const fixedDate = new Date(this.props.projectTimeBookingCreateEditContext.date);

            fixedDate.setHours(8);

            const projectTimeBookingPayload = {
                date:           fixedDate,
                project:        [
                                    // https://lulububu.atlassian.net/browse/VOFEITITRA-183
                                    '/api/projects/',
                                    this.props.projectTimeBookingCreateEditContext.project,
                                ].join(''),
                text:           this.props.projectTimeBookingCreateEditContext.text,
                time:           (
                                    this.props.projectTimeBookingCreateEditContext.duration.hours +
                                    (
                                        this.props.projectTimeBookingCreateEditContext.duration.minutes / 60
                                    )
                                ),
                workingPackage: [
                                    // https://lulububu.atlassian.net/browse/VOFEITITRA-183
                                    '/api/working-packages/',
                                    this.props.projectTimeBookingCreateEditContext.workingPackage,
                                ].join(''),
            };

            if (this.isEditContext()) {
                projectTimeBookingPayload.id = this.props.projectTimeBookingCreateEditContext.id;

                this.props.updateProjectTimeBooking(projectTimeBookingPayload);
            } else {
                this.props.submitProjectTimeBooking(projectTimeBookingPayload);
            }
        }
    };

    setAreYouSureOverlayVisibility = (visibility) => {
        this.setState({
            deleteOverlayVisible: visibility,
        });
    };

    textChanged = (event) => {
        this.props.setProjectTimeBookingText({
            text: event.target.value,
        });
    };

    textTemplateChanged = (option) => {
        this.props.setProjectTimeBookingTextTemplate({
            textTemplate: option.value,
        });
    };

    validateForm = () => {
        let errorText = null;

        this.props.clearAlerts();

        if (!this.props.projectTimeBookingCreateEditContext.project) {
            errorText = I18n.t('projectError1');
        } else if (!this.props.projectTimeBookingCreateEditContext.workingPackage) {
            errorText = I18n.t('workingPackageError1');
        } else if (!this.props.projectTimeBookingCreateEditContext.text) {
            errorText = I18n.t('descriptionError1');
        } else if (
            !this.props.projectTimeBookingCreateEditContext.duration.hours &&
            !this.props.projectTimeBookingCreateEditContext.duration.minutes
        ) {
            errorText = I18n.t('durationError1');
        }

        if (errorText) {
            this.props.showErrorAlert({
                text: errorText,
            });

            return false;
        }

        return true;
    };

    workingPackageChanged = (option) => {
        this.props.setProjectTimeBookingWorkingPackage({
            workingPackage: option.value,
        });
    };
}

const mapStateToProps = state => (
    {
        holidays:                                         state.holiday.holidays,
        pathname:                                         state.router.location.pathname,
        projects:                                         state.project.projects,
        projectTimeBookingAddAnotherTimeBookingAfterSave: state.projectTimeBooking.addAnotherTimeBookingAfterSave,
        projectTimeBookingCreateEditContext:              state.projectTimeBooking.projectTimeBookingCreateEditContext,
        textTemplates:                                    state.textTemplate.textTemplates,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    ProjectTimeBookingActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
