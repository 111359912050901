//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = {
    actions:                          'Aktionen',
    admin:                            'Admin',
    and:                              'und',
    applicationCopyright:             '© 2020 · Don Ailinger Creative Services',
    applicationName:                  'Zeiterfassung',
    april:                            'April',
    august:                           'August',
    calendarWeekShortened:            'KW{{calendarWeek}}',
    clientName:                       'Bauherr',
    copyText:                         'Text kopieren',
    customerName:                     'vogt und feist',
    dataProtection:                   'Datenschutz',
    date:                             'Datum',
    dateFilter:                       'Datumsfilter ({{dateFrom}} - {{dateTo}})',
    dateFilterPlaceholder:            'z.B. 01.02.',
    dateFormatDayName:                'ddd',
    dateInputDateFormat:              'EEE dd.MM.YYY',
    december:                         'Dezember',
    description:                      'Beschreibung',
    descriptionError1:                'Bitte geben Sie eine Beschreibung an. Sie könne dazu auch eine Textvorlage wählen.',
    descriptionPlaceholder:           'Beschreibung',
    descriptionShow:                  'Anzeigen',
    duration:                         'Dauer',
    durationError1:                   'Eine Zeiterfassung ohne Angabe der Dauer ist nicht möglich.',
    employee:                         'Mitarbeiter',
    emptyPlaceholderShort:            '',
    enterTextPlaceholder:             'Text eingeben...',
    enterTextPlaceholderShort:        'Text...',
    february:                         'Februar',
    groupByDate:                      'Nach Datum gruppieren',
    headerStagingFlag:                'Staging-Version',
    holiday:                          'Feiertag',
    hour:                             'Stunde',
    hours:                            'Stunden',
    hourShort:                        'h',
    hoursPlaceholder:                 '0',
    imprint:                          'Impressum',
    january:                          'Januar',
    july:                             'Juli',
    june:                             'Juni',
    lastLogin:                        'Letzter Login',
    lastLoginDateFormat:              'DD.MM.YYYY',
    latestTrackedTime:                'Zuletzt erfasste Zeiten',
    listDateFormat:                   'DD.MM.YYYY',
    listDateFormatWithShortDay:       'ddd DD.MM.YYYY',
    loading:                          'Lade...',
    login:                            'Login',
    loginAction:                      'Einloggen',
    loginError:                       'Der Login ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten und versuchen es erneut.',
    loginPasswordPlaceholder:         '**********',
    loginUsernamePlaceholder:         '@vogtundfeist.de',
    logout:                           'Abmelden',
    lostPasswordAction:               'Passwort anfordern',
    lostPasswordEmailAddress:         'office@vogtundfeist.de',
    march:                            'März',
    may:                              'Mai',
    minutes:                          'Minuten',
    minutesPlaceholder:               '15',
    more:                             'Mehr',
    myMonth:                          'Mein Monat',
    myOverview:                       'Meine Übersicht',
    myProjects:                       'Meine Projekte',
    myTopProjects:                    'Meine Top Projekte',
    myWeek:                           'Meine Woche',
    nextMonth:                        'Folgemonat',
    notCurrentMonthHint:              'Vor- und Folgemonate werden nicht in die Monatsberechnung einbezogen',
    november:                         'November',
    numberFilterPlaceholder:          'z.B.: 3, 10-12, >20',
    oclock:                           'Uhr',
    october:                          'Oktober',
    or:                               'oder',
    pageNotFound:                     'Seite nicht gefunden',
    pageNotFoundText:                 'Die angeforderte Seite wurde nicht gefunden. Bitte klicken Sie auf das Logo im Kopfbereich der Seite um zum Login oder der Übersicht Ihrer Zeitbuchungen zu gelangen.',
    pageNotFoundTitle:                'Huch!',
    pageTitle:                        'vogt und feist Zeiterfassung',
    previousMonth:                    'Vormonat',
    project:                          'Projekt',
    projectError1:                    'Bitte wählen Sie ein Projekt aus.',
    projectLeader:                    'Projektleiter',
    projectNumber:                    'Projekt #',
    projectOverview:                  'Projektübersicht',
    projects:                         'Projekte',
    projectStatusActive:              'Aktiv',
    projectStatusFinished:            'Fertig',
    projectStatusSketch:              'Entwurf',
    projectTitle:                     'Titel',
    save:                             'Speichern',
    saveChanges:                      'Änderungen Speichern',
    selectNoSearchResults:            'Keine Auswahl mehr möglich.',
    selectProject:                    'Projekt wählen...',
    selectTextTemplate:               'Textvorlage wählen...',
    selectWorkingPackage:             'Arbeitspaket wählen...',
    september:                        'September',
    status:                           'Status',
    tableFilterHide:                  'Suchfilter ausblenden',
    tableFilterShow:                  'Suchfilter einblenden',
    timeOverview:                     'Zeit-Übersicht',
    timeTracking:                     'Zeiterfassung',
    timeTrackingBreadCrumb:           'Zeiterfassung',
    timeTrackingCalendarWeekDetail:   'Zeitbuchungen aus dieser Woche anzeigen',
    timeTrackingCopy:                 'Zeiterfassung kopieren',
    timeTrackingCreateEditBreadCrumb: 'Zeit bearbeiten',
    timeTrackingCreateNew:            'Neue Zeit erfassen',
    timeTrackingCreateNewAddAnother:  'Im Anschluss weitere Zeit hinzufügen',
    timeTrackingCreateNewBreadCrumb:  'Neue Zeit erfassen',
    timeTrackingCreateNewButton:      'Zeit erfassen',
    timeTrackingDelete:               'Eintrag löschen',
    timeTrackingDeleteCancelButton:   'Abbrechen',
    timeTrackingDeleteConfirmButton:  'Ja, Eintrag löschen',
    timeTrackingDeleteConfirmText:    'Eintrag wirklich unwiderruflich löschen?',
    timeTrackingDeleteError1:         'Beim Löschen der Zeitbuchung ist ein Fehler aufgetreten.',
    timeTrackingDeleteSuccess1:       'Die Zeitbuchung wurde erfolgreich gelöscht.',
    timeTrackingEdit:                 'Zeiterfassung bearbeiten',
    timeTrackingSuccess1:             'Ihre Zeiterfassung wurde erfolgreich gespeichert. Vielen Dank!',
    trackedHours:                     'erfasste Stunden',
    version:                          'Version',
    versionNumberDateFormat:          'DD.MM.YYYY HH:mm:ss',
    workingPackage:                   'Arbeitspaket',
    workingPackageAbbreviation:       'Arbeitspaket',
    workingPackageError1:             'Bitte wählen Sie ein Arbeitspaket aus.',
};

module.exports = german;
