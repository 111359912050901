//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames         from 'classnames';
import ComponentHelper    from '../../helper/ComponentHelper';
import HolidayLabel       from '../HolidayLabel';
import I18n               from 'i18next';
import PreAfterMonthLabel from '../PreviousAndNextMonthLabel';
import PropTypes          from '../PropTypes';
import React              from 'react';
import styles             from './styles.module.scss';
import ValueLabel         from '../ValueLabel';

class TableHolidayDateFormatter extends React.Component {
    render () {
        const value         = this.props.value;
        const date          = value.date;
        const holiday       = value.holiday;
        const currentMonth  = value.currentMonth;
        const targetMonth   = value.targetMonth;
        const isTargetMonth = currentMonth === targetMonth;

        if (value) {
            return (
                <span>
                    <ValueLabel
                        customClassNames={classNames(
                            styles.dateLabel,
                            {
                                [styles.greyedOutDate]: !isTargetMonth,
                            },
                        )}
                        customTitle={!isTargetMonth ? I18n.t('notCurrentMonthHint') : null}
                        showBold={true}
                        value={date}
                    />
                    <div className={styles.labelContainer}>
                        {this.renderPreAfterMonthLabel()}
                        <HolidayLabel
                            value={holiday}
                        />
                    </div>
                </span>
            );
        }

        return null;
    }

    renderPreAfterMonthLabel = () => {
        const value         = this.props.value;
        const currentMonth  = value.currentMonth;
        const targetMonth   = value.targetMonth;
        const isTargetMonth = currentMonth === targetMonth;

        if (!isTargetMonth) {
            let difference = currentMonth - targetMonth;

            if (targetMonth === 1 && currentMonth === 12) {
                difference = -1;
            }

            if (targetMonth === 12 && currentMonth === 1) {
                difference = 1;
            }

            return (
                <PreAfterMonthLabel
                    value={true}
                    text={I18n.t(difference > 0 ? 'nextMonth' : 'previousMonth')}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableHolidayDateFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
