//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes                     from '../PropTypes';
import React                         from 'react';
import styles                        from './styles.module.scss';
import classNames                    from 'classnames';
import { connect }                   from 'react-redux';
import ComponentHelper               from '../../helper/ComponentHelper';
import { bindActionCreators }        from 'redux';
import { ProjectTimeBookingActions } from '../../store/actions/projectTimeBooking';
import { LoadingActions }            from '../../store/actions/loading';

class LoadingOverlay extends React.Component {
    loadingOverlayClicked = () => {
        this.props.overlayClicked();
    };

    render () {
        return (
            <div
                className={classNames(
                    styles.loadingOverlay,
                    {
                        [styles.loadingOverlayVisible]: this.props.isLoading,
                    },
                )}
            >
                <div
                    className={styles.loadingOverlayPulse}
                >
                    <span onClick={this.loadingOverlayClicked}></span>
                    <span onClick={this.loadingOverlayClicked}></span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    isLoading: PropTypes.bool,
    text:      PropTypes.string,
};

Component.defaultProps = {
    isLoading: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isLoading: state.loading.isLoading,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(LoadingActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
