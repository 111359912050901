//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                            from 'lodash';
import ComponentHelper              from '../../helper/ComponentHelper';
import DefaultColumns               from '../Table/DefaultColumns';
import I18n                         from 'i18next';
import PropTypes                    from '../PropTypes';
import React                        from 'react';
import { connect }                  from 'react-redux';
import TableWithHeaderTitle         from '../TableWithHeaderTitle';
import TableWithHeaderTitleTheme    from '../TableWithHeaderTitle/TableWithHeaderTitleTheme';
import TableProjectActionsFormatter from '../TableProjectActionsFormatter';

class TopProjectTable extends React.Component {
    getColumnConfiguration = () => {
        const defaultColumns      = DefaultColumns.get();
        const columnConfiguration = _.compact([
            defaultColumns.projectNumber,
            defaultColumns.projectTitle,
            {
                formatter: TableProjectActionsFormatter,
                key:       'actions',
                name:      I18n.t('actions'),
                width:     30,
            },
        ]);

        return columnConfiguration;
    };

    getProjects = () => {
        const projectsWithTotalTimeOrderedByTotalTime = this.props.projectsWithTotalTimeOrderedByTotalTime;
        const projects                                = [];

        if (projectsWithTotalTimeOrderedByTotalTime) {
            for (const projectWithTotalTimeOrderedByTotalTime of projectsWithTotalTimeOrderedByTotalTime) {
                const project = projectWithTotalTimeOrderedByTotalTime.project;

                projects.push({
                    actions:       project,
                    projectNumber: project.number,
                    projectTitle:  project.title,
                    id:            project.id,
                });
            }
        }

        return projects;
    };

    render () {
        const projects = this.getProjects();

        return (
            <TableWithHeaderTitle
                theme={TableWithHeaderTitleTheme.green}
                title={I18n.t('myTopProjects')}
                columnConfiguration={this.getColumnConfiguration()}
                rawData={projects}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TopProjectTable;

Component.propTypes = {
    projects:                                PropTypes.array,
    projectsWithTotalTimeOrderedByTotalTime: PropTypes.array,
    projectTimeBookings:                     PropTypes.array,
};

Component.defaultProps = {
    projects:                                [],
    projectsWithTotalTimeOrderedByTotalTime: [],
    projectTimeBookings:                     [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        projects:                                state.project.projects,
        projectsWithTotalTimeOrderedByTotalTime: state.projectTimeBooking.projectsWithTotalTimeOrderedByTotalTime,
        projectTimeBookings:                     state.projectTimeBooking.projectTimeBookings,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
