//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import PropTypes                 from '../PropTypes';
import LongTextLabel             from '../LongTextLabel';
import ComponentHelper           from '../../helper/ComponentHelper';
import styles                    from './styles.module.scss';

class TableLongTextFormatter extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <div className={styles.tableLongTextFormatter}>
                    <div className={styles.labelWrapper}>
                        <LongTextLabel
                            value={this.props.value}
                        />
                    </div>
                </div>
            );
        }

        return '';
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableLongTextFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
