import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import BreadCrumbBar   from '../components/BreadCrumbBar';
import Headline        from '../components/Headline';
import HeadlineTheme   from '../components/Headline/HeadlineTheme';
import Paragraph       from '../components/Paragraph';

class Screen extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('pageNotFound'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    text={I18n.t('pageNotFoundTitle')}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Paragraph>
                    {I18n.t('pageNotFoundText')}
                </Paragraph>
            </div>
        );
    }
}

export default Screen;
