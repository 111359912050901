//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default class Api {
    static getBackendUrl () {
        const url = window.location.href;

        if (url.indexOf('.test') > -1 || url.indexOf('localhost') > -1) {
            return LOCAL_BACKEND_URL;
        } else if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    };

    static getBackendUrlAdminLogin () {
        return this.getBackendUrl() + '/admin';
    }

    /**
     * @returns {boolean}
     */
    static isStaging () {
        return (
            BACKEND_URL === STAGING_BACKEND_URL
        );
    }
}

export const PRODUCTION_BACKEND_URL        = 'https://api.zeiterfassung.vogtundfeist.de';
export const STAGING_BACKEND_URL           = 'https://api.staging.zeiterfassung.vogtundfeist.de';
export const LOCAL_BACKEND_URL             = 'https://vogt-feist-backend.test';
export const BACKEND_URL                   = Api.getBackendUrl();
export const BACKEND_URL_ADMIN_LOGIN       = Api.getBackendUrlAdminLogin();
export const API_HOLIDAYS_URL              = 'api/holidays';
export const API_PROJECT_TIME_BOOKINGS_URL = 'api/project-time-bookings';
export const API_PROJECTS_URL              = 'api/projects';
export const API_TEXT_TEMPLATES_URL        = 'api/text-templates';
export const API_TOKENS                    = 'api/tokens';
export const API_USER_TIME_DAY_SUM_URL     = 'api/user-time-sum-days';
export const API_USER_TIME_MONTH_SUM_URL   = 'api/user-time-sum-months';
export const API_USER_TIME_PROJECT_SUM_URL = 'api/user-time-sum-projects';
export const API_USER_TIME_YEAR_SUM_URL    = 'api/user-time-sum-years';

if (BACKEND_URL.endsWith('/')) {
    console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /');
}

if (BACKEND_URL !== PRODUCTION_BACKEND_URL) {
    console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
}

if (BACKEND_URL.indexOf('https://') === -1) {
    console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
}
