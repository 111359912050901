import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../helper/ComponentHelper';

class IconBox extends React.Component {
    render () {
        return (
            <span
                className={classNames(
                    styles.iconBox,
                    {
                        [styles.iconBoxClickable]: this.props.onClick,
                    },
                )}
                onClick={this.props.onClick}
            >
                <Icon
                    iconType={this.props.iconType}
                />
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = IconBox;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    onClick:  PropTypes.func,
};

Component.defaultProps         = {
    iconType: null,
    onClick:  _.noop,
};
Component.renderAffectingProps = [
    'iconType',
];

Component.renderAffectingStates = [];

export default Component;