// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/NumericFilter.js
import _                            from 'lodash';
import I18n                         from 'i18next';
import PropTypes                    from '../PropTypes';
import React                        from 'react';
import styles                       from './styles.module.scss';
import TableFilterInput             from '../TableFilterInput';
import UserTimeTrackingTableRowType from '../UserTimeTrackingTable/UserTimeTrackingTableRowType';
import { withTranslation }          from 'react-i18next';

class TableTextFilter extends React.Component {
    filterValues (row, columnFilter, columnKey) {
        if (row['type'] !== UserTimeTrackingTableRowType.sum) {
            if (columnFilter.rawValue && row[columnKey]) {
                const lowerCaseSearchTerm   = columnFilter.rawValue.toLowerCase().trim();
                const lowerCaseSearchTarget = row[columnKey].toString().toLowerCase();

                return lowerCaseSearchTarget.indexOf(lowerCaseSearchTerm) > -1;
            }

            return false;
        }
    }

    handleChange = (event) => {
        const value = event.target.value;

        this.props.onChange({
            filterTerm:   value.length > 0,
            column:       this.props.column,
            rawValue:     value,
            filterValues: this.filterValues,
        });
    };

    render () {
        const columnKey = _.get(this, 'props.column.key');
        const inputKey  = 'header-filter-' + columnKey;

        return (
            <TableFilterInput
                key={inputKey}
                placeholder={I18n.t('enterTextPlaceholderShort')}
                onChange={this.handleChange}
            />
        );
    }
}

TableTextFilter.propTypes = {
    column:   PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default withTranslation()(TableTextFilter);