//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const ProjectTimeBookingTypes = {
    DECREASE_DATE:                                        'ProjectTimeBooking/DECREASE_DATE',
    DECREASE_DURATION_HOURS:                              'ProjectTimeBooking/DECREASE_DURATION_HOURS',
    DECREASE_DURATION_MINUTES:                            'ProjectTimeBooking/DECREASE_DURATION_MINUTES',
    DELETE_PROJECT_TIME_BOOKING:                          'ProjectTimeBooking/DELETE_PROJECT_TIME_BOOKING',
    DELETE_PROJECT_TIME_BOOKING_FAILED:                   'ProjectTimeBooking/DELETE_PROJECT_TIME_BOOKING_FAILED',
    DELETE_PROJECT_TIME_BOOKING_SUCCEEDED:                'ProjectTimeBooking/DELETE_PROJECT_TIME_BOOKING_SUCCEEDED',
    INCREASE_DATE:                                        'ProjectTimeBooking/INCREASE_DATE',
    INCREASE_DURATION_HOURS:                              'ProjectTimeBooking/INCREASE_DURATION_HOURS',
    INCREASE_DURATION_MINUTES:                            'ProjectTimeBooking/INCREASE_DURATION_MINUTES',
    FETCH_ALL_PROJECT_TIME_BOOKINGS:                      'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKING',
    FETCH_ALL_PROJECT_TIME_BOOKINGS_FAILED:               'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKING_FAILED',
    FETCH_ALL_PROJECT_TIME_BOOKINGS_SUCCEEDED:            'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKING_SUCCEEDED',
    FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH:           'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH',
    FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_FAILED:    'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_FAILED',
    FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_SUCCEEDED: 'ProjectTimeBooking/FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_SUCCEEDED',
    SET_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE:              'ProjectTimeBooking/SET_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE',
    SET_DATE:                                             'ProjectTimeBooking/SET_DATE',
    SET_DURATION_HOURS:                                   'ProjectTimeBooking/SET_DURATION_HOURS',
    SET_DURATION_MINUTES:                                 'ProjectTimeBooking/SET_DURATION_MINUTES',
    SET_ID:                                               'ProjectTimeBooking/SET_ID',
    SET_PROJECT:                                          'ProjectTimeBooking/SET_PROJECT',
    SET_PROJECT_TIME_BOOKING:                             'ProjectTimeBooking/SET_PROJECT_TIME_BOOKING',
    SET_TEXT:                                             'ProjectTimeBooking/SET_TEXT',
    SET_TEXT_TEMPLATE:                                    'ProjectTimeBooking/SET_TEXT_TEMPLATE',
    SET_WORKING_PACKAGE:                                  'ProjectTimeBooking/SET_WORKING_PACKAGE',
    SUBMIT_PROJECT_TIME_BOOKING:                          'ProjectTimeBooking/SUBMIT_PROJECT_TIME_BOOKING',
    SUBMIT_PROJECT_TIME_BOOKING_FAILED:                   'ProjectTimeBooking/SUBMIT_PROJECT_TIME_BOOKING_FAILED',
    SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED:                'ProjectTimeBooking/SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED',
    TOGGLE_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE:           'ProjectTimeBooking/TOGGLE_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE',
    UPDATE_PROJECT_TIME_BOOKING:                          'ProjectTimeBooking/UPDATE_PROJECT_TIME_BOOKING',
    UPDATE_PROJECT_TIME_BOOKING_FAILED:                   'ProjectTimeBooking/UPDATE_PROJECT_TIME_BOOKING_FAILED',
    UPDATE_PROJECT_TIME_BOOKING_SUCCEEDED:                'ProjectTimeBooking/UPDATE_PROJECT_TIME_BOOKING_SUCCEEDED',
    OPEN_PROJECT_TIME_BOOKINGS:                           'ProjectTimeBooking/OPEN_PROJECT_TIME_BOOKINGS',
};

const decreaseProjectTimeBookingDate = makeActionCreator(
    ProjectTimeBookingTypes.DECREASE_DATE, {
        holidays: null,
    },
);

const decreaseProjectTimeBookingDurationHours = makeActionCreator(
    ProjectTimeBookingTypes.DECREASE_DURATION_HOURS,
);

const decreaseProjectTimeBookingDurationMinutes = makeActionCreator(
    ProjectTimeBookingTypes.DECREASE_DURATION_MINUTES,
);

const deleteProjectTimeBooking = makeActionCreator(
    ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING, {
        id: null,
    },
);

const deleteProjectTimeBookingFailed = makeActionCreator(
    ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_FAILED,
);

const deleteProjectTimeBookingSucceeded = makeActionCreator(
    ProjectTimeBookingTypes.DELETE_PROJECT_TIME_BOOKING_SUCCEEDED, {
        id: null,
    },
);

const increaseProjectTimeBookingDate = makeActionCreator(
    ProjectTimeBookingTypes.INCREASE_DATE, {
        holidays: null,
    },
);

const increaseProjectTimeBookingDurationHours = makeActionCreator(
    ProjectTimeBookingTypes.INCREASE_DURATION_HOURS,
);

const increaseProjectTimeBookingDurationMinutes = makeActionCreator(
    ProjectTimeBookingTypes.INCREASE_DURATION_MINUTES,
);

const fetchAllProjectTimeBookings = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS,
);

const fetchAllProjectTimeBookingsFailed = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_FAILED,
);

const fetchAllProjectTimeBookingsSucceeded = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_SUCCEEDED, {
        projectTimeBookings: null,
    },
);

const fetchAllProjectTimeBookingsYearMonth = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH, {
        month: null,
        year:  null,
    },
);

const fetchAllProjectTimeBookingsYearMonthFailed = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_FAILED, {
        month: null,
        year:  null,
    },
);

const fetchAllProjectTimeBookingsYearMonthSucceeded = makeActionCreator(
    ProjectTimeBookingTypes.FETCH_ALL_PROJECT_TIME_BOOKINGS_YEAR_MONTH_SUCCEEDED, {
        projectTimeBookings: null,
    },
);

const setProjectTimeBooking = makeActionCreator(
    ProjectTimeBookingTypes.SET_PROJECT_TIME_BOOKING, {
        projectTimeBooking: null,
    },
);

const setProjectTimeBookingAddAnotherTimeBookingAfterSave = makeActionCreator(
    ProjectTimeBookingTypes.SET_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE, {
        addAnotherTimeBookingAfterSave: null,
    },
);

const setProjectTimeBookingDate = makeActionCreator(
    ProjectTimeBookingTypes.SET_DATE, {
        date: null,
    },
);

const setProjectTimeBookingDurationHours = makeActionCreator(
    ProjectTimeBookingTypes.SET_DURATION_HOURS, {
        hours: null,
    },
);

const setProjectTimeBookingDurationMinutes = makeActionCreator(
    ProjectTimeBookingTypes.SET_DURATION_MINUTES, {
        minutes: null,
    },
);

const setProjectTimeBookingId = makeActionCreator(
    ProjectTimeBookingTypes.SET_ID, {
        id: null,
    },
);

const setProjectTimeBookingProject = makeActionCreator(
    ProjectTimeBookingTypes.SET_PROJECT, {
        project: null,
    },
);

const setProjectTimeBookingText = makeActionCreator(
    ProjectTimeBookingTypes.SET_TEXT, {
        text: null,
    },
);

const setProjectTimeBookingTextTemplate = makeActionCreator(
    ProjectTimeBookingTypes.SET_TEXT_TEMPLATE, {
        textTemplate: null,
    },
);

const setProjectTimeBookingWorkingPackage = makeActionCreator(
    ProjectTimeBookingTypes.SET_WORKING_PACKAGE, {
        workingPackage: null,
    },
);

const submitProjectTimeBooking = makeActionCreator(
    ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING, {
        date:           null,
        project:        null,
        text:           null,
        time:           null,
        workingPackage: null,
    },
);

const submitProjectTimeBookingFailed = makeActionCreator(
    ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_FAILED,
);

const submitProjectTimeBookingSucceeded = makeActionCreator(
    ProjectTimeBookingTypes.SUBMIT_PROJECT_TIME_BOOKING_SUCCEEDED, {
        projectTimeBooking: null,
    },
);

const updateProjectTimeBooking = makeActionCreator(
    ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING, {
        date:           null,
        id:             null,
        project:        null,
        text:           null,
        time:           null,
        workingPackage: null,
    },
);

const updateProjectTimeBookingFailed = makeActionCreator(
    ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING_FAILED,
);

const updateProjectTimeBookingSucceeded = makeActionCreator(
    ProjectTimeBookingTypes.UPDATE_PROJECT_TIME_BOOKING_SUCCEEDED, {
        projectTimeBooking: null,
    },
);

const toggleProjectTimeBookingAddAnotherTimeBookingAfterSave = makeActionCreator(
    ProjectTimeBookingTypes.TOGGLE_ADD_ANOTHER_TIME_BOOKING_AFTER_SAVE,
);

const openProjectTimeBookings = makeActionCreator(
    ProjectTimeBookingTypes.OPEN_PROJECT_TIME_BOOKINGS, {
        month: null,
        year:  null,
    },
);

export const ProjectTimeBookingActions = {
    decreaseProjectTimeBookingDate,
    decreaseProjectTimeBookingDurationHours,
    decreaseProjectTimeBookingDurationMinutes,
    deleteProjectTimeBooking,
    deleteProjectTimeBookingFailed,
    deleteProjectTimeBookingSucceeded,
    increaseProjectTimeBookingDate,
    increaseProjectTimeBookingDurationHours,
    increaseProjectTimeBookingDurationMinutes,
    fetchAllProjectTimeBookings,
    fetchAllProjectTimeBookingsFailed,
    fetchAllProjectTimeBookingsSucceeded,
    fetchAllProjectTimeBookingsYearMonth,
    fetchAllProjectTimeBookingsYearMonthFailed,
    fetchAllProjectTimeBookingsYearMonthSucceeded,
    setProjectTimeBooking,
    setProjectTimeBookingAddAnotherTimeBookingAfterSave,
    setProjectTimeBookingDate,
    setProjectTimeBookingDurationHours,
    setProjectTimeBookingDurationMinutes,
    setProjectTimeBookingId,
    setProjectTimeBookingProject,
    setProjectTimeBookingText,
    setProjectTimeBookingTextTemplate,
    setProjectTimeBookingWorkingPackage,
    submitProjectTimeBooking,
    submitProjectTimeBookingFailed,
    submitProjectTimeBookingSucceeded,
    toggleProjectTimeBookingAddAnotherTimeBookingAfterSave,
    updateProjectTimeBooking,
    updateProjectTimeBookingFailed,
    updateProjectTimeBookingSucceeded,
    openProjectTimeBookings,
};
