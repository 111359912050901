//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import classNames             from 'classnames';
import ComponentHelper        from '../../helper/ComponentHelper';
import Dashboard              from '../../screens/Dashboard';
import DataProtection         from '../../screens/DataProtection';
import I18n                   from 'i18next';
import IconButton             from '../IconButton';
import IconButtonTheme        from '../IconButton/IconButtonTheme';
import IconType               from '../Icon/IconType';
import Imprint                from '../../screens/Imprint';
import Login                  from '../../screens/Login';
import NotFound               from '../../screens/NotFound';
import Projects               from '../../screens/Projects';
import PropTypes              from '../PropTypes';
import React                  from 'react';
import Routes                 from '../../constants/Routes';
import SideBarLink            from '../SideBarLink';
import SplitPane              from 'react-split-pane';
import SplitPaneConfiguration from './SplitPaneConfiguration';
import SplitPaneMode          from '../../constants/SplitPaneMode';
import SplitPanePanel         from '../SplitPanePanel';
import SplitPanePrimary       from '../../constants/SplitPanePrimary';
import splitPaneStyle         from './splitPaneStyle';
import styles                 from './styles.module.scss';
import TimeTracking           from '../../screens/TimeTracking';
import TimeTrackingForm       from '../../screens/TimeTrackingForm';
import WindowEventHelper      from '../../helper/WindowEvent';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { LayoutActions }      from '../../store/actions/layout';
import { Route }              from 'react-router';
import { Switch }             from 'react-router';
import { Redirect }           from 'react-router';

class MainRouter extends React.Component {
    getLeftSideBarResizerStyle = () => {
        return this.getVerticalSideBarResizerStyle(this.props.layout.leftSideBarVisible);
    };

    getLeftSideBarSize () {
        if (this.props.layout.leftSideBarVisible && this.shouldShowSideBars()) {
            return this.props.layout.leftSideBarSize;
        }

        return 0;
    }

    getRightSideBarResizerStyle = () => {
        return this.getVerticalSideBarResizerStyle(this.props.layout.rightSideBarSize);
    };

    getRightSideBarSize () {
        if (this.props.layout.rightSideBarVisible && this.shouldShowSideBars()) {
            return this.props.layout.rightSideBarSize;
        }

        return 0;
    }

    getVerticalSideBarResizerStyle = (visible) => {
        if (visible) {
            return splitPaneStyle.resizerVertical;
        }

        return splitPaneStyle.resizerVerticalHidden;
    };

    onLeftSideBarChanged = (newSize) => {
        this.props.leftSideBarSetSize({ size: newSize });
    };

    onRightSideBarChanged = (newSize) => {
        this.props.rightSideBarSetSize({ size: newSize });
    };

    onSideBarChange = (newSize) => {
        WindowEventHelper.fakeResize();
    };

    render () {
        const leftSideBarSize          = this.getLeftSideBarSize();
        const leftSideBarResizerStyle  = this.getLeftSideBarResizerStyle();
        const rightSideBarSize         = this.getRightSideBarSize();
        const rightSideBarResizerStyle = this.getRightSideBarResizerStyle();

        return (
            <div
                className={classNames(
                    styles.mainRouter,
                    {
                        [styles.mainRouterLoading]: this.props.isLoading,
                    },
                )}
            >
                <SplitPane
                    className={styles.mainSplitPane}
                    split={SplitPaneMode.vertical}
                    onDragFinished={this.onLeftSideBarChanged}
                    minSize={SplitPaneConfiguration.left.minimumSize}
                    maxSize={SplitPaneConfiguration.left.maximumSize}
                    defaultSize={leftSideBarSize}
                    size={leftSideBarSize}
                    primary={SplitPanePrimary.first}
                    resizerStyle={leftSideBarResizerStyle}
                    allowResize={true}
                    onChange={this.onSideBarChange}
                >
                    <SplitPanePanel>
                        {this.renderLeftPanelContent()}
                    </SplitPanePanel>
                    <SplitPanePanel>
                        {this.renderCenterPanelContent()}
                    </SplitPanePanel>
                </SplitPane>
            </div>
        );
    }

    renderCenterPanelContent = () => {
        return (
            <div className={styles.mainRouterContent}>
                <Switch>
                    <Route
                        exact
                        path={Routes.index}
                    >
                        <Redirect
                            to={Routes.getRouteWithParameters(
                                Routes.dashboard,
                                {
                                    monthYear: '',
                                },
                            )}
                        />
                    </Route>
                    <Route
                        exact
                        path={Routes.login}
                        component={Login}
                    />
                    <Route
                        exact
                        path={Routes.dashboard}
                        component={Dashboard}
                    />
                    <Route
                        exact
                        path={Routes.timeTrackingCreate}
                        component={TimeTrackingForm}
                    />
                    <Route
                        exact
                        path={Routes.timeTrackingEdit}
                        component={TimeTrackingForm}
                    />
                    <Route
                        exact
                        path={Routes.projects}
                        component={Projects}
                    />
                    <Route
                        exact
                        path={Routes.timeTracking}
                        component={TimeTracking}
                    />
                    <Route
                        exact
                        path={Routes.imprint}
                        component={Imprint}
                    />
                    <Route
                        exact
                        path={Routes.dataProtection}
                        component={DataProtection}
                    />
                    <Route
                        component={NotFound}
                    />
                </Switch>
            </div>
        );
    };

    renderLeftPanelContent = () => {
        return (
            <div className={styles.mainRouterLeftSideBar}>
                <div className={styles.mainRouterLeftSideBarLinkWrapper}>
                    <SideBarLink
                        text={I18n.t('myOverview')}
                        on
                        to={Routes.getRouteWithParameters(
                            Routes.dashboard,
                            {
                                monthYear: '',
                            },
                        )}
                    />
                </div>
                <div className={styles.mainRouterLeftSideBarLinkWrapper}>
                    <SideBarLink
                        text={I18n.t('projects')}
                        to={Routes.projects}
                    />
                </div>
                <div className={styles.mainRouterLeftSideBarLinkWrapper}>
                    <SideBarLink
                        text={I18n.t('timeTracking')}
                        to={Routes.getRouteWithParameters(
                            Routes.timeTracking,
                            {
                                monthYear: '',
                            },
                        )}
                    />
                    <div className={styles.mainRouterLeftSideBarAddButtonWrapper}>
                        <IconButton
                            iconType={IconType.add}
                            theme={IconButtonTheme.redRounded}
                            to={Routes.timeTrackingCreate}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderRightPanelContent = () => {
        // This pane is empty for now

        return null;
    };

    shouldShowSideBars = () => {
        return this.props.pathname !== Routes.login;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MainRouter;

Component.propTypes = {
    isLoading: PropTypes.bool,
    pathname:  PropTypes.string,
    layout:    PropTypes.object,
};

Component.defaultProps = {
    isLoading: false,
    pathname:  null,
    layout:    null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isLoading: state.loading.isLoading,
        pathname:  state.router.location.pathname,
        layout:    state.layout,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LayoutActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
