import ComponentHelper from '../../helper/ComponentHelper';
import React           from 'react';
import PropTypes       from '../PropTypes';
import IconButton      from '../IconButton';
import styles          from './styles.module.scss';
import IconType        from '../Icon/IconType';
import _               from 'lodash';

class InputArrowControl extends React.Component {
    render () {
        return (
            <div className={styles.inputArrowControlWrapper}>
                {this.renderArrows()}
                {this.props.children}
            </div>
        );
    }

    renderArrows = () => {
        return (
            <div
                className={styles.inputArrowControlArrowWrapper}
                style={{
                    right: this.props.extraMarginRight,
                }}
            >
                <div className={styles.inputArrowControlArrowWrapperTop}>
                    <IconButton
                        iconType={IconType.arrowUp}
                        onClick={this.props.arrowUpClicked}
                    />
                </div>
                <div className={styles.inputArrowControlArrowWrapperBottom}>
                    <IconButton
                        iconType={IconType.arrowDown}
                        onClick={this.props.arrowDownClicked}
                    />
                </div>
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = InputArrowControl;

Component.propTypes = {
    arrowDownClicked: PropTypes.func,
    arrowUpClicked:   PropTypes.func,
    children:         PropTypes.children,
    extraMarginRight: PropTypes.number,
};

Component.defaultProps = {
    arrowDownClicked: _.noop,
    arrowUpClicked:   _.noop,
    children:         null,
    extraMarginRight: 0,
};

Component.renderAffectingProps = [
    'children',
];

Component.renderAffectingStates = [];

export default Component;