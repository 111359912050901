import React           from 'react';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import Cast            from '../../helper/Cast';
import styles          from './styles.module.scss';
import ComponentHelper from '../../helper/ComponentHelper';

class DurationLabel extends React.Component {
    getValue = () => {
        const value = this.props.value;

        if (value) {
            const fixedValue       = value.toFixed(2);
            const valueString      = Cast.string(fixedValue);
            const valueStringComma = valueString.replace('.', ',');

            return valueStringComma;
        }

        return '0,00';
    };

    render () {
        const value = this.getValue();

        return (
            <span
                className={styles.durationLabel}
                title={value + ' ' + I18n.t('hours')}
            >
                {value}
                {' '}
                {I18n.t('hourShort')}
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DurationLabel;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;