//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import ComponentHelper               from '../../helper/ComponentHelper';
import I18n                          from 'i18next';
import IconButton                    from '../IconButton';
import IconButtonTheme               from '../IconButton/IconButtonTheme';
import IconType                      from '../Icon/IconType';
import PropTypes                     from '../PropTypes';
import Routes                        from '../../constants/Routes';
import styles                        from './styles.module.scss';
import { bindActionCreators }        from 'redux';
import { connect }                   from 'react-redux';
import { ProjectTimeBookingActions } from '../../store/actions/projectTimeBooking';
import { withRouter }                from 'react-router-dom';

class ProjectOverviewActionList extends React.Component {
    copyButtonPressed = () => {
        this.props.setProjectTimeBookingText({
            text: this.props.text,
        });
    };

    editButtonPressed = () => {
        this.props.setProjectTimeBooking({
            projectTimeBooking: this.props.projectTimeBooking,
        });

        this.props.history.push(Routes.timeTrackingEdit);
    };

    renderEditButton = () => {
        const timeBookingUserId    = _.get(this.props, 'projectTimeBooking.user.id');
        const shouldShowEditButton = _.endsWith(this.props.userId, timeBookingUserId);

        if (shouldShowEditButton) {
            return (
                <li>
                    <IconButton
                        iconType={IconType.pencil}
                        onClick={this.editButtonPressed}
                        theme={IconButtonTheme.grayRounded}
                        title={I18n.t('timeTrackingEdit')}
                    />
                </li>
            );
        }

        return null;
    };

    render () {
        if (this.props.text) {
            return (
                <ul className={styles.projectOverviewActionList}>
                    <li>
                        <IconButton
                            iconType={IconType.copy}
                            onClick={this.copyButtonPressed}
                            theme={IconButtonTheme.grayRounded}
                            title={I18n.t('copyText')}
                        />
                    </li>
                    {this.renderEditButton()}
                </ul>
            );
        }

        return '';
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectOverviewActionList;

Component.propTypes = {
    text:               PropTypes.string,
    projectTimeBooking: PropTypes.any,
};

Component.defaultProps = {
    text:               [],
    projectTimeBooking: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        userId: state.user.id,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(ProjectTimeBookingActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Component));
