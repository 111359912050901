import ComponentHelper from '../../helper/ComponentHelper';
import React           from 'react';
import PropTypes       from '../PropTypes';
import styles          from './styles.module.scss';
import Select          from '../Select';
import SelectTheme     from '../Select/SelectTheme';
import IconBox         from '../IconBox';
import IconType        from '../Icon/IconType';
import _               from 'lodash';

class SelectInput extends React.Component {
    selectReference = null;

    iconBoxClicked = () => {
        if (this.selectReference) {
            this.selectReference.select.openMenu();
            this.selectReference.select.focus();
        }
    };

    getSelectedValue = () => {
        const value         = this.props.value;
        const selectedValue = this.props.options.filter(option => option.value === value);

        return selectedValue;
    };

    render () {
        return (
            <div className={styles.selectWrapper}>
                {this.renderIconBox()}
                {this.renderSelect()}
            </div>
        );
    }

    renderIconBox = () => {
        if (this.props.iconType) {
            return (
                <IconBox
                    iconType={this.props.iconType}
                    onClick={this.iconBoxClicked}
                />
            );
        }

        return null;
    };

    renderSelect = () => {
        return (
            <Select
                innerRef={this.setSelectReference}
                options={this.props.options}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                theme={SelectTheme.noBorderLeft}
                value={this.getSelectedValue()}
            />
        );
    };

    setSelectReference = (selectReference) => {
        this.selectReference = selectReference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SelectInput;

Component.propTypes = {
    iconType:    PropTypes.oneOf(Object.values(IconType)),
    onChange:    PropTypes.func,
    options:     PropTypes.array,
    placeholder: PropTypes.string,
    value:       PropTypes.string,
};

Component.defaultProps = {
    iconType:    null,
    onChange:    _.noop,
    options:     [],
    placeholder: null,
    value:       null,
};

Component.renderAffectingProps = [
    'iconType',
    'placeholder',
    'options',
];

Component.renderAffectingStates = [];

export default SelectInput;