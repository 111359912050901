//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../helper/ComponentHelper';
import DateHelper      from '../../helper/Date';
import I18n            from 'i18next';
import IconButton      from '../IconButton';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class MonthlyContainer extends React.Component {
    increaseMonth = (month, year) => {
        this.changeMonth(month, year, 1);
    };

    decreaseMonth = (month, year) => {
        this.changeMonth(month, year, -1);
    };

    changeMonth = (month, year, offset) => {
        let newMonth = parseInt(month) + offset;
        let newYear  = parseInt(year);

        if (newMonth > 12) {
            newYear++;
            newMonth = newMonth - 12;
        }

        if (newMonth < 1) {
            newYear--;
            newMonth = 12 - newMonth;
        }

        this.props.updateMonthAndYear({
            month: newMonth,
            year:  newYear,
        });
    };

    onArrowLeftButtonClicked = () => {
        this.decreaseMonth(this.props.month, this.props.year);
    };

    onArrowRightButtonClicked = () => {
        this.increaseMonth(this.props.month, this.props.year);
    };

    render () {
        const currentMonthName = DateHelper.getMonthName(this.props.month - 1);

        return (
            <div
                className={classNames(
                    styles.monthlyContainerContainer,
                    'boxed',
                )}
            >
                <div className={styles.monthlyContainerHeader}>
                    <div>
                        <IconButton
                            iconType={IconType.arrowLeft}
                            onClick={this.onArrowLeftButtonClicked}
                        />
                    </div>
                    <span>
                        {I18n.t(currentMonthName)} {this.props.year}
                    </span>
                    <div>
                        <IconButton
                            iconType={IconType.arrowRight}
                            onClick={this.onArrowRightButtonClicked}
                        />
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MonthlyContainer;

Component.propTypes = {
    userTimeSums:       PropTypes.array,
    month:              PropTypes.any,
    year:               PropTypes.any,
    updateMonthAndYear: PropTypes.func,
};

Component.defaultProps = {
    userTimeSums:       null,
    month:              null,
    year:               null,
    updateMonthAndYear: _.noop(),
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
