//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { REHYDRATE } from 'redux-persist';

import { Api }         from '../../api';
import { UserActions } from '../actions/user';
import _               from 'lodash';

function apiMiddleware() {
    return ({ dispatch }) => (next) => (action) => {
        if (
            action.key === 'root' &&
            action.type === REHYDRATE
        ) {
            const checkForInvalidTokenMonitor = (response) => {
                const status = _.get(response, 'status');

                if (
                    status === 401 ||
                    status === 403
                ) {
                    dispatch(UserActions.logout());
                }
            };

            Api.addMonitor(checkForInvalidTokenMonitor);
        }

        return next(action);
    };
}

export default apiMiddleware;
