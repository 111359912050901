import { makeActionCreator } from '../../helper/Store';

export const TextTemplateTypes = {
    FETCH_ALL_TEXT_TEMPLATES:           'TextTemplate/FETCH_ALL_TEXT_TEMPLATES',
    FETCH_ALL_TEXT_TEMPLATES_FAILED:    'TextTemplate/FETCH_ALL_TEXT_TEMPLATES_FAILED',
    FETCH_ALL_TEXT_TEMPLATES_SUCCEEDED: 'TextTemplate/FETCH_ALL_TEXT_TEMPLATES_SUCCEEDED',
};

const fetchAllTextTemplates = makeActionCreator(
    TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES,
);

const fetchAllTextTemplatesFailed = makeActionCreator(
    TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES_FAILED,
);

const fetchAllTextTemplatesSucceeded = makeActionCreator(
    TextTemplateTypes.FETCH_ALL_TEXT_TEMPLATES_SUCCEEDED, {
        textTemplates: null,
    },
);

export const TextTemplateActions = {
    fetchAllTextTemplates,
    fetchAllTextTemplatesFailed,
    fetchAllTextTemplatesSucceeded,
};