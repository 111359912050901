//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper               from '../../helper/ComponentHelper';
import I18n                          from 'i18next';
import IconButton                    from '../IconButton';
import IconButtonTheme               from '../IconButton/IconButtonTheme';
import IconType                      from '../Icon/IconType';
import PropTypes                     from '../PropTypes';
import React                         from 'react';
import Routes                        from '../../constants/Routes';
import styles                        from './styles.module.scss';
import { bindActionCreators }        from 'redux';
import { connect }                   from 'react-redux';
import { ProjectTimeBookingActions } from '../../store/actions/projectTimeBooking';
import { withRouter }                from 'react-router-dom';

class ProjectTimeBookingActionList extends React.Component {
    copyButtonPressed = () => {
        this.props.setProjectTimeBooking({
            projectTimeBooking: this.props.projectTimeBooking,
        });
        this.props.setProjectTimeBookingId({
            id: null,
        });

        this.props.history.push(Routes.timeTrackingCreate);
    };

    editButtonPressed = () => {
        this.props.setProjectTimeBooking({
            projectTimeBooking: this.props.projectTimeBooking,
        });

        this.props.history.push(Routes.timeTrackingEdit);
    };

    render () {
        if (this.props.projectTimeBooking) {
            return (
                <ul className={styles.projectTimeBookingActionList}>
                    <li>
                        <IconButton
                            iconType={IconType.copy}
                            onClick={this.copyButtonPressed}
                            theme={IconButtonTheme.grayRounded}
                            title={I18n.t('timeTrackingCopy')}
                        />
                    </li>
                    <li>
                        <IconButton
                            iconType={IconType.pencil}
                            onClick={this.editButtonPressed}
                            theme={IconButtonTheme.grayRounded}
                            title={I18n.t('timeTrackingEdit')}
                        />
                    </li>
                </ul>
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectTimeBookingActionList;

Component.propTypes = {
    projectTimeBooking: PropTypes.object,
};

Component.defaultProps = {
    projectTimeBooking: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(ProjectTimeBookingActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Component));
