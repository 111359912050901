import React                  from 'react';
import PropTypes              from '../PropTypes';
import TimeTrackingActionList from '../TimeTrackingActionList';

class TableTimeTrackingActionsFormatter extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <TimeTrackingActionList projectTimeBooking={this.props.value} />
            );
        }

        return '';
    }
}

const Component = TableTimeTrackingActionsFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;