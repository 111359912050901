import React           from 'react';
import PropTypes       from '../PropTypes';
import classNames      from 'classnames';
import styles          from './styles.module.scss';
import ComponentHelper from '../../helper/ComponentHelper';

class AbbreviationLabel extends React.Component {
    render () {
        return (
            <span
                className={classNames(
                    // This is used to target the the label in tables to change the background
                    // color from gray to gray
                    'abbreviationLabel',
                    styles.abbreviationLabel,
                )}
            >
                {this.props.value}
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = AbbreviationLabel;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;