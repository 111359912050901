//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import Table                     from '../Table';
import DefaultColumns            from '../Table/DefaultColumns';
import moment                    from 'moment';
import I18n                      from 'i18next';
import _                         from 'lodash';
import SortDirection             from '../../constants/SortDirection';
import PropTypes                 from '../PropTypes';
import WorkingPackageTitleHelper from '../../helper/WorkingPackageTitleHelper';
import ComponentHelper           from '../../helper/ComponentHelper';

class UserTimeTrackingTable extends React.Component {
    getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get({
            withLongDate: true,
        });

        return [
            defaultColumns.date,
            defaultColumns.duration,
            defaultColumns.workingPackageAbbreviation,
            defaultColumns.textWithCopy,
            defaultColumns.employeeAbbreviation,
            defaultColumns.overviewActions,
        ];
    };

    getFormattedDate = (date) => {
        const dateAsMomentDate = new moment(date);
        const formattedDate    = dateAsMomentDate.format(I18n.t('listDateFormatWithShortDay'));

        return formattedDate;
    };

    getProjectTimeBookings = () => {
        const project           = _.get(this.props, 'project');
        let projectTimeBookings = [];

        if (this.props.projectTimeBookings) {
            for (const projectTimeBooking of this.props.projectTimeBookings) {
                const workingPackage           = projectTimeBooking.workingPackage;
                const workingPackageTitle      = WorkingPackageTitleHelper.getWorkingPackageTitle(workingPackage);
                let workingPackageAbbreviation = workingPackage.abbreviation;

                if (!workingPackageAbbreviation) {
                    if (workingPackage.workingPackageType === 'HAOI_SERVICE') {
                        workingPackageAbbreviation = workingPackage.sortOrder;
                    } else {
                        workingPackageAbbreviation = 'SL';
                    }
                }

                projectTimeBookings.push({
                    date:                       this.getFormattedDate(projectTimeBooking.date),
                    description:                projectTimeBooking.text,
                    duration:                   projectTimeBooking.timeInHours,
                    employeeAbbreviation:       _.get(projectTimeBooking, 'user.abbreviation'),
                    workingPackageAbbreviation: {
                        abbreviation: workingPackageAbbreviation,
                        title:        workingPackageTitle,
                    },
                    actions:                    {
                        project,
                        ...projectTimeBooking,
                    },
                });
            }
        }

        return projectTimeBookings;
    };

    render () {
        const projectTimeBookings = this.getProjectTimeBookings();

        return (
            <Table
                columnConfiguration={this.getColumnConfiguration()}
                rawData={projectTimeBookings}
                sortColumn={'date'}
                sortDirection={SortDirection.desc}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserTimeTrackingTable;

Component.propTypes = {
    projectTimeBookings: PropTypes.array,
    project:             PropTypes.any,
};

Component.defaultProps = {
    projectTimeBookings: [],
    project:             null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
