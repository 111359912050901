import React           from 'react';
import I18n            from 'i18next';
import PropTypes       from '../PropTypes';
import classNames      from 'classnames';
import styles          from './styles.module.scss';
import SelectionHelper from '../../helper/SelectionHelper';
import ProjectStatus   from '../../constants/ProjectStatus';
import ComponentHelper from '../../helper/ComponentHelper';

class StatusLabel extends React.Component {
    getCleanValue = () => {
        const cleanValue = this.props.value.toLowerCase();

        return cleanValue;
    };

    getLabel = () => {
        const cleanValue = this.getCleanValue();

        return SelectionHelper.get(
            cleanValue,
            {
                [ProjectStatus.active]:   I18n.t('projectStatusActive'),
                [ProjectStatus.finished]: I18n.t('projectStatusFinished'),
                [ProjectStatus.sketch]:   I18n.t('projectStatusSketch'),
            },
        );
    };

    getStyleClass = () => {
        const cleanValue = this.getCleanValue();

        return SelectionHelper.get(
            cleanValue,
            {
                [ProjectStatus.active]:   styles.statusLabelActive,
                [ProjectStatus.finished]: styles.statusLabelFinished,
                [ProjectStatus.sketch]:   styles.statusLabelSketch,
            },
        );
    };

    render () {
        const label      = this.getLabel();
        const styleClass = this.getStyleClass();

        return (
            <span
                className={classNames(
                    styles.statusLabel,
                    styleClass,
                )}
            >
                {label}
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = StatusLabel;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;