//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                                       from 'lodash';
import ComponentHelper                         from '../../helper/ComponentHelper';
import DefaultColumns                          from '../Table/DefaultColumns';
import I18n                                    from 'i18next';
import moment                                  from 'moment';
import PropTypes                               from '../PropTypes';
import React                                   from 'react';
import TableProjectTimeBookingActionsFormatter from '../TableProjectTimeBookingActionsFormatter';
import TableWithHeaderTitle                    from '../TableWithHeaderTitle';
import TableWithHeaderTitleTheme               from '../TableWithHeaderTitle/TableWithHeaderTitleTheme';
import WorkingPackageTitleHelper               from '../../helper/WorkingPackageTitleHelper';
import { connect }                             from 'react-redux';

class LatestTrackedTimeTable extends React.Component {
    getColumnConfiguration = () => {
        const defaultColumns      = DefaultColumns.get();
        const columnConfiguration = _.compact([
            defaultColumns.date,
            defaultColumns.duration,
            defaultColumns.workingPackageTitle,
            {
                formatter: TableProjectTimeBookingActionsFormatter,
                key:       'actions',
                name:      I18n.t('actions'),
                width:     60,
            },
        ]);

        return columnConfiguration;
    };

    getProjectTimeBookings = () => {
        const orderedProjectTimeBookings = _.orderBy(this.props.projectTimeBookings, ['date'], ['desc']);
        const projectTimeBookings        = [];
        let maxAmount                    = 4;

        for (const projectTimeBooking of orderedProjectTimeBookings) {
            if (maxAmount === 0) {
                break;
            }

            const workingPackageTitle = WorkingPackageTitleHelper.getWorkingPackageTitle(projectTimeBooking.workingPackage);
            const formattedDate       = this.getFormattedDate(projectTimeBooking.date);

            projectTimeBookings.push({
                date:                formattedDate,
                duration:            projectTimeBooking.timeInHours,
                workingPackageTitle: workingPackageTitle,
                actions:             projectTimeBooking,
            });

            maxAmount--;
        }

        return projectTimeBookings;
    };

    getFormattedDate = (date) => {
        const dateAsMomentDate = new moment(date);
        const formattedDate    = dateAsMomentDate.format(I18n.t('listDateFormat'));

        return formattedDate;
    };

    render () {
        const projectTimeBookings = this.getProjectTimeBookings();

        return (
            <TableWithHeaderTitle
                theme={TableWithHeaderTitleTheme.yellow}
                title={I18n.t('latestTrackedTime')}
                columnConfiguration={this.getColumnConfiguration()}
                rawData={projectTimeBookings}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LatestTrackedTimeTable;

Component.propTypes = {
    projectTimeBookings: PropTypes.array,
};

Component.defaultProps = {
    projectTimeBookings: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        projectTimeBookings: state.projectTimeBooking.projectTimeBookings,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
