'use strict';

export default class ComponentHelper {
    static shouldComponentUpdate (componentClass, component, nextProps, nextStates) {
        if (!this.hasComponentRequiredFields(component)) {
            return true;
        }

        const props        = component.renderAffectingProps;
        const currentProps = componentClass.props;
        const propsChanged = props.some((prop) => {
            return currentProps[prop] !== nextProps[prop];
        });

        if (propsChanged) {
            return true;
        }

        const states        = component.renderAffectingStates;
        const currentStates = componentClass.state;
        const statesChanged = states.some((state) => {
            return currentStates[state] !== nextStates[state];
        });

        return statesChanged;
    }

    static hasComponentRequiredFields (component) {
        const hasRenderAffectingProps  = component.hasOwnProperty('renderAffectingProps');
        const hasRenderAffectingStates = component.hasOwnProperty('renderAffectingStates');

        if (!hasRenderAffectingProps || !hasRenderAffectingStates) {
            console.error(
                'The component "' + component.name + '" is missing the ' + (
                    !hasRenderAffectingProps ? 'renderAffectingProps' : 'renderAffectingStates'
                ) + ' property.',
            );

            return false;
        }

        const renderAffectingPropsIsArray  = Array.isArray(component.renderAffectingProps);
        const renderAffectingStatesIsArray = Array.isArray(component.renderAffectingStates);

        if (!renderAffectingPropsIsArray || !renderAffectingStatesIsArray) {
            console.error(
                'Wrong variable type detected in the "' + component.name + '" component: The ' + (
                    !renderAffectingPropsIsArray ? 'renderAffectingProps' : 'renderAffectingStates'
                ) + ' should be an "array", "' + (
                    !renderAffectingPropsIsArray ? typeof component.renderAffectingProps : typeof component.renderAffectingStates
                ) + '" given.',
            );

            return false;
        }

        return true;
    }
}