import React           from 'react';
import PropTypes       from '../PropTypes';
import OverlayMenu     from '../OverlayMenu';
import OverlayMenuText from '../OverlayMenuText';
import classNames      from 'classnames';
import styles          from './styles.module.scss';
import ComponentHelper from '../../helper/ComponentHelper';

class LongTextLabel extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            mouseIsOver: false,
        };
    }

    onMouseEnter = () => {
        this.setMouseIsOver(true);
    };

    onMouseLeave = () => {
        this.setMouseIsOver(false);
    };

    render () {
        return (
            <div className={styles.longTextLabelWrapper}>
                <span
                    className={classNames(
                        styles.longTextLabel,
                    )}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    {this.props.value}
                </span>
                {this.renderOverlay()}
            </div>
        );
    }

    renderOverlay = () => {
        return (
            <OverlayMenu
                key={'longTextOverlay'}
                right={50}
                visible={this.state.mouseIsOver}
            >
                <OverlayMenuText
                    text={this.props.value}
                />
            </OverlayMenu>
        );
    };

    setMouseIsOver = (mouseIsOver) => {
        this.setState({ mouseIsOver });
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LongTextLabel;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'mouseIsOver',
];

export default Component;