import PropTypes                         from '../PropTypes';
import React                             from 'react';
import styles                            from './styles.module.scss';
import _                                 from 'lodash';
import LeftRightSplitterContentAlignment from './LeftRightSplitterContentAlignment';
import classNames                        from 'classnames';
import SelectionHelper                   from '../../helper/SelectionHelper';

class LeftRightSplitter extends React.Component {
    getSplitterAlignmentClass = () => {
        return SelectionHelper.get(
            this.props.contentAlignment,
            {
                [LeftRightSplitterContentAlignment.center]:  styles.leftRightSplitterAlignCenter,
                [LeftRightSplitterContentAlignment.default]: styles.leftRightSplitterAlignDefault,
            },
        );
    };

    getStyle = (flex) => {
        if (flex) {
            return {
                flex,
            };
        }

        return {};
    };

    getStyleLeft = () => {
        return this.getStyle(this.props.flexLeft);
    };

    getStyleRight = () => {
        return this.getStyle(this.props.flexRight);
    };

    render () {
        const splitterAlignmentClass = this.getSplitterAlignmentClass();

        return (
            <div className={styles.leftRightSplitter}>
                <div
                    className={classNames(
                        styles.leftRightSplitterLeft,
                        splitterAlignmentClass,
                    )}
                    style={this.getStyleLeft()}
                >
                    {this.props.renderLeft()}
                </div>
                <div
                    className={classNames(
                        styles.leftRightSplitterRight,
                        splitterAlignmentClass,
                    )}
                    style={this.getStyleRight()}
                >
                    {this.props.renderRight()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        // Should be always true since its too complex
        // to set unique filter keys for this component.
        return true;
    }
}

const Component = LeftRightSplitter;

Component.propTypes = {
    contentAlignment: PropTypes.oneOf(Object.values(LeftRightSplitterContentAlignment)),
    flexLeft:         PropTypes.number,
    flexRight:        PropTypes.number,
    renderLeft:       PropTypes.func,
    renderRight:      PropTypes.func,
};

Component.defaultProps = {
    contentAlignment: LeftRightSplitterContentAlignment.default,
    flexLeft:         1,
    flexRight:        1,
    renderLeft:       _.noop,
    renderRight:      _.noop,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;