// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/NumericFilter.js
import React               from 'react';
import { Filters }         from 'react-data-grid-addons';
import I18n                from 'i18next';
import styles              from './styles.module.scss';
import { withTranslation } from 'react-i18next';
import TableFilterInput    from '../TableFilterInput';

const TableNumericFilter = Filters.NumericFilter;

TableNumericFilter.prototype.render = function () {
    const inputKey = 'header-filter-' + this.props.column.key;

    return (
        <div className={styles.numericFilterWrapper}>
            <div className={styles.numericFilter}>
                <TableFilterInput
                    key={inputKey}
                    placeholder={I18n.t('numberFilterPlaceholder')}
                    onChange={this.handleChange}
                />
            </div>
        </div>
    );
};

export default withTranslation()(TableNumericFilter);