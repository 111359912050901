//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                                             from 'lodash';
import ComponentHelper                               from '../../helper/ComponentHelper';
import DateHelper                                    from '../../helper/Date';
import I18n                                          from 'i18next';
import MonthlyContainer                              from '../MonthlyContainer';
import PropTypes                                     from '../PropTypes';
import React                                         from 'react';
import Table                                         from '../Table';
import TableDateFilter                               from '../TableDateFilter';
import TableDurationNotNullFormatter                 from '../TableDurationNotNullFormatter';
import TableHolidayDateFormatter                     from '../TableHolidayDateFormatter';
import TableNumericFilter                            from '../TableNumericFilter';
import TableTimeTrackingCalendarWeekActionsFormatter from '../TableTimeTrackingCalendarWeekActionsFormatter';
import { connect }                                   from 'react-redux';
import Cast                                          from '../../helper/Cast';

class MonthlyTrackedTimeTable extends React.Component {
    constructor (props) {
        super(props);
    }

    getColumnConfiguration = () => {
        const columnConfiguration = _.compact([
            {
                filterable:     true,
                filterRenderer: TableDateFilter,
                formatter:      TableHolidayDateFormatter,
                key:            'dateAndHoliday',
                name:           I18n.t('date'),
                sortable:       false,
            },
            {
                filterable:     true,
                filterRenderer: TableNumericFilter,
                formatter:      TableDurationNotNullFormatter,
                key:            'duration',
                name:           I18n.t('duration'),
                sortable:       false,
            },
            {
                filterable: false,
                width:      35,
                formatter:  TableTimeTrackingCalendarWeekActionsFormatter,
                key:        'actions',
                name:       I18n.t('actions'),
                sortable:   false,
            },
        ]);

        return columnConfiguration;
    };

    getUserTimeSums = () => {
        const targetMonth                              = Cast.int(this.props.month);
        const monthName                                = I18n.t(DateHelper.getMonthName(targetMonth - 1));
        const orderedTimeDaySumsPerDay                 = this.props.orderedUserTimeDaySumsPerDay;
        const orderedTimeDaySumsPerDayWithCalendarWeek = [];
        let calendarWeek                               = null;
        let sumCalendarWeek                            = 0;
        let sumTotal                                   = 0;

        if (orderedTimeDaySumsPerDay) {
            for (let orderedTimeBookingPerDay of orderedTimeDaySumsPerDay) {
                const date                = new Date(orderedTimeBookingPerDay.rawDate);
                const isTargetMonth       = date.getMonth() + 1 === targetMonth;
                const currentCalendarWeek = DateHelper.getWeekOfTheYear(date)[1];

                if (!calendarWeek) {
                    calendarWeek = currentCalendarWeek;
                }

                if (calendarWeek !== currentCalendarWeek) {
                    orderedTimeDaySumsPerDayWithCalendarWeek.push(
                        {
                            dateAndHoliday: {
                                date:    I18n.t('calendarWeekShortened', { calendarWeek: calendarWeek }),
                                holiday: false,
                            },
                            duration:       sumCalendarWeek,
                        },
                        {},
                    );

                    calendarWeek    = currentCalendarWeek;
                    sumCalendarWeek = 0;
                }

                sumCalendarWeek += orderedTimeBookingPerDay.duration;

                if (isTargetMonth) {
                    sumTotal += orderedTimeBookingPerDay.duration;
                }

                orderedTimeDaySumsPerDayWithCalendarWeek.push({
                    actions:        date,
                    dateAndHoliday: {
                        date:         orderedTimeBookingPerDay.date,
                        holiday:      orderedTimeBookingPerDay.holiday,
                        targetMonth:  targetMonth,
                        currentMonth: date.getMonth() + 1,
                    },
                    ...orderedTimeBookingPerDay,
                });
            }
        }

        orderedTimeDaySumsPerDayWithCalendarWeek.push({
            dateAndHoliday: {
                date:    I18n.t('calendarWeekShortened', { calendarWeek: calendarWeek }),
                holiday: false,
            },
            duration:       sumCalendarWeek,
        });
        orderedTimeDaySumsPerDayWithCalendarWeek.unshift(
            {
                dateAndHoliday: {
                    date:    monthName + ' ' + this.props.year,
                    holiday: false,
                },
                duration:       sumTotal,
            },
            {},
        );

        return orderedTimeDaySumsPerDayWithCalendarWeek;
    };

    render () {
        const userTimeSums = this.getUserTimeSums();

        return (
            <MonthlyContainer
                userTimeSums={userTimeSums}
                month={this.props.month}
                year={this.props.year}
                updateMonthAndYear={this.props.updateMonthAndYear}
            >
                <Table
                    columnConfiguration={this.getColumnConfiguration()}
                    rawData={userTimeSums}
                    sortColumn={'date'}
                />
            </MonthlyContainer>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MonthlyTrackedTimeTable;

Component.propTypes = {
    month:                        PropTypes.number,
    year:                         PropTypes.number,
    projectTimeBookings:          PropTypes.array,
    orderedUserTimeDaySumsPerDay: PropTypes.array,
    updateMonthAndYear:           PropTypes.func,
};

Component.defaultProps = {
    month:                        null,
    year:                         null,
    projectTimeBookings:          [],
    orderedUserTimeDaySumsPerDay: [],
    updateMonthAndYear:           _.noop(),
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'projectTimeBookings',
    'orderedUserTimeDaySumsPerDay',
];

const mapStateToProps = state => (
    {
        projectTimeBookings:          _.get(state, ['projectTimeBooking', 'projectTimeBookings']),
        orderedUserTimeDaySumsPerDay: _.get(state, ['userTimeSum', 'orderedUserTimeDaySumsPerDay']),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
