import '@babel/polyfill';
import './language/Language';
import ReactDOM            from 'react-dom';
import React               from 'react';
import { Provider }        from 'react-redux';
import store               from './store';
import { ConnectedRouter } from 'connected-react-router';
import { history }         from './store/index';
import Header              from './components/Header';
import LoadingOverlay      from './components/LoadingOverlay';
import MainRouter          from './components/MainRouter';
import Footer              from './components/Footer';
import * as serviceWorker  from './serviceWorker';
import moment              from 'moment';
import 'moment/locale/de';

moment.locale('de');

ReactDOM.render(
    <Provider store={store()}>
        <ConnectedRouter history={history}>
            <Header />
            <LoadingOverlay />
            <MainRouter />
            <Footer />
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'),
);

serviceWorker.register();