//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                             from 'lodash';
import AlertBoxManager               from '../components/AlertBoxManager';
import BreadCrumbBar                 from '../components/BreadCrumbBar';
import CheckBox                      from '../components/CheckBox';
import classNames                    from 'classnames';
import ColorButton                   from '../components/ColorButton';
import ColorButtonTheme              from '../components/ColorButton/ColorButtonTheme';
import Headline                      from '../components/Headline';
import HeadlineOverlay               from '../components/HeadlineOverlay';
import HeadlineTheme                 from '../components/Headline/HeadlineTheme';
import I18n                          from 'i18next';
import IconType                      from '../components/Icon/IconType';
import MonthlyContainer              from '../components/MonthlyContainer';
import PageTitleHelper               from '../helper/PageTitle';
import React                         from 'react';
import Routes                        from '../constants/Routes';
import styles                        from '../styles.module.scss';
import TableFilterButton             from '../components/TableFilterButton';
import UserTimeTrackingTable         from '../components/UserTimeTrackingTable';
import { bindActionCreators }        from 'redux';
import { Component }                 from 'react';
import { connect }                   from 'react-redux';
import { Helmet }                    from 'react-helmet';
import { LayoutActions }             from '../store/actions/layout';
import { ProjectTimeBookingActions } from '../store/actions/projectTimeBooking';
import { UserActions }               from '../store/actions/user';
import DateHelper                    from '../helper/Date';
import { withRouter }                from 'react-router-dom';
import moment                        from 'moment';

class Screen extends Component {
    groupByDateCheckBoxChanged = () => {
        this.props.groupByDateToggle();
    };

    updateMonthAndYear = (monthYearObject) => {
        const newMonth = monthYearObject.month;
        const newYear  = monthYearObject.year;

        this.props.openProjectTimeBookings({
            month: newMonth,
            year:  newYear,
        });
    };

    getMonthAndYear = () => {
        const { monthYear } = this.props.match.params;
        const currentMonth  = DateHelper.getCurrentMonth();
        const currentYear   = DateHelper.getCurrentYear();

        if (monthYear && _.includes(monthYear, '-')) {
            const monthYearArray = monthYear.split('-');

            if (!isNaN(monthYearArray[0]) && !isNaN(monthYearArray[1])) {
                return monthYearArray;
            }
        }

        return [currentMonth, currentYear];
    };

    getDateFilter = () => {
        let dateFilter    = false;
        let dateFromParam = new URLSearchParams(this.props.location.search).get('dateFrom');
        let dateToParam   = new URLSearchParams(this.props.location.search).get('dateTo');

        if (dateFromParam || dateToParam) {
            dateFilter = {};

            if (dateFromParam) {
                dateFilter.from = moment(dateFromParam, 'DD-MM-YYYY');
            }

            if (dateToParam) {
                dateFilter.to = moment(dateToParam, 'DD-MM-YYYY');
            }
        }

        return dateFilter;
    };

    render () {
        const dateFilter   = this.getDateFilter();
        const headerKey    = 'header' + (
            this.props.groupByDate ? 'Checked' : 'Unchecked'
        ) + dateFilter;
        const monthAndYear = this.getMonthAndYear();
        let month          = monthAndYear[0];
        let year           = monthAndYear[1];
        const tableKey     = 'UserTimeTrackingTable' + this.props.projectTimeBookings.length;

        if (dateFilter.from) {
            month = dateFilter.from.month() + 1;
            year  = dateFilter.from.year();
        }

        return (
            <div
                className={classNames(
                    styles.contentView,
                    styles.contentViewFullHeight,
                )}
            >
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('timeTracking'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    key={headerKey}
                    overlay={this.renderHeadlineOverlay()}
                    text={I18n.t('timeTracking')}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <AlertBoxManager />
                <MonthlyContainer
                    month={month}
                    year={year}
                    updateMonthAndYear={this.updateMonthAndYear}
                >
                    <UserTimeTrackingTable
                        key={tableKey}
                        monthYearFilter={{
                            month: month,
                            year:  year,
                        }}
                        dateFilter={dateFilter}
                    />
                </MonthlyContainer>
            </div>
        );
    }

    renderHeadlineOverlay = () => {
        return (
            <HeadlineOverlay>
                {this.renderDateFilterCheckBox()}
                <CheckBox
                    checked={this.props.groupByDate}
                    text={I18n.t('groupByDate')}
                    valueChanged={this.groupByDateCheckBoxChanged}
                    noMarginBottom={true}
                />
                <TableFilterButton />
                <ColorButton
                    iconType={IconType.clock}
                    text={I18n.t('timeTrackingCreateNewButton')}
                    theme={ColorButtonTheme.redSmall}
                    to={Routes.timeTrackingCreate}
                />
            </HeadlineOverlay>
        );
    };

    dateFilterCheckBoxChanged = () => {
        this.props.history.push(Routes.getRouteWithParameters(
            Routes.timeTracking,
            {
                monthYear: '',
            },
        ));
    };

    renderDateFilterCheckBox = () => {
        const dateFilter = this.getDateFilter();

        if (dateFilter) {
            const formattedDateFrom = moment(dateFilter.from).format(I18n.t('listDateFormat'));
            const formattedDateTo   = moment(dateFilter.to).format(I18n.t('listDateFormat'));

            return (
                <CheckBox
                    key={dateFilter}
                    checked={dateFilter}
                    text={I18n.t('dateFilter', { dateFrom: formattedDateFrom, dateTo: formattedDateTo })}
                    valueChanged={this.dateFilterCheckBoxChanged}
                    noMarginBottom={true}
                    marginRight={true}
                />
            );
        }

        return null;
    };
}

const mapStateToProps = state => (
    {
        groupByDate:         state.layout.groupByDate,
        loading:             state.loading,
        projects:            state.project.projects,
        projectTimeBookings: state.projectTimeBooking.projectTimeBookings,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LayoutActions,
    ProjectTimeBookingActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Screen));
