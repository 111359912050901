import React                         from 'react';
import I18n                          from 'i18next';
import Routes                        from '../../constants/Routes';
import PropTypes                     from '../PropTypes';
import IconButton                    from '../IconButton';
import IconButtonTheme               from '../IconButton/IconButtonTheme';
import IconType                      from '../Icon/IconType';
import { bindActionCreators }        from 'redux';
import styles                        from './styles.module.scss';
import { ProjectTimeBookingActions } from '../../store/actions/projectTimeBooking';
import { connect }                   from 'react-redux';
import { withRouter }                from 'react-router-dom';
import ComponentHelper               from '../../helper/ComponentHelper';

class ProjectActionList extends React.Component {
    trackTimeButtonPressed = () => {
        this.props.setProjectTimeBookingProject({
            project: this.props.project.id,
        });

        this.props.history.push(Routes.timeTrackingCreate);
    };

    render () {
        if (this.props.project) {
            return (
                <ul className={styles.projectActionList}>
                    <li>
                        <IconButton
                            iconType={IconType.clock}
                            onClick={this.trackTimeButtonPressed}
                            theme={IconButtonTheme.redRounded}
                            title={I18n.t('timeTrackingCreateNewButton')}
                        />
                    </li>
                </ul>
            );
        }

        return '';
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectActionList;

Component.propTypes = {
    project: PropTypes.object,
};

Component.defaultProps = {
    project: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(ProjectTimeBookingActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Component));