import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import BreadCrumbBar   from '../components/BreadCrumbBar';
import Headline        from '../components/Headline';
import HeadlineTheme   from '../components/Headline/HeadlineTheme';
import Paragraph       from '../components/Paragraph';

//
// This is basically a copy of
// @see https://www.vogtundfeist.de/datenschutz.html
//
class Screen extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('dataProtection'))}
                    </title>
                </Helmet>
                <BreadCrumbBar />
                <Headline
                    text={'Datenschutz auf einen Blick'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Headline
                    text={'Allgemeine Hinweise'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                    Daten
                    passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                    persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                    Sie
                    unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </Paragraph>
                <Headline
                    text={'Datenerfassung auf dieser Website'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Headline
                    text={'Wer ist verantwortlich für die Datenerfassung auf dieser Website?'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                    können
                    Sie dem Impressum dieser Website entnehmen.
                </Paragraph>
                <Headline
                    text={'Wie erfassen wir Ihre Daten?'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B.
                    um
                    Daten handeln, die Sie in ein Kontaktformular eingeben.
                </Paragraph>
                <Paragraph>
                    Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind
                    vor
                    allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                    Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                </Paragraph>
                <Headline
                    text={'Wofür nutzen wir Ihre Daten?'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
                    Andere
                    Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </Paragraph>
                <Headline
                    text={'Welche Rechte haben Sie bezüglich Ihrer Daten?'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                    gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
                    Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                    Datenschutz
                    können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren
                    steht
                    Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </Paragraph>
                <Headline
                    text={'Analyse-Tools und Tools von Drittanbietern'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht
                    vor
                    allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt
                    in
                    der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser
                    Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                    Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
                </Paragraph>
                <Paragraph>
                    Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
                    Datenschutzerklärung informieren.
                </Paragraph>
                <Headline
                    text={'Allgemeine Hinweise und Pflichtinformationen'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Headline
                    text={'Datenschutz'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                    Ihre
                    personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                    dieser Datenschutzerklärung.
                </Paragraph>
                <Paragraph>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                    Personenbezogene
                    Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                    Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert
                    auch,
                    wie und zu welchem Zweck das geschieht.
                </Paragraph>
                <Paragraph>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                </Paragraph>
                <Headline
                    text={'Widerruf Ihrer Einwilligung zur Datenverarbeitung'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
                    eine
                    bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
                    an
                    uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                    unberührt.
                </Paragraph>
                <Headline
                    text={'Beschwerderecht bei der zuständigen Aufsichtsbehörde'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
                    zuständigen
                    Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
                    Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste
                    der
                    Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:
                    {' '}
                    <a
                        href={'https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'}
                        target={'_blank'}
                    >
                        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                    </a>
                    .
                </Paragraph>
                <Headline
                    text={'Recht auf Datenübertragbarkeit'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                    Vertrags
                    automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                    aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
                    Verantwortlichen
                    verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </Paragraph>
                <Headline
                    text={'SSL- bzw. TLS-Verschlüsselung'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie
                    zum
                    Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
                    TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                    Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                </Paragraph>
                <Paragraph>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
                    nicht von Dritten mitgelesen werden.
                </Paragraph>
                <Headline
                    text={'Widerspruch gegen Werbe-Mails'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                    nicht
                    ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                    Betreiber
                    der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                    Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </Paragraph>
                <Headline
                    text={'Datenerfassung auf unserer Website'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Headline
                    text={'Cookies'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                    Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                    effektiver
                    und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
                    die
                    Ihr Browser speichert.
                </Paragraph>
                <Paragraph>
                    Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende
                    Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
                    diese
                    löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                </Paragraph>
                <Paragraph>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                    Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
                    der
                    Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                </Paragraph>
                <Paragraph>
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                    bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf
                    Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
                    Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung
                    seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert
                    werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                </Paragraph>
                <Headline
                    text={'Server-Log-Dateien'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                    Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                </Paragraph>
                <Paragraph>
                    • Browsertyp und Browserversion
                    • verwendetes Betriebssystem
                    • Referrer URL
                    • Hostname des zugreifenden Rechners
                    • Uhrzeit der Serveranfrage
                    • IP-Adresse
                </Paragraph>
                <Paragraph>
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                </Paragraph>
                <Paragraph>
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                    zur
                    Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                </Paragraph>
                <Headline
                    text={'Plugins und Tools'}
                    theme={HeadlineTheme.standardSmallMargin}
                />
                <Headline
                    text={'Google Web Fonts'}
                    theme={HeadlineTheme.small}
                />
                <Paragraph>
                    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von
                    Google
                    bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
                    Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
                </Paragraph>
                <Paragraph>
                    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
                    aufnehmen.
                    Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen
                    wurde.
                    Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden
                    Darstellung
                    unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
                    DSGVO
                    dar.
                </Paragraph>
                <Paragraph>
                    Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
                </Paragraph>
                <Paragraph>
                    Weitere Informationen zu Google Web Fonts finden Sie unter
                    {' '}
                    <a
                        href={'https://developers.google.com/fonts/faq'}
                        target={'_blank'}
                    >
                        https://developers.google.com/fonts/faq
                    </a>
                    {' '}
                    und in der Datenschutzerklärung von Google:
                    {' '}
                    <a
                        href={'https://www.google.com/policies/privacy/'}
                        target={'_blank'}
                    >
                        https://www.google.com/policies/privacy/
                    </a>
                    .
                </Paragraph>
            </div>
        );
    }
}

export default Screen;
