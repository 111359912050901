import _               from 'lodash';
import ComponentHelper from '../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class TableFilterInput extends React.Component {
    render () {
        return (
            <div className={styles.tableFilterInputWrapper}>
                <input
                    className={styles.tableFilterInput}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder}
                    type={'text'}
                />
                <div className={styles.tableFilterInputIconWrapper}>
                    <Icon iconType={IconType.search} />
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TableFilterInput;

Component.propTypes = {
    placeholder: PropTypes.string,
    onChange:    PropTypes.func,
};

Component.defaultProps = {
    placeholder: null,
    onChange:    _.noop,
};

Component.renderAffectingProps = [
    'placeholder',
];

Component.renderAffectingStates = [];

export default Component;