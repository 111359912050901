//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import ProjectOverviewActionList from '../ProjectOverviewActionList';
import PropTypes                 from '../PropTypes';
import styles                    from './styles.module.scss';

class TableOverviewActionsFormatter extends React.Component {
    render () {
        if (this.props.value) {
            const projectTimeBooking = this.props.value;
            const text               = _.get(projectTimeBooking, 'text');

            return (
                <div className={styles.projectOverviewActionListWrapper}>
                    <ProjectOverviewActionList
                        text={text}
                        projectTimeBooking={projectTimeBooking}
                    />
                </div>
            );
        }

        return '';
    }
}

const Component = TableOverviewActionsFormatter;

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
