import ComponentHelper from '../../helper/ComponentHelper';
import I18n            from 'i18next';
import moment          from 'moment';
import packageJson     from '../../../package.json';
import preval          from 'preval.macro';
import React           from 'react';
import styles          from './styles.module.scss';

const buildTimestamp = preval`module.exports = new Date().getTime();`;

class VersionNumber extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate    = lastUpdateMoment.format(I18n.t('versionNumberDateFormat'));

        return formattedDate;
    };

    render () {
        return (
            <span className={styles.versionNumberWrapper}>
                {I18n.t('version')}
                {': '}
                <span className={styles.versionNumber}>
                    {packageJson.version}
                    {'.'}
                    {buildTimestamp}
                </span>
                {' '}
                {'('}
                {this.getDateString()}
                {')'}
            </span>
        );
    }
}

const Component = VersionNumber;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;