//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../PropTypes';
import _               from 'lodash';
import classNames      from 'classnames';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import ComponentHelper from '../../helper/ComponentHelper';

class CheckBox extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.checkBoxWrapper,
                    {
                        [styles.checkBoxWrapperNoMarginBottom]: this.props.noMarginBottom,
                        [styles.checkBoxWrapperMarginRight]:    this.props.marginRight,
                    },
                )}
            >
                <div
                    className={styles.checkBoxWrapperClickArea}
                    onClick={this.props.valueChanged}
                >
                    {this.renderCheckBox()}
                    <div className={styles.checkBoxWrapperLabel}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }

    renderCheckBox = () => {
        return (
            <div
                className={classNames(
                    styles.checkBox,
                    {
                        [styles.checkBoxChecked]: this.props.checked,
                    },
                )}
            >
                {
                    this.props.checked ? (
                        <Icon iconType={IconType.check} />
                    ) : null
                }
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CheckBox;

Component.propTypes = {
    checked:        PropTypes.bool,
    noMarginBottom: PropTypes.bool,
    marginRight:    PropTypes.bool,
    text:           PropTypes.string,
    valueChanged:   PropTypes.func,
};

Component.defaultProps = {
    checked:        false,
    noMarginBottom: false,
    marginRight:    false,
    text:           '',
    valueChanged:   _.noop,
};

Component.renderAffectingProps = [
    'checked',
    'noMarginBottom',
    'marginRight',
    'text',
];

Component.renderAffectingStates = [];

export default Component;
